/* eslint-disable camelcase */
import {Form, Button, Spinner} from 'react-bootstrap';
import {useFormFields} from '../../hooks/form-hook';
import {useState} from 'react';
import {BA, useTranslation} from '@util/bachhelpers';
import {URL_TYPES} from '@util/consts.js';
import UI from '@ui/UI';
import PropTypes from 'prop-types';

function FormAccountPassword(props) {
  const {_} = useTranslation();
  const [fields, handleChange] = useFormFields([
    {old_pw: '', error: true},
    {new_pw: '', error: true},
    {new_pw2: '', error: true},
  ]);
  const [toasts, setToasts] = useState([]);
  const [xhrload, setXhrload] = useState(false);
  const [pwdchangeSuccess, setPwdchangeSuccess] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log( 'handleSubmit', xhrload,
    // fields.findIndex((el) => el.error) );
    if (!xhrload && fields.findIndex((el) => el.error) === -1) {
      const dataToSend = BA.sendData(fields);
      setXhrload(true);
      BA.ajax({
        url: props.userInfo.wuniverse === false ?
          BA.buildApiUrl(URL_TYPES.ACCOUNT_PASSWORD) :
          '/bachapi/member/setnewpwd',
        method: e.target.method,
        contentType: 'application/json',
        bpoapi: props.userInfo.wuniverse === false,
        method: 'POST',
        data: JSON.stringify(dataToSend),

      }).then((response) => {
        console.log(response);
        setXhrload(false);
        if (response.error || response.status === 'error') {
          setToasts([{variant: 'error mb-4',
            message: response.message ? response.message :
              response.error.message,
            show: true}]);
        } else {
          setPwdchangeSuccess( true );
          setToasts([{variant: 'success mb-4',
            message: _('app.profile.account_password_changed'),
            show: true}]);
          setTimeout(()=>{
            // console.log( dataToSend );
            props.setUser({...props.user, ...dataToSend});
            props.setInPageForm('');
          }, 1500);
        }
      });
    }
  };

  const handleClose = (i) => {
    const updatedToasts = toasts.map( (toast, ix)=>{
      if ( i === ix ) {
        toast.show = false;
      }
      return toast;
    });
    setToasts( updatedToasts );
  };

  return (
    <>

      <Form method="post"
        action=""
        autoComplete="off"
        onSubmit={handleSubmit}
        className="dataform">

        <UI.ToastMessage toasts={toasts} handleClose={handleClose} />
        {!pwdchangeSuccess&&<>
          <Form.Group className="mb-2">
            <Form.Label htmlFor="old_pw" className="mb-0">
              <span className="labeltext">{_('general.current_pwd')}</span>
            </Form.Label>
            {/* prevent firefox from writing password in
            first password field */}
            <input type="password" className="d-none" />
            <UI.FormControl
              type="password"
              validation
              name="old_pw"
              handler={handleChange}
              className="ps-2"
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label htmlFor="new_pw" className="mb-0">
              <span className="labeltext">{_('general.new_pwd')}</span>
            </Form.Label>
            <UI.FormControl
              type="password"
              validation
              name="new_pw"
              handler={handleChange}
              className="ps-2"
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label htmlFor="new_pw2" className="mb-0">
              <span className="labeltext">{_('general.repeat_pwd')}</span>
            </Form.Label>
            <UI.FormControl
              type="password"
              validation
              name="new_pw2"
              repeat="new_pw"
              handler={handleChange}
              className="ps-2"
            />
          </Form.Group>

          <div className="text-end mt-4">
            <Button type="submit" className="px-4" variant="primary">
              {xhrload&&<Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />}
              {_('app.login.change_pwd')}
            </Button>

          </div>
        </>}
      </Form></>);
}
FormAccountPassword.propTypes={
  setUser: PropTypes.func,
  user: PropTypes.object,
  userInfo: PropTypes.object,
  setInPageForm: PropTypes.oneOfType(
      [
        PropTypes.object,
        PropTypes.func,
      ]),
};
export default FormAccountPassword;
