import {Container, Button, Form, Row, Col, Spinner} from 'react-bootstrap';
import UI from '@ui/UI';
import {useFormFields} from '@hooks/form-hook.js';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';


function ForgotPassword(props) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const [toasts, setToasts] = useState([]);
  const [fields, handleChange] = useFormFields([
    {confirm_email: '', error: true},
    {pin_send_method: 'preferred', error: false},
  ]);
  const [xhrload, setXhrload] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ( !xhrload && fields.findIndex( (el) => el.error ) === -1 ) {
      const dataToSend = BA.sendData(fields);
      setXhrload(true);
      BA.ajax({
        url: e.target.getAttribute('action'),
        method: e.target.method,
        data: dataToSend,
        bpoapi: false,
      }).then( (response) => {
        console.log( response );
        setXhrload(false);
        if ( response.status === 'error' ) {
          setToasts( [{
            variant: response.status,
            code: response.code,
            message: response.message,
            show: true}]);
        } else {
          dispatch({
            type: 'set_redirect',
            to: BA.BACHURL+'/idpapi/login',
            title: _('app.signup.thankyou'),
            info: _('app.signup.confirmation',
                false,
                dataToSend.confirm_email ),
          });

          navigate('/redirect', {replace: false});
        }
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  const handleClose = (i) => {
    const updatedToasts = toasts.map( (toast, ix)=>{
      if ( i === ix ) {
        toast.show = false;
      }
      return toast;
    });
    setToasts( updatedToasts );
  };


  return (
    <div className="col-lg-5 col-xxl-3 p-0">
      <UI.InfoBox title={_('general.wuniverse')}
        titlesmall={_('app.signup.info_subtitle_confirm')}>
        <UI.InfoText>{_('app.signup.info_text_confirm')}</UI.InfoText>
      </UI.InfoBox>

      <Form method="post"
        action="/bachapi/resendconfirm"
        autoComplete="off"
        onSubmit={handleSubmit}>

        <UI.ToastMessage toasts={toasts} handleClose={handleClose} />

        <Container fluid className="p-4">
          <Form.Group className="mb-4">
            <Form.Label htmlFor="confirm_email">
              <UI.Icon icon="bi-email"/>
              <span className="labeltext">{_('general.email')}</span>
            </Form.Label>
            <UI.FormControl
              type="text"
              placeholder={_('general.email')}
              name="confirm_email"
              handler={handleChange}
            />
          </Form.Group>
          <input type="hidden"
            id="pin_send_method"
            name="pin_send_method"
            value="preferred" />
          <Row className="g-2">
            <Col className="d-grid">
              <Button type="submit" id="btn-resend-confirm" variant="primary">
                {xhrload&&<Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                />}
                {_('app.signup.resend')}
              </Button>
            </Col>
            <Col xs={6} sm={5} className="d-grid">
              <UI.BachLink
                href="/"
                className="btn btn-secondary">
                {_('general.cancel')}
              </UI.BachLink>
            </Col>
          </Row>
        </Container>
      </Form>
      <Container fluid className="p-4">
        <p>
          {_('app.signup.noaccount')}<br />
          <UI.BachLink href="/register"
            className="text-link">
            {_('app.signup.signup_now')}
          </UI.BachLink>
        </p>
        <p>
          {_('app.signup.alreadylogin')}<br />
          <UI.BachLink
            href={BA.BACHURL+'/idpapi/login'}
            className="text-link">
            {_('general.signin')}
          </UI.BachLink>
        </p>
      </Container>
    </div>

  );
}

export default ForgotPassword;
