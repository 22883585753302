import {Row, Col, Form} from 'react-bootstrap';
import {useTranslation} from '@util/bachhelpers.js';
import {useDispatch, useSelector} from 'react-redux';
import './StudyList.css';
import PropTypes from 'prop-types';

function StudyList( props ) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const style = props.style? props.style:{};
  const studyPrograms = useSelector((state) => state.studyPrograms);
  const currentStudyProgram = useSelector((state) => state.currentStudyProgram);
  const studies = useSelector((state) => state.studies);
  const currentStudy = useSelector((state) => state.currentStudy);

  const changeHandler = (e)=>{
    if ( parseInt(e.target.value) !== -1 ) {
      if (props.variantStudyProgram && studyPrograms.length > 0) {
        dispatch({
          type: 'set_current_study_program',
          currentStudyProgram: studyPrograms.filter((studyProgram) => {
            return studyProgram.code === e.target.value;
          })[0]});
      } else {
        dispatch({
          type: 'set_current_study',
          currentStudy: studies.filter((study) =>
            parseInt(study.id) === parseInt(e.target.value))[0],
        });
      }
    } else {
      if (props.variantStudyProgram && studyPrograms.length > 0) {
        dispatch({
          type: 'set_current_study_program',
          currentStudyProgram: {
            code: -1,
            nameEn: '',
            nameDe: '',
          },
        });
      } else {
        dispatch({
          type: 'set_current_study',
          currentStudy: {
            id: -1,
            studyCode: 'all',
          },
        });
      }
    }
  };

  return (
    <Row className={style.row}>
      <Col className={style.col} xs={style.xs} sm={style.sm}>
        {!props.hideHeader && (
          <span className="current-study">
            {(props.variantStudyProgram && studyPrograms.length > 0) ?
                  _('app.achievements.degree_program') :
                  _('app.exams.registered_studies')
            }
          </span>
        )}
        <Form.Select className={`mt-2 mb-2 smallselect`}
          aria-label="select studies"
          onChange={changeHandler}
          /* eslint-disable max-len */
          defaultValue={(props.variantStudyProgram && studyPrograms.length > 0) ?
          currentStudyProgram.code: currentStudy.id}>
          <option className="option-study" key={'study-1'}
            value={-1}>
            {_('app.exams.all')}
          </option>
          {(props.variantStudyProgram && studyPrograms.length > 0) ?
            studyPrograms.map((studyProgram, i)=>(
              <option className="option-study" key={'study'+i}
                value={studyProgram.code}
              >
                {studyProgram.abbreviation}
              </option>
            )) :
            studies.map((study, i)=>(
              <option className="option-study" key={'study'+i}
                value={study.id}
              >
                {study.studyCode}
              </option>
            ))
          }
        </Form.Select>
      </Col>
    </Row>
  );
}
StudyList.propTypes={
  hideHeader: PropTypes.bool,
  style: PropTypes.object,
  variantStudyProgram: PropTypes.bool,
};
export default StudyList;
