/* eslint-disable max-len */
import {useTranslation} from '@util/bachhelpers.js';
// import Box from './Box';
// import {Badge, Row, Col} from 'react-bootstrap';
import {useSelector} from 'react-redux';
// import {Clock, GeoAltFill} from 'react-bootstrap-icons';
import './ExamBoxes.css';
import {useEffect, useState} from 'react';
// import {LANGUAGES} from '@util/consts';
import PropTypes from 'prop-types';
import ExamBoxSingle from './ExamBoxSingle';

function ExamBoxes( props ) {
  const {_} = useTranslation();
  // const language = useSelector((state) => state.language);
  const semesters = useSelector((state) => state.semesters);
  const exams = useSelector((state) => state.exams);
  const currentStudy = useSelector((state) => state.currentStudy);

  const [filteredFutureExams, setFilteredFutureExams] =
  useState(semesters.length>0 ? exams.filter((exam) => {
    return ((exam.studyId === currentStudy.id || currentStudy.id ===-1) &&
      Date(exam.end)>=Date.now() && !exam.deregistered );
  },
  ):[],
  );
  const [filteredPastExams, setFilteredPastExams] =
   useState(semesters.length>0 ? exams.filter((exam) => {
     return ((exam.studyId === currentStudy.id || currentStudy.id ===-1) &&
      Date(exam.end)<Date.now() && !exam.deregistered);
   },
   ):[],
   );

  const [filteredDeRegExams, setFilteredDeRegExams] =
   useState(semesters.length>0 ? exams.filter((exam) => {
     return ((exam.studyId === currentStudy.id || currentStudy.id ===-1) &&
      exam.deregistered);
   },
   ):[],
   );

  /*
  const [countShownExams, setCountShownExams] = useState( semesters.length>0 ? exams.filter((exam) => {
    return ((exam.studyId === currentStudy.id || currentStudy.id ===-1) &&
    ((props.showDeReg && exam.deregistered) || ( !props.showDeReg && !exam.deregistered) ));
  },
  ):[],
  );
  */

  useEffect(()=>{
    setFilteredFutureExams(semesters.length > 0? exams.filter((exam) => {
      return ((exam.studyId === currentStudy.id || currentStudy.id ===-1) &&
        Date.parse(exam.end)>=Date.now() && !exam.deregistered);
    }):
    []);
    setFilteredPastExams(semesters.length > 0? exams.filter((exam) => {
      return ((exam.studyId === currentStudy.id || currentStudy.id ===-1) &&
        Date.parse(exam.end)<Date.now() && !exam.deregistered);
    },
    ):[]);
    setFilteredDeRegExams(semesters.length > 0? exams.filter((exam) => {
      return ((exam.studyId === currentStudy.id || currentStudy.id ===-1) &&
        exam.deregistered);
    },
    ):[]);

    /* setCountShownExams(semesters.length>0 ? exams.filter((exam) => {
      return ((exam.studyId === currentStudy.id || currentStudy.id ===-1) &&
      Date.parse(exam.end)<Date.now() &&
      ((props.showDeReg && exam.deregistered) || ( !props.showDeReg && !exam.deregistered) ));
    },
    ):[]);*/
  }, [semesters.length, currentStudy, props.showDeReg]);


  return (
    <>
      {semesters.length>0 && filteredFutureExams.length > 0 &&
        <div className="pt-3 mb-2 mt-2">
          {filteredFutureExams.map((exam, i)=>{
            return (
              <div key={'exam_future_'+i}>
                <ExamBoxSingle exam={exam} />
              </div>
            );
          })}
        </div>}


      {semesters.length>0 && filteredPastExams.length > 0 &&
        <><div className={`past-exams ms-1 pt-3`}>
          {_('app.exams.past_exams')}
        </div>
        <div className="mb-2 mt-2">
          {semesters.length>0 && filteredPastExams.map((exam, i)=>{
            return (
              <div key={'exam_past_'+i}>
                <ExamBoxSingle exam={exam} />
              </div>
            );
          })}
        </div></>}

      {semesters.length>0 && filteredDeRegExams.length > 0 && props.showDeReg &&
        <><div className={`past-exams ms-1 pt-3`}>
          {_('app.exams.dereg_exams')}
        </div>
        <div className="mb-2 mt-2">
          {semesters.length>0 && filteredDeRegExams.map((exam, i)=>{
            return (
              <div key={'exam_dereg_'+i}>
                <ExamBoxSingle exam={exam} />
              </div>
            );
          })}
        </div></>}


      { semesters.length>0 &&
        filteredFutureExams.length === 0 &&
        filteredPastExams.length === 0 &&
        (!props.showDeReg || filteredDeRegExams.length === 0) && (<div className="p-2 mb-2">{_('app.exams.no_exams')}</div>)}


    </>
  );
}
ExamBoxes.propTypes={
  showDeReg: PropTypes.bool.isRequired,
};
export default ExamBoxes;
