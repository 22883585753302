import {Modal, CloseButton, Row, Col} from 'react-bootstrap';
import {useTranslation} from '@util/bachhelpers.js';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {createBrowserHistory} from 'history';


function ErrorModal(props) {
  const {_} = useTranslation();
  const [show, setShow] = useState(true);
  const history = createBrowserHistory({window});
  const handleClose = () => {
    setShow(false);
    if ( props.goback ) {
      history.back();
      // wait because history.back is async
      setTimeout( ()=>{
        location.reload();
      }, 200);
    }
  };

  return (
    <Modal show={show}>
      <Modal.Header className="pt-2 pb-2">
        <Modal.Title>{_('general.error')}</Modal.Title>
        <CloseButton onClick={handleClose} className="btn-close-white"/>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            {_('general.errormsg', props.errorCode)}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

ErrorModal.propTypes ={
  errorCode: PropTypes.string,
  goback: PropTypes.bool,
};

export default ErrorModal;
