import {Toast} from 'react-bootstrap';
import './ToastMessage.css';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

function ToastMessage( props ) {
  /* console.log(props.toasts); */
  return ( <>
    {props.toasts.map( (toast, i) => {
      const classNames = ['d-flex mx-4 mt-4 fade', toast.variant].join(' ');
      return (

        <Toast

          key={`toast${i}`}
          show={toast.show}
          className={classNames}
        >
          <Toast.Body>
            {(toast.variant==='error'&&
            typeof toast.code === 'number' && toast.code != 0)?
            parse(`<b>Error: ${toast.code}</b><br>${toast.message}`) :
             (toast.message || 'undefined' ) }
          </Toast.Body>
          <button
            type="button"
            className="btn-close btn-close-white me-2 m-auto"
            onClick={()=>{
              props.handleClose(i);
            }}></button>
        </Toast>

      );
    },
    ) }
  </> );
}
ToastMessage.propTypes={
  handleClose: PropTypes.func,
  toasts: PropTypes.array,
};
export default ToastMessage;
