import {Row, Col} from 'react-bootstrap';
import UI from '@ui/UI';
import {useTranslation} from '@util/bachhelpers';
import MyFavorites from '@components/Partials/MyFavorites';


function StudyPlan(props) {
  const {_} = useTranslation();
  return (
    <Col className="p-0">
      <Row className="px-4 pt-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <UI.PageTitle backurl="/member">
            {_('app.memberhome.studyplan')}
          </UI.PageTitle>
          <p className="mt-4 pt-4 text-center"><em>
            {_('general.not_in_this_version')}</em>
          </p>
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3
          d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}
export default StudyPlan;
