/* eslint-disable max-len */
import {Badge, Form, Row, Col} from 'react-bootstrap';
import UI from '@ui/UI';
import {useEffect, useState} from 'react';
import {BA, useTranslation} from '@util/bachhelpers.js';
import CourseBoxes from '@components/Boxes/CourseBoxes';
import SemesterList from '@ui/SemesterList';
import {useSelector, useDispatch} from 'react-redux';
import {URL_TYPES} from '@util/consts';
import MyFavorites from '@components/Partials/MyFavorites';


function MyCourses(props) {
  const {_} = useTranslation();
  const [xhrload, setXhrload] = useState(false);
  const [showDeReg, setShowDeReg] = useState(false);
  const currentSemester = useSelector((state) => state.currentSemester);
  const currentStudy = useSelector((state) => state.currentStudy);
  const dispatch = useDispatch();

  const loadCourses = () => {
    if (!currentSemester.id || typeof (currentSemester.id) === 'undefined') {
      return;
    }
    if (!xhrload) {
      setXhrload(true);
      BA.ajax({
        bpoapi: true,
        url: BA.buildApiUrl(URL_TYPES.MY_COURSES,
            {semester: currentSemester.id}),
      }).then(async (response) => {
        const courses = !response.data ? []:[...response.data.courses];
        setXhrload(false);
        dispatch({
          type: 'set_courses',
          courses: courses,
        });
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  const style = {
    col: 'text-end',
    xs: {span: 7, offset: 5},
    sm: {span: 5, offset: 7},
  };

  const handleChange = (e) => {
    setShowDeReg(e.target.checked);
  };

  useEffect(() => {
    loadCourses();
  }, [currentSemester.id]);

  return (
    <Col className="p-0">
      <Row className="px-4 pt-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <UI.PageTitle backurl="/member">{_('app.memberhome.my_courses')}</UI.PageTitle>
          <div className=" d-lg-none"><SemesterList style={style} /></div>

          <Row className="mt-4">
            <Col className="col-6">
              <h5>{_('app.course_detail.degree_program')}</h5>
            </Col>
            <Col className="col-6">
              <UI.StudyList hideHeader={true} />
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col className="col-6 align-self-end pb-2 mb-1">
              <Form.Check
                id='show-de-reg'
                label={_('app.exams.show_de_reg')}
                onChange={handleChange} />
            </Col>
          </Row>
          <h5>
            <Badge bg="highlight">
              {currentStudy.studyCode === 'all' ?
              _('app.course_detail.all') :
              currentStudy.studyCode}
            </Badge>
          </h5>

          {xhrload && <UI.AjaxLoading />}
          {!xhrload && <>
            <CourseBoxes
              showDeReg={showDeReg}/>
          </>}

        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}
export default MyCourses;
