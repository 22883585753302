import UI from '@ui/UI';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
// import * as consts from '@util/consts.js';

function LogoutApp(props) {
  const {_} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const callApiOnce = useRef(false);

  useEffect( () => {
    if ( !callApiOnce.current ) {
      callApiOnce.current = true;
      BA.ajax({
        url: `/bachapi/logout`,
        bpoapi: false,
      }).then( (response) => {
        sessionStorage.removeItem('bachtoken');

        dispatch({
          type: 'set_auth',
        });

        dispatch({
          type: 'set_userinfo',
          stundent: false,
          wuniverseLinked: false,
          avatar: null,
          employee: null,
          wuniverse: null,
          roles: {},
          description: null,
          tid: null,
        });

        dispatch({
          type: 'set_redirect',
          to: '/',
          title: _('app.logout.goodbye'),
          info: _('app.logout.app_only', false),
        });

        navigate('/redirect', {replace: false});
      });
      return () => {};
    }
  }, []);


  return (
    <div className="col-lg-4 col-xxl-3 ">
      <UI.AjaxLoading />
    </div>
  );
}

export default LogoutApp;
