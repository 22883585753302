import UI from '@ui/UI';
import {useSelector} from 'react-redux';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {URL_TYPES} from '@util/consts.js';
import PropTypes from 'prop-types';
import {publicTiles} from '@util/static_tiles';
// import EmployeeInfo from './EmployeeInfo';
import WUniverseInfo from './WUniverseInfo';

function MyFavorites(props) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const tiles = useSelector((state) => state.tiles);
  const auth = useSelector((state) => state.auth);
  const userInfo = useSelector((state) => state.userInfo);
  const [xhrload, setXhrload] = useState(false);

  const createTilesInitset = (callback) => {
    BA.ajax({
      url: BA.buildApiUrl(URL_TYPES.TILES),
      method: 'post',
      data: {},
      bpoapi: true,
    }).then(async (response) => {
      console.log('fetch tiles post init callback');
      callback();
    }).catch( (e) => {
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      console.log( 'Error fetch tiles post init', e );
    });
  };

  useEffect(() => {
    if (!xhrload) {
      if (tiles && tiles.length === 0 && /* userInfo.employee === false &&*/
        userInfo.wuniverse === false ) {
        setXhrload(true);

        BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.TILES),
          bpoapi: true,
        }).then(async (response) => {
          console.log('fetch tiles callback', response.data);
          // only add tiles if reponse has tiles
          if ( response.data && response.data.tiles ) {
          // if response.data.tiles is empty
            if ( response.data.tiles.length == 0 ) {
              setXhrload(true);
              createTilesInitset( ()=> {
                dispatch({
                  type: 'set_tiles',
                  tiles: [],
                });
                setXhrload(false);
              });
            } else {
              setXhrload(false);
            }

            dispatch({
              type: 'set_tiles',
              tiles: response.data.tiles,
            });
          } else {
            setXhrload(false);
          }
        }).catch( (e) => {
          setXhrload(false);
          dispatch({
            type: 'set_errcode',
            errcode: e,
          });
          console.log( 'Error fetch tiles callback', e );
        });
      }
    }
  }, [tiles, userInfo]);

  /* if ( userInfo.employee === true ) {
    return <EmployeeInfo />;
  } else if ( userInfo.wuniverse === true ) {
    return <WUniverseInfo />;
  } else if ( !auth || userInfo.student === true ) {*/
  return <>
    { /* userInfo.employee === true && <EmployeeInfo />*/}

    { userInfo.wuniverse === true && <WUniverseInfo />}

    {!(window.location.href.includes('member/grades')||
      window.location.href.includes('member/profile')||
      window.location.href.includes('member/notifications')||
      window.location.href.includes('member/achievements')) &&
    <UI.SemesterList/>}

    {auth&&<div className="pt-2">
      <div className="row mt-4">
        <div className="col-6">
          <h2 className="col-6 colormedium d-lg-none">

            {_('app.favorites.title')}
          </h2>
        </div>

        {xhrload && <UI.AjaxLoading />}
        {props.showedittile &&
          (tiles && tiles.length > 0) &&
          <p className="col-6 text-end">
            <UI.BachLink className="text-link" href="/member/favorites">
              <UI.Icon icon="bi-edit bachicon-inline me-2" />
              <span className="">
                {_('app.favorites.edit')}</span>
            </UI.BachLink></p>}
      </div>
      {!xhrload && <UI.BachTileGroup
        className="appear"
        tiles={tiles}
      />}
    </div>}
    {!auth&&<>
      <br/><UI.BachTileGroup
        title=""
        tiles={publicTiles.auth}
      />
    </>}
  </>;
  /* } else {
    return <><UI.AjaxLoading /></>;
  }*/
}

MyFavorites.propTypes={
  showedittile: PropTypes.bool,
};
export default MyFavorites;
