/* eslint-disable new-cap */
import UI from '@ui/UI';
import {useEffect, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import ReactHtmlParser from 'html-react-parser';
import {useTranslation} from '@util/bachhelpers.js';

function RedirectPage(props) {
  const {_} = useTranslation();
  const navigate = useNavigate();
  const progressBar = useRef();
  const count = useRef( {v: 0} );

  const redirect = useSelector( (state)=>state.redirect );

  const redirectParams = useParams();
  switch ( redirectParams.errorid ) {
    case 'login500':
      redirect.title = _('general.error');
      redirect.info = _('app.redirect.idp_not_reachable');
      redirect.to = '/login';
      break;
    default:
  }


  useEffect( ()=> {
    progressBar.current.style.width = `${count.current.v}%`;
    const int = setInterval(()=>{
      count.current.v++;
      progressBar.current.style.width = `${count.current.v}%`;
      if ( count.current.v >= 120 ) {
        clearInterval( int );
        if ( redirect.to.indexOf( 'http' ) > -1 ) {
          window.location.href = redirect.to;
        } else {
          navigate( redirect.to, {replace: false} );
        }
      }
    }, 50);

    /* if no redirect text, redirect immediatly to home */
    if ( redirect.info === '...' ) {
      clearInterval( int );
      if ( redirect.to.indexOf( 'http' ) > -1 ) {
        window.location.href = redirect.to;
      } else {
        navigate( redirect.to, {replace: false} );
      }
    }

    return () => clearInterval(int);
  }, []);

  const clickHandler = (e) => {
    e.preventDefault();
    count.current.v = 120;
  };

  return (
    <div className="col-lg-4 col-xxl-3 ">

      <UI.InfoBox title={redirect.title} titlesmall="" redirect>
        <UI.InfoText>{ReactHtmlParser(redirect.info)}</UI.InfoText>
      </UI.InfoBox>

      <div className="p-4 redirectprogress">
        <div className="progress mx-4">
          <div ref={progressBar}
            className="progress-bar"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"></div>
        </div>
        <br />
        <p>
          {_('app.redirect.message')} <a className='text-link'
            href="/"
            onClick={clickHandler}
          >
            {_('app.redirect.click')}
          </a>.
        </p>
      </div>
    </div>

  );
}

export default RedirectPage;
