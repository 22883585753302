import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

function BachLink(props) {
  const isapp = useSelector((state) => state.app);

  if ( props.href.indexOf( '../' ) === 0 ) {
    return (<a href={props.href.replace('../', '/')}>{props.children}</a>);
  }

  let clickMe = props.clickHandler;


  if ( isapp && props.href.indexOf( 'idpapi' ) === -1 ) {
    clickMe = (e) => {
      /*
      e.preventDefault();
      console.log( 'e.target.href', e.target );
      console.html( e.target.href );
      top.location.href = e.target.href;
      */
      console.html( 'extern link cliked in app' );
      if ( props.clickHandler ) {
        props.clickHandler(e);
      }
    };
  }


  return (
        props.href.indexOf( 'idpapi' ) === -1 &&
        props.href.indexOf( 'http' ) !== 0 &&
        props.href.indexOf( 'mailto:' ) !== 0 &&
        props.href.indexOf( '/vanillajs' ) !== 0 ?
           <Link
             to={props.href}
             className={props.className}
             id={props.id}
             onClick={props.clickHandler}
           >{props.children}</Link> :
           <a
             href={props.href}
             target={ isapp || props.href.indexOf( 'idpapi' ) > -1 ? '_self' :
              '_blank' }
             className={props.className}
             id={props.id}
             rel="noopener noreferrer"
             onClick={clickMe}
           >{props.children}</a>
  );
}
BachLink.propTypes={
  href: PropTypes.string,
  clickHandler: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
};
export default BachLink;
