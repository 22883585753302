import UI from '@ui/UI';
import {useTranslation} from '@util/bachhelpers.js';
import './Profile.css';

function Logout(props) {
  const {_} = useTranslation();
  return (
    <>

      <div className="col-lg-5 col-xxl-4 ">
        <div className="p-4 pb-0">
          <UI.PageTitle backurl="/member">{_('app.logout.title')}</UI.PageTitle>
        </div>
        <div className="offset-2 col-8 pt-4">
          {/* <h5 className=" pt-2">{_('app.logout.app_title')}</h5>*/}
          <p className="d-grid col">
            <UI.BachLink href="/logoutidp"
              className="btn btn-primary text-link">
              {_('app.logout.global_btn')}
            </UI.BachLink>

            <span className="info mt-2">
              <span>i</span>{_('app.logout.global_info')}
            </span>
          </p>
          {/* <h5 className="mt-4 pt-4">{_('app.logout.app_title')}</h5>*/}
          <p className="d-grid mt-4 pt-4">
            <UI.BachLink
              href="/logoutapp"
              className="btn btn-secondary text-link">
              {_('app.logout.app_btn')}
            </UI.BachLink>
            <span className="info mt-2">
              <span>i</span>{_('app.logout.app_info')}
            </span>
          </p>
          {/*
          <h5 className=" pt-2">{_('app.logout.global_title')}</h5>
          <p className="d-grid col">
            <UI.BachLink href="/logoutidp"
              className="btn btn-primary text-link">
              {_('app.logout.global_btn')}
            </UI.BachLink>

            <span className="info mt-2">
              <span>i</span>{_('app.logout.global_info')}
            </span>
          </p>


          */}
        </div>
      </div>
    </>
  );
}
export default Logout;
