/* eslint-disable max-len */
import './MyAchievements.css';
import UI from '@ui/UI';
import {useEffect, useState} from 'react';
import {Row, Col, ProgressBar, Badge} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {URL_TYPES, LANGUAGES} from '@util/consts.js';
import MyFavorites from '@components/Partials/MyFavorites';

function MyAchievements(props) {
  const {_} = useTranslation();
  const [xhrLoad, setXhrload] = useState(true);
  const minRequirement = useSelector((state) => state.minRequirement);
  const studyPrograms = useSelector((state) => state.studyPrograms);
  const [spLoaded, setSpLoaded] = useState(false);
  const [exempt, setExempt] = useState(false);
  const currentStudyProgram = useSelector((state) => state.currentStudyProgram);
  const language = useSelector((state) => state.language);
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();

  const checkMinRequirementExemption = () => {
    BA.ajax({
      url: BA.buildApiUrl(URL_TYPES.MIN_REQUIREMENT_EXEMPTION),
      bpoapi: true,
    }).then(async (response) => {
      const isUserExempt = !response.data ? null : response.data.isUserExempt;
      setExempt(isUserExempt);
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
    });
  };

  const loadMinRequirement = () => {
    if (currentStudyProgram.code === -1) {
      setXhrload(true);
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.MIN_REQUIREMENT),
        bpoapi: true,
      }).then(async (response) => {
        const minRequirement = !response.data ? []: response.data.min_requirement.map(
            ({study_program_code: code, ects, semester})=>(
              {
                code,
                ects,
                semester,
              }
            ));
        dispatch({
          type: 'set_min_requirement',
          minRequirement: minRequirement,
        });
        setXhrload(false);
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    } else if (currentStudyProgram.code !== -1) {
      setXhrload(true);
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.MIN_REQUIREMENT, {code: currentStudyProgram.code}),
        bpoapi: true,
      }).then(async (response) => {
        const minRequirement = !response.data ? []: response.data.min_requirement.map(
            ({study_program_code: code, ects, semester})=>(
              {
                code,
                ects,
                semester,
              }
            ));
        dispatch({
          type: 'set_min_requirement',
          minRequirement: minRequirement,
        });
        setXhrload(false);
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    } else {
      return;
    }
  };

  useEffect(()=>{
    if (studyPrograms) {
      setSpLoaded(true);
    }
  }, [studyPrograms]);

  // Check if user in exempt when userInfo changes
  useEffect(()=> {
    checkMinRequirementExemption();
  }, [userInfo]);

  // Load minleist view data when currentStudyProgram.code changes
  useEffect(()=> {
    loadMinRequirement();
  }, [currentStudyProgram.code]);

  const getPercentage = (ects) => {
    if (ects === 0) {
      return 100;
    } else {
      return (ects / 16) * 100;
    }
  };

  const getSemOrdinal = (semester) => {
    const numberString = semester.toString();
    let ordinal;

    if (language === LANGUAGES.DE) {
      ordinal = '.';
    } else {
      ordinal = 'th';

      if (numberString.slice(-1) === '1') ordinal = 'st';
      if (numberString.slice(-1) === '2') ordinal = 'nd';
      if (numberString.slice(-1) === '3') ordinal = 'rd';

      if (numberString.length > 1) {
        if (['11', '12', '13'].includes(numberString.slice(-2))) ordinal = 'th';
      }
    }

    return numberString + ordinal;
  };

  const displayMinReq = (sp, entry) => {
    return <div key={sp.code}>
      <div className="pb-4">&nbsp;</div>
      <hr />
      <h5>
        <Badge bg="highlight">
          {sp.abbreviation}
        </Badge>
      </h5>

      <h5 className='pt-3 text-break'>
        {language === LANGUAGES.DE ? sp.nameDe : sp.nameEn}
      </h5>

      {!entry && (
        <>
          <div className='mb-3'>
            <p>{_('app.achievements.not_relevant')}</p>
          </div>
        </>
      )}

      {exempt && entry && (
        <>
          <div className='mb-3'>
            <p>{_('app.achievements.exemption')}</p>
          </div>
        </>
      )}

      {!exempt && (
        <>
          {entry && (entry.semester === 0) && (
            <>
              <div className='mb-3'>
                <p>{_('app.achievements.zero_sem')}</p>
              </div>
            </>
          )}

          {entry && (entry.semester > 0) && (
            <>
              {/* Minimum requirement achieved */}
              {entry.ects >= 16 && (
                <>
                  <div className='pb-container-success'>
                    <div className='info-heading-success'>
                      {_('app.achievements.header_achieved')}
                    </div>
                  </div>
                  <UI.InfoText>{_('app.achievements.info_success')} </UI.InfoText>
                </>
              )}

              {/* Minimum requirement not yet achieved */}
              {entry.ects < 16 && (
                <>
                  <div className={((entry.semester === 2 && entry.ects < 12) || (entry.semester > 2))? 'pb-container-danger': 'pb-container-warning'}>
                    <div className={((entry.semester === 2 && entry.ects < 12) || (entry.semester > 2))? 'info-heading-danger': 'info-heading-warning'}>
                      {_('app.achievements.header_not_achieved')}
                    </div>
                    {/* Zero ECTS - no progress bar */}
                    {entry.ects === 0 && (
                      <small className='m-0'>
                        {_('app.achievements.current_standing')} ({getSemOrdinal(entry.semester)} {_('app.achievements.semester')}): {_('app.achievements.no_ects')}
                      </small>
                    )}
                    {/* Some ECTS - progress bar */}
                    {entry.ects > 0 && (
                      <>
                        <div>
                          <ProgressBar
                            key={entry.code}
                            now={getPercentage(entry.ects)}
                            label={`${entry.ects} ${_('app.achievements.of_ects')}`}
                            className={((entry.semester === 2 && entry.ects < 12) || (entry.semester > 2))? 'progress-bar-danger': 'progress-bar-warning'}
                          />
                        </div>
                        <div className='d-flex justify-content-center'>
                          <small className='text-center mt-2'>
                            {`${_('app.achievements.current_standing')} (${getSemOrdinal(entry.semester)} ${_('app.achievements.semester')})`}
                          </small>
                        </div>
                      </>
                    )}
                  </div>
                  <UI.InfoText>{_('app.achievements.info_danger')}</UI.InfoText>
                  <UI.InfoText>{_('app.achievements.info_further')}</UI.InfoText>
                </>
              )}
            </>
          )}
        </>
      )}

    </div>;
  };

  return (

    <Col className="p-0">
      <Row className="p-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <UI.PageTitle backurl="/member">
            {_('app.achievements.title')}
          </UI.PageTitle>

          <div className="my-4">
            <UI.InfoBox>
              <UI.InfoText>{_('app.achievements.info_link')}</UI.InfoText>
              <UI.InfoText>{_('app.achievements.info_description')}</UI.InfoText>
            </UI.InfoBox>
          </div>

          {/* There are study programs (at least 1) - show select */}
          {spLoaded && (studyPrograms.length > 0) && (
            <>
              <Row className="mt-4">
                <Col className="col-6">
                  <h5 className='text-break'>{_('app.achievements.degree_program')}</h5>
                </Col>
                <Col className="col-6">
                  <UI.StudyList hideHeader={true} variantStudyProgram={true} />
                </Col>
              </Row>
            </>
          )}

          {/* Loading minRequirement finished */}
          {!xhrLoad && (
            <>
              {/* User is not of type student */}
              {!userInfo.student && (
                <>
                  <div className='my-3'>
                    <p>{_('app.achievements.not_applicable')}</p>
                  </div>
                </>
              )}

              {/* User is of type student */}
              {userInfo.student && (
                <>
                  {/* Option: View all study programs */}
                  {(currentStudyProgram.code === -1) && spLoaded && (studyPrograms.length > 0) && (
                    <>
                      {studyPrograms.map((sp) => {
                        return displayMinReq(sp, minRequirement.find((entry) => entry.code === sp.code) );
                      },
                      )}
                    </>
                  )}

                  {/* Option: View one study programs */}
                  {(currentStudyProgram.code !== -1) && (
                    <>
                      {
                        displayMinReq(currentStudyProgram, minRequirement.find((entry) => entry.code === currentStudyProgram.code))
                      }
                    </>
                  )}

                  {/* No study programs (special case) */}
                  {(currentStudyProgram.code === -1) && spLoaded && (studyPrograms.length === 0) && (
                    <>
                      <div className='my-3'>
                        <p>{_('app.achievements.not_available')}</p>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {/* Loading ongoing */}
          {xhrLoad && <UI.AjaxLoading />}
        </div>

        <div
          className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>

  );
}

export default MyAchievements;
