import UI from '@ui/UI';
import {useTranslation} from '@util/bachhelpers.js';
import {LANGUAGES} from '@util/consts';
import PropTypes from 'prop-types';
import {Badge} from 'react-bootstrap';
import {useSelector} from 'react-redux';

const getDateSelector = (row, translate) => (
  row.date ? row.date : translate('app.grades.unknown')
);

const getName = (language, nameDe, nameEn, type) => {
  const name = language === LANGUAGES.DE ? nameDe : nameEn;
  return (
    <div className="text-left w-100">
      <div className="d-block">
        <b>{type}</b>&nbsp;{name}
      </div>
    </div>
  );
};

const getGradeSelector = (row, language, translate) => {
  const note = language === LANGUAGES.DE ? row.noteDe : row.noteEn;
  return (
    <div className="text-left w-100">
      <div className="d-block">
        {note}
      </div>
      <div className="d-block">
        <i>{row.tentiative ? translate('app.grades.tentiative') : ''}</i>
      </div>
    </div>
  );
};

const TableWithHeader = (props) => {
  const language = useSelector((state) => state.language);
  const {_} = useTranslation();

  const columns = [
    {
      id: 'date',
      selector: (row) => getDateSelector(row, _),
      name: _('app.grades.table.date'),
      wrap: true,
      minWidth: '60px',
      maxWidth: '9rem',
    },
    {
      id: 'class',
      selector: (row) => getName(language, row.nameDe, row.nameEn, row.type),
      name: _('app.grades.table.class'),
      wrap: true,
      minWidth: '60px',
      maxWidth: '65%',
    },
    {
      id: 'ects',
      selector: (row) => row.ects ? row.ects : _('app.grades.unknown'),
      name: _('app.grades.table.ects'),
      wrap: true,
      minWidth: '60px',
      maxWidth: '10%',
    },
    {
      id: 'grade',
      selector: (row) => getGradeSelector(row, language, _),
      name: _('app.grades.table.grade'),
      wrap: true,
      minWidth: '68px',
      maxWidth: '17%',
    },
  ];

  return (
    <>
      <Badge bg="highlight me-2">
        {props.study && props.study.studyCode}
      </Badge>
      <UI.BachDataTable
        hideCount={true}
        columns={columns}
        data={props.grades}
        hideFilter={props.grades.length === 0}
        filterCols={['date', language === LANGUAGES.DE ? 'nameDe' : 'nameEn']}
      />
    </>
  );
};

TableWithHeader.propTypes = {
  study: PropTypes.object,
  grades: PropTypes.array,
};

export default TableWithHeader;
