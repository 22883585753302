import {useState} from 'react';
import {BA} from '@util/bachhelpers.js';

export const useFormFields = (initialState) => {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    (event) => {
      let newFields;
      if ( event.target ) { // if called by changeHandler
        let error = false;
        let fieldValue = event.target.value;
        if ( event.target.type === 'checkbox' ) {
          fieldValue = event.target.checked;
        }
        event.target.classList.remove('is-invalid');
        if (event.type === 'blur') {
          error = !BA.validateFields({
            [event.target.name]: fieldValue});
        }
        newFields = fields.map((f) => {
          if (f[event.target.name] !== undefined) {
            f[event.target.name] = fieldValue;
            f.error = error;
          }
          return f;
        });
      } else { // if not called by changeHandler, direct input values
        newFields = event;
      }
      setValues(newFields);
    },
  ];
};
