import UI from '@ui/UI';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {URL_TYPES} from '@util/consts.js';
import PropTypes from 'prop-types';

function Subject({subjects, semester}) {
  const {i18n} = useTranslation();

  return (
    <>
      {subjects.map((subject, i) => (
        <UI.BachLink
          className='text-link'
          href={BA.buildUIRouteUrl(URL_TYPES.COURSE_SUBJECTS, {
            id: subject.fk_planpunkt,
            semester: semester,
          })}
          key={i}
        >
          {subject[`planpunkt_${i18n.language}`]}
          <br />
        </UI.BachLink>
      ))}
    </>
  );
}

Subject.propTypes = {
  subjects: PropTypes.array.isRequired,
  semester: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Subject;
