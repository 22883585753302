import {Badge, Form, Row, Col} from 'react-bootstrap';
import UI from '@ui/UI';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {useEffect, useState} from 'react';
import ExamBoxes from '@components/Boxes/ExamBoxes';
import {URL_TYPES} from '@util/consts.js';
import {useSelector, useDispatch} from 'react-redux';
import MyFavorites from '@components/Partials/MyFavorites';

function Exams(props) {
  const {_} = useTranslation();
  const currentSemester = useSelector((state) => state.currentSemester);
  const currentStudy = useSelector((state) => state.currentStudy);
  const [showDeReg, setShowDeReg] = useState(false);
  const dispatch = useDispatch();


  const [xhrload, setXhrload] = useState(false);
  const loadExams = () => {
    if (!currentSemester.id || typeof (currentSemester.id) === 'undefined') {
      return;
    }
    if (!xhrload) {
      setXhrload(true);
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.EXAMS, {semester: currentSemester.id}),
        bpoapi: true,
      }).then(async (response) => {
        const exams = !response.data || response.data.exams.length === 0 ?
          [] : response.data.exams.map(
              (exam) => (
                {
                  tid: exam.tid,
                  examId: exam.pr_id,
                  shortTitle: exam.reptkuerzel,
                  titleEn: exam.pp_en,
                  titleDe: exam.pp_de,
                  ects: exam.ects,
                  time: exam.termin,
                  start: exam.termin_start,
                  end: exam.termin_ende,
                  location: exam.ort,
                  roomEn: exam.room_en,
                  roomDe: exam.room_de,
                  studyId: exam.fk_studium,
                  semester: exam.fk_semester,
                  teachers: exam.teachers.map((teacher) => (
                    {
                      shortTitle: teacher.kurztitel,
                      firstName: teacher.vorname,
                      lastName: teacher.zuname,
                      degreePrefix: teacher.akad_grad_prae,
                      degreeSuffix: teacher.akad_grad_post,
                    }
                  )),
                  hoursPerWeek: exam.wochenstunden,
                  deregistered: (exam.abmeldedatum || exam.abmelder) ?
                    true : false,
                }
              ));
        setXhrload(false);
        dispatch({
          type: 'set_exams',
          exams: exams,
        });
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  const handleChange = (e) => {
    setShowDeReg(e.target.checked);
  };

  useEffect(() => {
    loadExams();
  }, [currentSemester.id]);

  useEffect(() => {

  }, [currentStudy]);

  return (
    <Col className="p-0">

      <Row className="px-4 pt-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <UI.PageTitle backurl="/member">{_('app.exams.title')}</UI.PageTitle>
          <Row className="justify-content-end d-lg-none">
            <Col className="text-end col-6 col-xs-6 col-md-6 col-lg-5 ">
              <UI.SemesterList allSemesters={false} />
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col className="col-6 align-self-end pb-2 mb-1">
              <Form.Check
                id='show-de-reg'
                label={_('app.exams.show_de_reg')}
                onChange={handleChange} />
            </Col>
            <Col className="text-end col-6 col-sm-6 col-md-6 col-lg-5 ">
              <UI.StudyList hideHeader={false} />
            </Col>
          </Row>


          <h5>
            <Badge bg="highlight">
              {currentStudy.studyCode === 'all' ?
                            _('app.exams.all') :
                            currentStudy.studyCode}
            </Badge>
          </h5>
          {xhrload && <UI.AjaxLoading />}
          {!xhrload && <>
            <ExamBoxes showDeReg={showDeReg} />
          </>}
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3
        d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}
export default Exams;
