/* React Basics */
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';

/* Redux Store */
import {Provider} from 'react-redux';
import store from './store/store.js';

/**
 * i18next Translations
 * File: ./shared/util/bachtranslations.js
*/
import {I18nextProvider} from 'react-i18next';
import i18next from './shared/util/bachtranslations';

/* CSS & Components */
import './index.css';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

/* render */
ReactDOMClient.createRoot( document.getElementById('bachroot') ).render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
