import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Container} from 'react-bootstrap';

import {BA} from '@util/bachhelpers.js';

import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import BackToTop from '@components/Partials/BackToTop';
import AppPublic from './AppPublic';
import AppMember from './AppMember';

import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import BrowserDetector from '@components/Partials/BrowserDetector';

import ErrorBoundary from '@components/Error/ErrorBoundary';
import packageInfo from '@packageInfo';

function App(props) {
  const wrapperEl = useRef(null);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth) {
      BA.fixUserNameWidth(wrapperEl.current.offsetWidth);
      window.onresize = () => {
        BA.fixUserNameWidth(wrapperEl.current.offsetWidth);
      };
    } else {
      window.onresize = () => { };
    }
  }, [auth]);


  return (

    <Container id="wrapper" ref={wrapperEl} fluid>
      <BrowserRouter basename={REACT_APP_BASE_URL}>
        <Header />
        <ErrorBoundary>
          <main id="main">
            <BrowserDetector />
            <div className="row justify-content-md-end mx-0">
              {console.log(packageInfo.version)}
              <Routes>
                <Route path="/member/*" element={<AppMember auth={auth} />} />
                <Route path="/*" exact element={<AppPublic auth={auth} />} />
              </Routes>
            </div>
          </main>
        </ErrorBoundary>
        <Footer />
        <BackToTop />
      </BrowserRouter>
    </Container>

  );
}

export default App;
