/* eslint-disable max-len */
import UI from '@ui/UI';
import SemesterList from '@ui/SemesterList';
import {Badge, Card, Col, Row, Button} from 'react-bootstrap';
import {Search} from 'react-bootstrap-icons';
import {useEffect, useState} from 'react';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {LANGUAGES, URL_TYPES} from '@util/consts';
import {useSelector, useDispatch} from 'react-redux';
import MyFavorites from '@components/Partials/MyFavorites';
import Calendar from '@components/Calendar/Calendar';
import './LVPlaner.css';

function LVPlaner() {
  const {_} = useTranslation();
  const dispatch = useDispatch();

  let weekdays = [];
  weekdays = [
    _('schedule.mon'),
    _('schedule.tue'),
    _('schedule.wed'),
    _('schedule.thu'),
    _('schedule.fri'),
    _('schedule.sat'),
    _('schedule.sun'),
    _('schedule.today'),
  ];

  const language = useSelector((state) => state.language);
  const userInfo = useSelector((state) => state.userInfo);
  const currentSemester = useSelector((state) => state.currentSemester);
  const currentStudy = useSelector((state) => state.currentStudy);

  const courseSearchResultLVPlaner = useSelector((state) => state.courseSearchResultLVPlaner);

  const calendarMonthVisibleLVPlaner = useSelector((state) => state.calendarMonthVisibleLVPlaner);

  const [xhrload, setXhrload] = useState(false);

  const [searchText, setSearchText] = useState('');

  const [errorSearchCourse, setErrorSearchCourse] = useState(false);
  const [errorSaveDeleteCourse, setErrorSaveDeleteCourse] = useState(false);

  const [collisionVerid, setCollisionVerid] = useState({});

  const [coursesDB, setCoursesDB] = useState([]);

  const columns = [
    {
      id: 'date',
      selector: (row) => `${row.startDateFormatted}`,
      name: _('app.lvplaner.calendar_semester_date'),
      wrap: true,
      minWidth: '80px',
      maxWidth: '8rem',
    },
    {
      id: 'time',
      selector: (row) => row.courses.map((course, courseIndex) => (
        <div key={courseIndex} className='mb-2'>
          <div className={course.hasCollision ? 'text-danger' : ''}>{course.startTime} - {course.endTime}</div>
        </div>
      )),
      name: _('app.lvplaner.calendar_semester_time'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '9rem',
    },
    {
      id: 'verid',
      selector: (row) => row.courses.map((course, courseIndex) => (
        <div key={courseIndex} className='mb-2'>
          <div className={course.hasCollision ? 'text-danger' : ''}>{course.lv_nr}</div>
        </div>
      )),
      name: _('app.lvplaner.calendar_semester_verid'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '9rem',
    },
    {
      id: 'titel',
      selector: (row) => row.courses.map((course, courseIndex) => (
        <div key={courseIndex} className='mb-2'>
          <div className=''>
            <UI.BachLink
              href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS, {id: course.lv_nr, semester: course.semester})}
              className={course.hasCollision ? 'text-danger' : ''}>
              {language === LANGUAGES.EN ? course.titel_en.length > 35 ?
              course.titel_en.slice(0, 35) + '...' : course.titel_en : course.titel.length > 35 ?
              course.titel.slice(0, 35) + '...' : course.titel}
            </UI.BachLink>
          </div>
        </div>
      )),
      name: _('app.lvplaner.calendar_semester_title'),
      wrap: true,
      minWidth: '160px',
    },
    {
      id: 'room',
      selector: (row) => row.courses.map((course, courseIndex) => (
        <div key={courseIndex} className='mb-2'>
          <div className={course.hasCollision ? 'text-danger' : ''}>
            {course.raum.length > 15 ? course.raum.slice(0, 15) + '...' : course.raum}
          </div>
        </div>
      )),
      name: _('app.lvplaner.calendar_semester_room'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '10rem',
    },
  ];

  const getSavedCoursesDB = async () => {
    if (currentStudy.id === -1) {
      setCoursesDB([]);
      return;
    };

    if (!xhrload) {
      setXhrload(true);

      setCollisionVerid([]);

      try {
        const response = await BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.GET_SAVED_COURSES, {study: currentStudy.id}),
          bpoapi: true,
        });

        if (response.data) {
          const savedCoursesDB = response.data.savedCoursesDB;
          const coursesDBCopy = [];

          savedCoursesDB.forEach((course) => {
            // Find course in the array by lv_tid
            let courseEntry = coursesDBCopy.find((c) => c.lv_tid === course.lv_tid);

            // If the course does not exist, create it
            if (!courseEntry) {
              courseEntry = {
                lv_tid: course.lv_tid,
                lv_nr: course.lv_nr,
                titel: course.titel,
                titel_en: course.titel_en,
                lv_typ: course.lv_typ,
                lv_sprache: course.lv_sprache,
                lv_sprache_en: course.lv_sprache_en,
                lv_didaktik: course.lv_didaktik,
                anmeldung_von: course.anmeldung_von,
                anmeldung_bis: course.anmeldung_bis,
                semester: course.semester,
                teachers: [],
                events: [],
              };
              coursesDBCopy.push(courseEntry);
            }

            // Add teacher if not already present
            if (!courseEntry.teachers.find((t) => t.zuname === course.zuname && t.vorname === course.vorname)) {
              courseEntry.teachers.push({
                akad_grad_prae: course.akad_grad_prae,
                zuname: course.zuname,
                vorname: course.vorname,
                akad_grad_post: course.akad_grad_post,
              });
            }

            // Add event if not already present
            if (!courseEntry.events.find((e) => e.beginn === course.beginn && e.ende === course.ende && e.raum === course.raum)) {
              courseEntry.events.push({
                beginn: course.beginn,
                ende: course.ende,
                raum: course.raum,
                raum_en: course.raum_en,
              });
            }
          });

          coursesDBCopy.sort((a, b) => a.lv_tid - b.lv_tid);

          setCoursesDB(coursesDBCopy);
          setXhrload(false);
        }
      } catch (e) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      }
    }
  };

  useEffect(() => {
    getSavedCoursesDB();
  }, []);

  useEffect(() => {
    setErrorSaveDeleteCourse(false);

    getSavedCoursesDB();
  }, [currentStudy]);

  const getCourseDetail = async () => {
    if (!currentSemester.id || typeof currentSemester.id === 'undefined') return;

    if (!xhrload) {
      setXhrload(true);

      dispatch({
        type: 'set_course_search_result_lv_planer',
        courseSearchResultLVPlaner: {
          courseInfos: {},
          courseEvents: [],
          courseTeachers: [],
        },
      });

      try {
        const response = await BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.COURSE_DETAILS, {id: searchText, semester: currentSemester.id}),
          bpoapi: true,
        });

        if (response.data) {
          dispatch({
            type: 'set_course_search_result_lv_planer',
            courseSearchResultLVPlaner: {
              // courseInfos: abmeldung_bis, (abmeldung_von), anmeldeart_de, anmeldeart_en, anmeldung_bis, anmeldung_von, (anmerkung),
              // didaktik, lvtyp_1, sprache_de, sprache_en, tid, titel_de, titel_en, unixepoch_abmeldung_bis, (unixepoch_abmeldung_von),
              // unixepoch_anmeldung_bis, unixepoch_anmeldung_von, (untertitel), verid, wochenstunden_neu
              courseInfos: response.data.courses.course_info,

              // courseEvents: course_tid, end_time, room_de, room_en, semester, (short_descr), start_time, tid, unixepoch_end_time,
              // unixepoch_start_time
              courseEvents: response.data.courses.events,

              // courseTeachers: akad_grad_post, akad_grad_prae, course_tid, kurztitel, persid, prp_tid, tid, vorname, zuname
              courseTeachers: response.data.courses.teachers,
            },
          });
        }

        if (!response.data) {
          setErrorSearchCourse(true);
          setXhrload(false);
          return;
        }

        setSearchText('');
        setErrorSearchCourse(false);
        setXhrload(false);
      } catch (e) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      }
    }
  };

  const saveCourseIntoDB = async (courseInfos) => {
    if (currentStudy.id === -1) {
      setErrorSaveDeleteCourse(true);
      return;
    }

    const courseExists = coursesDB.find((course) => parseInt(courseInfos.verid) === course.lv_nr);

    if (courseExists) {
      setSearchText('');

      dispatch({
        type: 'set_course_search_result_lv_planer',
        courseSearchResultLVPlaner: {
          courseInfos: {},
          courseEvents: [],
          courseTeachers: [],
        },
      });
      return;
    }

    if (!xhrload) {
      setXhrload(true);

      try {
        const response = await BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.SAVE_COURSE),
          bpoapi: true,
          method: 'post',
          contentType: 'application/json',
          data: JSON.stringify({tid_studium: currentStudy.id, tid_lv: courseInfos.tid}),
        });

        if (response.data) {
          await getSavedCoursesDB();
        }

        setErrorSaveDeleteCourse(false);

        setSearchText('');

        dispatch({
          type: 'set_course_search_result_lv_planer',
          courseSearchResultLVPlaner: {
            courseInfos: {},
            courseEvents: [],
            courseTeachers: [],
          },
        });

        setXhrload(false);
      } catch (e) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      }
    }
  };

  const deleteSavedCourseDB = async (course) => {
    if (currentStudy.id === -1) {
      setErrorSaveDeleteCourse(true);
      return;
    }

    if (!xhrload) {
      setXhrload(true);

      try {
        const response = await BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.DELETE_COURSE),
          bpoapi: true,
          method: 'delete',
          contentType: 'application/json',
          data: JSON.stringify({tid_studium: currentStudy.id, tid_lv: course.lv_tid}),
        });

        if (response.data) {
          await getSavedCoursesDB();
        }

        setErrorSaveDeleteCourse(false);
        setXhrload(false);
      } catch (e) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      }
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const resetInput = () => {
    setSearchText('');

    dispatch({
      type: 'set_course_search_result_lv_planer',
      courseSearchResultLVPlaner: {
        courseInfos: {},
        courseEvents: [],
        courseTeachers: [],
      },
    });
  };

  const getUnixEpochTime = (dateTimeParam) => {
    const dateTime = new Date(dateTimeParam);
    return dateTime.getTime();
  };

  const checkEventCollisionCard = (searchCourseVerid, searchEventStart, searchEventEnd) => {
    const searchVerid = parseInt(searchCourseVerid);

    const searchStart = getUnixEpochTime(searchEventStart);
    const searchEnd = getUnixEpochTime(searchEventEnd);

    let collisionFound = false;
    const newCollisionVerid = {...collisionVerid};

    coursesDB.forEach((course) => {
      if (course.lv_nr === searchVerid) return;

      course.events.forEach((courseEvent) => {
        const savedStart = getUnixEpochTime(courseEvent.beginn);
        const savedEnd = getUnixEpochTime(courseEvent.ende);

        if (BA.checkCollision(searchStart, searchEnd, savedStart, savedEnd)) {
          // If this course LV_Nr isn't already in the collisions, add it with an empty array
          if (!newCollisionVerid[course.lv_nr]) {
            newCollisionVerid[course.lv_nr] = [];
          }

          // Add the search course LV_Nr to the list of conflicting courses if not already present
          if (!newCollisionVerid[course.lv_nr].includes(searchVerid)) {
            newCollisionVerid[course.lv_nr].push(searchVerid);

            newCollisionVerid[course.lv_nr].sort((a, b) => a - b);
          }

          collisionFound = true;
        }
      });
    });

    // Update collisionVerid state if necessary
    if (Object.keys(newCollisionVerid).length !== Object.keys(collisionVerid).length) {
      setCollisionVerid(newCollisionVerid);
    }

    return collisionFound;
  };

  useEffect(() => {
    coursesDB.forEach((course) => {
      course.events.forEach((event) => {
        checkEventCollisionCard(course.lv_nr, event.beginn, event.ende);
      });
    });
  }, [coursesDB]);

  const processCoursesForSemesterCal = (coursesDB) => {
    const groupedCourses = [];

    coursesDB.forEach((course) => {
      course.events.forEach((event) => {
        const startDate = new Date(event.beginn);
        const startDateFormatted = BA.printCurrentDayString(startDate, weekdays);
        const endDate = new Date(event.ende);

        let dateGroup = groupedCourses.find((group) => group.startDateFormatted === startDateFormatted);

        if (!dateGroup) {
          dateGroup = {
            startDateFormatted: startDateFormatted,
            rawStartDate: startDate,
            courses: [],
          };
          groupedCourses.push(dateGroup);
        }

        // Add the course to the date group
        dateGroup.courses.push({
          lv_nr: course.lv_nr,
          titel: course.titel,
          titel_en: course.titel_en,
          startTime: BA.getTimeString(event.beginn),
          rawStartTime: startDate,
          endTime: BA.getTimeString(event.ende),
          rawEndTime: endDate,
          semester: course.semester,
          raum: event.raum,
          raum_en: event.raume_en,
          hasCollision: false,
        });
      });
    });

    groupedCourses.sort((a, b) => a.rawStartDate - b.rawStartDate);

    groupedCourses.forEach((group) => {
      group.courses.sort((a, b) => a.rawStartTime - b.rawStartTime);

      // Check for collisions between the courses
      for (let i = 0; i < group.courses.length - 1; i++) {
        const course1 = group.courses[i];
        const course2 = group.courses[i + 1];

        if (BA.checkCollision(course1.rawStartTime, course1.rawEndTime, course2.rawStartTime, course2.rawEndTime)) {
          course1.hasCollision = true;
          course2.hasCollision = true;
        }
      }
    });

    return groupedCourses;
  };

  const groupedCourses = processCoursesForSemesterCal(coursesDB);

  return (
    <Col className="p-0">
      <Row className="px-4 pt-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">

          <UI.PageTitle backurl="goback">{_('app.lvplaner.title')}</UI.PageTitle>

          {/* Must be a student to see LV-Planer */}
          {userInfo.student ? (
            <>
              <div className=" d-lg-none"><SemesterList /></div>

              <Row className="mt-4">
                <Col>
                  <h5 className='pt-3'>{_('app.lvplaner.study_selection')}</h5>
                </Col>
                <Col className="text-end col-6 col-sm-6 col-md-6 col-lg-5 ">
                  <UI.StudyList hideHeader={true} />
                </Col>
              </Row>

              <div className='pt-4 pb-4'>
                <UI.InfoBox>
                  <UI.InfoText>
                    {_('app.lvplaner.lv_planer_tip')} {_('app.lvplaner.lv_planer_tip_description')}
                  </UI.InfoText>
                  <UI.InfoText>
                    {_('app.lvplaner.search_tip')} {_('app.lvplaner.search_tip_description')}
                  </UI.InfoText>
                </UI.InfoBox>
              </div>

              <Card className="card">
                <Card.Header className="card_header p-2">
                  {_('app.course_search.search_for_courses')}
                </Card.Header>
                <Card.Body className="card_body py-3">
                  <p className="m-0 mb-1">{_('app.course_search.course_information')}</p>
                  <input
                    type="text"
                    name="search"
                    placeholder={_('general.search')}
                    id="search_course"
                    className="form-control shadow-sm"
                    onChange={handleChange}
                    // onKeyDown={handleKeyDown}
                    value={searchText}
                    disabled={xhrload || currentSemester.title === ''} />
                  <Search
                    id="search-course-magnifier"
                    className="magnifier"
                    onClick={getCourseDetail}
                    style={{cursor: 'pointer'}} />
                </Card.Body>
              </Card>

              <Row className="g-2 my-2 d-flex justify-content-center">
                <Col className="d-grid">
                  <Button
                    type="submit"
                    id="submit-input"
                    variant="primary"
                    onClick={getCourseDetail}
                  >
                    {_('app.course_search.search_button')}
                  </Button>
                </Col>
                <Col xs={5} sm={4} className="d-grid">
                  <Button
                    type="submit"
                    id="reset-input"
                    variant="secondary"
                    onClick={resetInput}
                  >
                    {_('app.course_search.reset_button')}
                  </Button>
                </Col>
              </Row>

              <Row>
                {xhrload && <UI.AjaxLoading />}
                <div className='container mt-3'>
                  <div className='row'>

                    {/* First Card (Left Side) */}
                    <div className='col-12 col-md-5 mb-3'>
                      {!xhrload && (Object.keys(courseSearchResultLVPlaner.courseInfos).length !== 0 || courseSearchResultLVPlaner.courseEvents.length !== 0 || courseSearchResultLVPlaner.courseTeachers.length !== 0) ? (
                        <Card className="card_lvplaner">
                          <Card.Header className='card_one_header'>{courseSearchResultLVPlaner.courseInfos.verid}</Card.Header>
                          <Card.Body className='card_body_lvplaner'>
                            <h6 className='card-title'>{language === LANGUAGES.EN ? courseSearchResultLVPlaner.courseInfos.titel_en : courseSearchResultLVPlaner.courseInfos.titel_de}</h6>
                            {courseSearchResultLVPlaner.courseTeachers.map((teacher, i) => (
                              <p key={i} className='card-text mb-0'>{teacher.akad_grad_prae} {teacher.zuname} {teacher.vorname} {teacher.akad_grad_post}</p>
                            ))}
                            <div className="row mt-2">
                              <div className="col-5 col-md-5">
                                <p className='card-text'><u>{_('app.lvplaner.lv_type')}</u></p>
                              </div>
                              <div className="col">
                                <p className='card-text'>{courseSearchResultLVPlaner.courseInfos.lvtyp_1}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-5 col-md-5">
                                <p className='card-text'><u>{_('app.lvplaner.lv_language')}</u></p>
                              </div>
                              <div className="col">
                                <p className='card-text'>{language === LANGUAGES.EN ? courseSearchResultLVPlaner.courseInfos.sprache_en : courseSearchResultLVPlaner.courseInfos.sprache_de}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-5 col-md-5">
                                <p className='card-text'><u>{_('app.lvplaner.lv_mode')}</u></p>
                              </div>
                              <div className="col">
                                <p className='card-text'>{courseSearchResultLVPlaner.courseInfos.didaktik}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-5 col-md-5">
                                <p className='card-text'><u>{_('app.lvplaner.lv_registration')}</u></p>
                              </div>
                              <div className="col">
                                <p className='card-text'>{BA.printCurrentDayString(courseSearchResultLVPlaner.courseInfos.anmeldung_von, weekdays)}: {BA.getTimeString(courseSearchResultLVPlaner.courseInfos.anmeldung_von)}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-5 col-md-5">
                                <p className='card-text'><u>{_('app.lvplaner.lv_deregistration')}</u></p>
                              </div>
                              <div className="col">
                                <p className='card-text'>{BA.printCurrentDayString(courseSearchResultLVPlaner.courseInfos.anmeldung_bis, weekdays)}: {BA.getTimeString(courseSearchResultLVPlaner.courseInfos.anmeldung_bis)}</p>
                              </div>
                            </div>
                            <p className='pt-2 mb-1'><u>{_('app.lvplaner.lv_appointment')}</u></p>
                            {courseSearchResultLVPlaner.courseEvents.map((event, i) => {
                              const eventCollision = checkEventCollisionCard(courseSearchResultLVPlaner.courseInfos.verid, event.start_time, event.end_time);
                              return (
                                <div key={i} className='row'>
                                  <div className="col-5 col-md-5">
                                    <p className={eventCollision ? 'card-text text-danger' : 'card-text'}>{BA.printCurrentDayString(event.start_time, weekdays)}</p>
                                  </div>
                                  <div className="col">
                                    <p className={eventCollision ? 'card-text text-danger' : 'card-text'}>{BA.getTimeString(event.start_time)} - {BA.getTimeString(event.end_time)}</p>
                                  </div>
                                </div>
                              );
                            })}
                            <p className="mt-3">
                              <UI.BachLink className="text-link" href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS, {id: courseSearchResultLVPlaner.courseInfos.verid, semester: courseSearchResultLVPlaner.courseEvents[0].semester})}>
                                <span className="d-sm-inline">
                                  {_('app.lvplaner.lv_more_info')}</span>
                              </UI.BachLink>
                            </p>
                            <a className='btn btn-primary mt-3' onClick={() => saveCourseIntoDB(courseSearchResultLVPlaner.courseInfos)}>{_('app.lvplaner.lv_save')}</a>
                          </Card.Body>
                        </Card>
                      ) : errorSearchCourse ? (
                        <div>
                          <UI.InfoBox>
                            <UI.InfoText>
                              {_('app.lvplaner.error_search_message')}
                            </UI.InfoText>
                          </UI.InfoBox>
                        </div>
                      ) : null}
                    </div>

                    {/* Second Card (Right Side) */}
                    <>
                      <div className='col-12 col-md-7 mb-3'>
                        {!xhrload && !errorSaveDeleteCourse && coursesDB.length !== 0 && (
                          <h5 className='mb-3 text-end'>
                            <Badge bg="highlight">
                              {currentStudy.studyCode === 'all' ? _('app.exams.all') : currentStudy.studyCode}
                            </Badge>
                          </h5>
                        )}
                        {!xhrload && !errorSaveDeleteCourse && coursesDB.length !== 0 ? (
                          <div className='row row-cols-1'>
                            {/* Set maxHeight and enable scrolling */}
                            <div style={{maxHeight: '450px', overflowY: 'auto'}}>
                              {coursesDB.map((course, i) => (
                                <div key={i} className='col mb-3'>
                                  <Card className={`card_lvplaner ${collisionVerid[course.lv_nr] ? 'border-danger' : ''}`}>
                                    <div className='row no-gutters'>
                                      <div className='col-2 d-flex align-items-center justify-content-center'>
                                        <a href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS, {id: course.lv_nr, semester: course.semester})}>
                                          <Card.Header className='card_two_header'>{course.lv_nr}</Card.Header>
                                        </a>
                                      </div>
                                      <div className='col-8'>
                                        <Card.Body className='card_body_lvplaner'>
                                          <h6 className='card-title'>{language === LANGUAGES.EN ? course.titel_en.length > 35 ? course.titel_en.slice(0, 35) + '...' : course.titel_en : course.titel.length > 35 ? course.titel.slice(0, 35) + '...' : course.titel}</h6>
                                          <div>
                                            {course.teachers.map((teacher, i) => (
                                              <p key={i} className='card-text small mb-0'>{teacher.akad_grad_prae} {teacher.zuname} {teacher.vorname} {teacher.akad_grad_post}</p>
                                            ))}
                                          </div>
                                          {course.events.map((event) => {
                                            checkEventCollisionCard(course.lv_nr, event.beginn, event.ende);
                                          })}
                                          {collisionVerid[course.lv_nr] && (
                                            <div>
                                              <p className='card-text pt-2'>
                                                {_('app.lvplaner.lv_collision')} {collisionVerid[course.lv_nr].join(', ')}
                                              </p>
                                            </div>
                                          )}
                                        </Card.Body>
                                      </div>
                                      <div className='col-2 d-flex align-items-center justify-content-center'>
                                        <button id='lvplaner_trash_can_icon' className='btn btn_close_bach' onClick={() => deleteSavedCourseDB(course)}></button>
                                      </div>
                                    </div>
                                  </Card>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : errorSaveDeleteCourse ? (
                          <div>
                            <UI.InfoBox>
                              <UI.InfoText>
                                {_('app.lvplaner.error_save_delete_message')}
                              </UI.InfoText>
                            </UI.InfoBox>
                          </div>
                        ) : null}
                      </div>
                    </>
                  </div>
                </div>

                <div className='mt-2'>
                  {coursesDB.length > 0 && (
                    <>
                      <Button className='btn btn-primary mb-1' onClick={() => dispatch({
                        type: 'set_calendar_month_visible_lv_planer',
                        calendarMonthVisibleLVPlaner: !calendarMonthVisibleLVPlaner,
                      })}>
                        {calendarMonthVisibleLVPlaner ? _('app.lvplaner.calendar_semester_button_name') : _('app.lvplaner.calendar_month_button_name')}
                      </Button>

                      {calendarMonthVisibleLVPlaner ? (
                        <div>
                          <Calendar variant="monthLVPlaner" coursesDB={coursesDB} />
                        </div>
                      ) : (
                        <UI.BachDataTable
                          noDataComponent={_('app.course_search.no_course_found')}
                          hideCount={true}
                          hideFilter={true}
                          columns={columns}
                          data={groupedCourses} />
                      )}
                    </>
                  )}
                </div>
              </Row>
            </>

          /* If not a student, then Infotext is displayed */
          ) : (
            <div className='pt-4 pb-4'>
              <UI.InfoBox>
                <UI.InfoText>
                  {_('app.lvplaner.not_student_tip')} {_('app.lvplaner.not_student_tip_description')}
                </UI.InfoText>
              </UI.InfoBox>
            </div>
          )}

        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}

export default LVPlaner;
