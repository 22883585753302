import PropTypes from 'prop-types';

function HeaderPart( props ) {
  const classnames = [props.className, 'p-1'];
  return (
    <div className={classnames.join(' ')}>
      {props.children}
    </div>
  );
}

HeaderPart.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
};

export default HeaderPart;
