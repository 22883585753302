import MyFavorites from '@components/Partials/MyFavorites';
import UI from '@ui/UI';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {LANGUAGES, URL_TYPES} from '@util/consts.js';
import {useEffect, useState} from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

function InstructorCourses(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {_} = useTranslation();
  const {persid, semester} = useParams();
  const [instructorCourses, setInstructorCourses] = useState([]);
  const [instructorDetails, setInstructorDetails] = useState({});
  const [xhrload, setXhrload] = useState(false);
  const language = useSelector((state) => state.language);
  const currentSemester = useSelector((state) => state.currentSemester);


  const columns = [
    {
      id: 'verdid',
      selector: (row) => row.verid.toString().padStart(4, '0'),
      name: _('app.course_search_dt.class_nr'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '9.5rem',
    },
    {
      id: 'lvtyp_1',
      selector: (row) => row.lvtyp_1,
      name: _('app.course_search_dt.class_type'),
      wrap: true,
      minWidth: '95px',
      maxWidth: '11rem',
    },
    {
      id: 'title',
      selector: (row) => language === LANGUAGES.EN ?
        row.titel_en :
        row.titel_de,
      cell: (row) => (
        <UI.BachLink className='text-link'
          href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS,
              {id: row.verid, semester: semester})}>
          {language === LANGUAGES.EN ? row.titel_en : row.titel_de}
        </UI.BachLink>
      ),
      name: _('app.course_search_dt.title'),
      sortable: true,
      wrap: true,
    },
    {
      id: 'instructor',
      // sort instructor by the last name of the first instructor
      selector: (row) => row.teachers[0].zuname,
      cell: (row) => (
        <div>
          {row.teachers.map(({tid, vorname, zuname}, i) => (
            <UI.BachLink className='text-link'
              key={i}
              href={BA.buildUIRouteUrl(URL_TYPES.COURSE_INSTRUCTORS,
                  {id: tid, semester: semester})}>
              <div>{vorname} {zuname}</div>
            </UI.BachLink>
          ))}
        </div>
      ),
      name: _('app.course_search_dt.instructor'),
      sortable: true,
      wrap: true,
    },
  ];

  const loadInstructorCourses = (semester) => {
    if (!xhrload) {
      setXhrload(true);
      BA.ajax({
        bpoapi: true,
        url: BA.buildApiUrl(URL_TYPES.COURSE_INSTRUCTORS,
            {id: persid, semester: semester}),
      }).then(async (response) => {
        setXhrload(false);
        if (response.data.courses && response.data.courses.length > 0) {
          setInstructorCourses(response.data.courses);
          // find the teacher by id in the course list.
          // there should be just 1 matching result as persid should be unique
          setInstructorDetails({...response.data.instructor});
        } else {
          setInstructorCourses([]);
        }
      }).catch((error) => {
        setXhrload(false);
        console.error('Error loading instructor courses:', error);
        dispatch({
          type: 'set_errcode',
          errcode: error,
        });
        setInstructorCourses([]);
      });
    }
  };


  useEffect(() => {
    loadInstructorCourses(semester);
  }, [semester, persid]);

  useEffect(() => {
    if (currentSemester &&
      currentSemester.id &&
      currentSemester.id.toString() !== semester.toString()) {
      sessionStorage.removeItem('courseSearchText');
      dispatch({
        type: 'set_course_search_result',
        courseSearchResult: {
          searchText: '',
          semester: 0,
          courses: [],
        },
      });
      navigate(`/instructor/${persid}/semester/${currentSemester.id}`,
          {replace: false});
      loadInstructorCourses(currentSemester.id);
    }
  }, [currentSemester.id]);

  return (
    <Col className="p-0">
      <Row className="px-4 pt-4 instructor-course g-0">
        <Col lg={6} xxl={6} className="offset-lg-1">
          <UI.PageTitle backurl="goback">
            {_('app.instructor_course.title')}
          </UI.PageTitle>
          {
            xhrload && <UI.AjaxLoading />
          }
          {
            !xhrload && (
              <>
                <div className="text-center">
                  <Image
                    className="teacher-avatar"
                    src={instructorDetails.image_url ||
                      // eslint-disable-next-line max-len
                      `${process.env.REACT_APP_BASE_URL}/images/instructor_default_image.jpg`}
                    roundedCircle
                  />
                  <div className="teacher-name">
                    {instructorDetails.vorname && instructorDetails.zuname &&
            `${instructorDetails.vorname} ${instructorDetails.zuname}`}
                  </div>
                  {instructorDetails.akad_grad_prae &&
          <div className="teacher-title">
            {instructorDetails.akad_grad_prae}
          </div>
                  }
                  <UI.BachLink
                    className="teacher-email"
                    href={instructorDetails.mail ?
                      `mailto:${instructorDetails.mail}` : '#'}
                  >
                    <div className="d-flex justify-content-center
                    align-items-center">
                      <span className="bachicon bi-mail-thin bachicon-inline" />
                      <span className="ms-2">
                        {instructorDetails.mail || ''}
                      </span>
                    </div>
                  </UI.BachLink>
                </div>
                <Row className="justify-content-end d-lg-none mt-4">
                  <Col lg={5} md={6} xs={8}>
                    <UI.SemesterList />
                  </Col>
                </Row>
                <UI.BachDataTable
                  hideCount={false}
                  hideFilter={true}
                  columns={columns}
                  data={instructorCourses}
                />
              </>
            )
          }

        </Col>
        <Col lg={3} xxl={3} className="offset-lg-1 d-none d-lg-block">
          <MyFavorites showedittile />
        </Col>
      </Row>
    </Col>
  );
}

export default InstructorCourses;
