import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
function FormControl(props) {
  return (
    <Form.Control
      type={props.type}
      className={`shadow-sm ${props.className?props.className:''}`}
      placeholder={props.placeholder}
      name={props.name}
      id={props.name}
      autoComplete="off"
      data-repeat={props.repeat}
      data-date-after={props.dateAfter}
      onChange={props.handler}
      onBlur={props.validation?props.handler:null}
      readOnly={props.readonly}
      value={props.value}
      as={props.type==='textarea'?'textarea':undefined}
      /* isInvalid={!!'incorrect lol'} */
    />
  );
}
FormControl.propTypes ={
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  handler: PropTypes.func,
  readonly: PropTypes.bool,
  repeat: PropTypes.string,
  dateAfter: PropTypes.string,
  validation: PropTypes.bool,
  value: PropTypes.string,
};
export default FormControl;
