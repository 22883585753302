/* eslint-disable max-len */
// import {useTranslation} from '@util/bachhelpers.js';
import Box from './Box';
import {Badge, Row, Col} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {Clock, GeoAltFill} from 'react-bootstrap-icons';
import {LANGUAGES} from '@util/consts';
import PropTypes from 'prop-types';

function ExamBoxSingle( props ) {
  // const {_} = useTranslation();
  const language = useSelector((state) => state.language);
  const semesters = useSelector((state) => state.semesters);

  const exam = props.exam;

  return (
    <Box type='exam'>
      <Row className="text-start">
        <Col xs={10}>
          <strong>
            {language === LANGUAGES.DE? exam.titleDe:exam.titleEn}
          </strong>
        </Col>
        <Col xs={2}>
          <Badge bg="mainmedium" className="me-2 float-end">
            {semesters.filter((semester) =>
              semester.id === exam.semester)[0].title}
          </Badge>
        </Col>
      </Row>
      {exam.teachers.map((teacher, i)=>
        (
          <div key={'teacher'+i}>
            <span>
              {teacher.shortTitle} {teacher.firstName} {teacher.lastName}
            </span>
          </div>
        ),
      )
      }
      <p className="mt-2">{exam.description}</p>
      <Row>
        <Col xs={1} className="icon"><GeoAltFill/></Col>
        <Col xs={11} className="text">
          {exam.location&&exam.location.includes('Online')?
                        (language === LANGUAGES.DE?exam.roomDe:exam.roomEn):
                        exam.location}
        </Col>
      </Row>
      <Row>
        <Col xs={1} className="icon"><Clock /></Col>
        <Col xs={11} className="text">{exam.time}</Col>
      </Row>
      <h5 className="mt-2">
        <Badge bg="highlight" className="me-2">
          {exam.examId}
        </Badge>
        <Badge bg="maindark" className="me-2">
          {exam.hoursPerWeek && `${exam.hoursPerWeek} SST`}
        </Badge>
      </h5>
    </Box>
  );
}

ExamBoxSingle.propTypes={
  exam: PropTypes.object,
};

export default ExamBoxSingle;
