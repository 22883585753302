import './InfoBox.css';
import PropTypes from 'prop-types';

function InfoBox( props ) {
  const classnames = ['infobox p-4'];
  if ( !props.nobgcolor ) {
    if ( props.redirect ) {
      classnames.push( 'redirectbox' );
    } else {
      classnames.push( 'bg-light' );
    }
  }
  return (
    <div className={classnames.join(' ')}>
      <h2>
        {props.title}{props.titlesmall&&<small>{props.titlesmall}</small>}
      </h2>
      {props.children}
    </div>
  );
}
InfoBox.propTypes={
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  nobgcolor: PropTypes.string,
  redirect: PropTypes.bool,
  title: PropTypes.string,
  titlesmall: PropTypes.string,
};
export default InfoBox;
