import {useEffect, useState} from 'react';
import {ChevronUp} from 'react-bootstrap-icons';
import Fade from 'react-bootstrap/Fade';

const SCROLL_THRESHOLD = 200;

function BackToTop() {
  const [isFadedIn, setIsFadedIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // window.pageYOffset is old but ... window.scrollY better
      // but for cross bworser compatibility use the old one...
      setIsFadedIn(window.pageYOffset >= SCROLL_THRESHOLD);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  return <>
    <Fade in={isFadedIn}>
      <div id="backtotop"
        className="back-to-top"
        style={{display: 'block'}}
        onClick={scrollToTop}>
        <ChevronUp color="white"/>
      </div>
    </Fade>
  </>;
}

export default BackToTop;
