import {Container} from 'react-bootstrap';
import UI from '@ui/UI';
import {useTranslation} from '@util/bachhelpers.js';
import AboutGeneralInfo from '@components/Partials/AboutGeneralInfo';
import AboutSampleCode from '@components/Partials/AboutSampleCode';

function AboutTeaching(props) {
  const {_} = useTranslation();
  return (
    <div className="col-lg-6 col-xxl-4 ">
      <div className="p-4 pb-0">
        <UI.PageTitle backurl="/about">{_('app.about.api_title')}</UI.PageTitle>
      </div>
      <div className="featurebox p-4 pt-0">
        <h2 className="pt-4 mb-4">{_('app.tile.teaching')}</h2>
      </div>
      <Container fluid className="px-4">
        <AboutGeneralInfo />
        <h5 className="mt-4">{_('app.about.available_data_title')}</h5>
        <div className="p-4">

          <h5>get_events</h5>
          <dl className="mb-4">
            <dt>{_('app.about.api_description')}</dt>
            <dd>
              {_('app.about.api_get_events')}
            </dd>
            <dt>{_('app.about.api_url')}</dt>
            <dd><code>https://bach.wu.ac.at/z/BachAPI/courses/get_events</code></dd>
            <dt>{_('app.about.api_params')}</dt>
            <dd>[verid] and [semester]</dd>
            <dt>{_('app.about.api_example')}</dt>
            <dd>
              <UI.BachLink href="https://bach.wu.ac.at/z/BachAPI/courses/get_events?verid=1234;semester=10S">verid=1234;semester=10S</UI.BachLink>
            </dd>
          </dl>

          <h5>search</h5>
          <dl className="mb-4">
            <dt>{_('app.about.api_description')}</dt>
            <dd>
              {_('app.about.api_search')}
            </dd>
            <dt>{_('app.about.api_url')}</dt>
            <dd><code>https://bach.wu.ac.at/z/BachAPI/courses/search</code></dd>
            <dt>{_('app.about.api_params')}</dt>
            <dd>[query]</dd>
            <dt>{_('app.about.api_example')}</dt>
            <dd>
              <UI.BachLink href="https://bach.wu.ac.at/z/BachAPI/courses/search?query=math">query=math</UI.BachLink>
            </dd>
          </dl>

          <h5>search_full</h5>
          <dl className="mb-4">
            <dt>{_('app.about.api_description')}</dt>
            <dd>
              {_('app.about.api_search_full')}
            </dd>
            <dt>{_('app.about.api_url')}</dt>
            <dd><code>https://bach.wu.ac.at/z/BachAPI/courses/search_full</code></dd>
            <dt>{_('app.about.api_params')}</dt>
            <dd>[semester] and [query]</dd>
            <dt>{_('app.about.api_example')}</dt>
            <dd>
              <UI.BachLink href="https://bach.wu.ac.at/z/BachAPI/courses/search_full?semester=18W;query=psycho">sem=18W;query=psycho</UI.BachLink>
            </dd>
          </dl>
        </div>
        <AboutSampleCode />
      </Container>

    </div>

  );
}

export default AboutTeaching;
