import PropTypes from 'prop-types';

function InfoText(props) {
  return (
    <div className="info mt-1">
      <span>i</span> {props.children}
    </div>
  );
}
InfoText.propTypes={
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
};
export default InfoText;
