import {BA, useTranslation} from '@util/bachhelpers.js';
import * as consts from '@util/consts';
import {URL_TYPES} from '@util/consts';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {CloseButton, Col, Modal, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

function ThemeModal(props) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const [xhrload, setXhrload] = useState(false);
  const {showSupport, handleClose} = props;
  const theme = useSelector((state) => state.profile_colormode);


  const changeTheme = (themeId) => {
    // Set theme only upon server side update
    if (!xhrload) {
      if (props.public) {
        // change theme in store, no server comm
        dispatch({
          type: 'set_theme',
          profile_colormode: themeId,
        });
      } else {
        setXhrload(true);
        dispatch({
          type: 'set_theme',
          profile_colormode: themeId,
        });
        BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.THEME),
          method: 'post',
          bpoapi: true,
          data: {theme_id: themeId},
        }).then(async (response) => {
          setXhrload(false);
          // console.log(response);
          // Dispatch if response is valid
          if (response.data.new_theme_id) {
            /*
            dispatch({
              type: 'set_theme',
              profile_colormode: response.data.new_theme_id,
            });
            */
          }
        }).catch((e) => {
          dispatch({
            type: 'set_errcode',
            errcode: e,
          });
          setXhrload(false);
        });
      }
      handleClose();
    }
  };


  return (
    <Modal show={showSupport} onHide={handleClose}>
      <Modal.Header className="pt-2 pb-2">
        <Modal.Title>{_('app.nav.theme')}</Modal.Title>
        <CloseButton onClick={handleClose} className="btn-close-white" />
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <nav>
              <div className="themebuttons p-2">
                <button
                  className={`btn btn-small lnk_bulblight 
                  ${theme === 'standard' ? 'btn-light' : ''}`}
                  onClick={() => {
                    changeTheme(consts.THEME_STANDARD);
                  }}
                >
                  {_('app.nav.theme_standard')}
                </button>
                <button className={['btn btn-small lnk_bulbdark',
                  theme === 'dark' ? 'btn-light' : ''].join(' ')}
                onClick={() => {
                  changeTheme(consts.THEME_DARK);
                }}>{_('app.nav.theme_dark')}</button>
                <button className={['btn btn-small lnk_star',
                  theme === 'corporate' ? 'btn-light' : ''].join(' ')}
                onClick={() => {
                  changeTheme(consts.THEME_CORPORATE);
                }}>{_('app.nav.theme_corporate')}</button>
              </div>
            </nav>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
ThemeModal.propTypes = {
  showSupport: PropTypes.bool,
  handleClose: PropTypes.func,
  public: PropTypes.bool,
};
export default ThemeModal;
