/* eslint-disable max-len */
import MyFavorites from '@components/Partials/MyFavorites';
import UI from '@ui/UI';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {URL_TYPES} from '@util/consts.js';
import {useEffect, useState} from 'react';
import {Col, Row, Container, Spinner, Alert} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';


function MyGrades(props) {
  const {_} = useTranslation();
  const [xhrLoad, setXhrload] = useState(false);
  const currentStudy = useSelector((state) => state.currentStudy);
  const grades = useSelector((state) => state.grades);
  const filteredGrades = useSelector((state) => state.filteredGrades);
  const latestGrades = useSelector((state) => state.latestGrades);
  const studies = useSelector((state) => state.studies);
  const dispatch = useDispatch();

  const loadGrades = async () => {
    if (grades.length === 0) {
      setXhrload(true);
      try {
        const response = await BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.GRADES),
          bpoapi: true,
        });

        const grades = !response.data ? [] : response.data.grades.map((grade) => ({
          id: grade.tid,
          note: grade.note,
          noteDe: grade.note_bezeichnung_de,
          noteEn: grade.note_bezeichnung_en,
          date: grade.datum,
          sortDate: grade.sortdate,
          studyId: grade.studies,
          studyName: grade.studium,
          semesterId: grade.fk_semester,
          type: grade.typ,
          ects: grade.ects,
          nameDe: grade.pp_de,
          nameEn: grade.pp_en,
          courseId: grade.lvnr,
          tentiative: grade.tentiative,
        }));

        dispatch({type: 'set_grades', grades: grades});

        const filteredGrades = currentStudy.id === -1 ? grades : grades.filter((a) => a.studyId === currentStudy.id);
        dispatch({type: 'set_filtered_grades', filteredGrades});

        const latestGrades = filteredGrades.length < 4 ? filteredGrades : filteredGrades.slice(0, 3);
        dispatch({type: 'set_latest_grades', latestGrades});
      } catch (e) {
        dispatch({type: 'set_errcode', errcode: e});
      } finally {
        setXhrload(false);
      }
    } else {
      const filteredGrades = currentStudy.id === -1 ? grades : grades.filter((a) => a.studyId === currentStudy.id);
      dispatch({type: 'set_filtered_grades', filteredGrades});

      const latestGrades = filteredGrades.length < 4 ? filteredGrades : filteredGrades.slice(0, 3);
      dispatch({type: 'set_latest_grades', latestGrades});
    }
  };


  const displayGradesPerStudy = () => {
    if (currentStudy.id !== -1) {
      return <UI.TableWithHeader study={currentStudy} grades={filteredGrades} />;
    } else {
      const renderTablesWithDividers = studies.map((s) => {
        const gradesPerStudy = grades.filter((g) => g.studyId === s.id);
        return (
          <div key={s.id}>
            <div className="pb-4">&nbsp;</div>
            <hr />
            <UI.TableWithHeader study={s} grades={gradesPerStudy} />
          </div>
        );
      });

      return renderTablesWithDividers;
    }
  };

  useEffect(() => {
    loadGrades();
  }, [currentStudy.id]);


  return (
    <Container data-testid='my-grades-container' className='p-0'>
      <Row className="pt-4 instructor-course g-0">
        <Col className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <UI.PageTitle backurl="/member">{_('app.grades.title')}</UI.PageTitle>
          <div className='ms-4 pt-1'>
            <UI.InfoText>{_('app.lpisinfotext')}</UI.InfoText>
          </div>
          <div className="d-flex justify-content-center align-items-center pt-2">
            {xhrLoad && <Spinner animation="border" role="status" />}
          </div>
          {!xhrLoad && grades.length > 0 &&
            <div className="px-4 mt-4" data-testid='latest-grades'>
              <h5>{_('app.grades.last_grades')}</h5>
              <Row className="d-flex justify-content-around mt-3">
                {latestGrades.length > 0 && latestGrades.map((grade) => (
                  <Col key={'grade' + grade.id} xs="auto" className="flex-grow-0">
                    <UI.Grades grade={grade} />
                  </Col>
                ))}
              </Row>
              <Row className="mt-4">
                <Col xs={6}>
                  <h5>{_('app.grades.degree_program')}</h5>
                </Col>
                <Col xs={6}>
                  <UI.StudyList hideHeader={true} />
                </Col>
              </Row>
              {displayGradesPerStudy()}
            </div>}
          {!xhrLoad && grades.length === 0 &&
            <Alert className="p-2 m-2" variant="info">
              <span data-testid='text-no-grades'>{_('app.grades.no_grades')}</span>
            </Alert>}
        </Col>
        <Col className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
        </Col>
      </Row>
    </Container>
  );
}

export default MyGrades;

