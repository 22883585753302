/* eslint-disable max-len */
import {useTranslation} from '@util/bachhelpers.js';
import CourseBoxSingle from './CourseBoxSingle';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

function CourseBoxes( props ) {
  const {_} = useTranslation();
  const semesters = useSelector((state) => state.semesters);
  const courses = useSelector((state) => state.courses);
  const currentStudy = useSelector((state) => state.currentStudy);

  const [filteredRegCourses, setFilteredRegCourses] =
    useState(semesters.length>0 ? courses.filter((course) => {
      return ((course.fk_studium === currentStudy.id || currentStudy.id ===-1) &&
      course.abmeldung === 'false');
    },
    ):[],
    );

  const [filteredDeRegCourses, setFilteredDeRegCourses] =
    useState(semesters.length>0 ? courses.filter((course) => {
      return ((course.fk_studium === currentStudy.id || currentStudy.id ===-1) &&
        course.abmeldung === 'true');
    },
    ):[],
    );

  useEffect(()=>{
    setFilteredRegCourses(semesters.length > 0? courses.filter((course) => {
      return ((course.fk_studium === currentStudy.id || currentStudy.id ===-1) &&
          course.abmeldung === 'false');
    }):
      []);
    setFilteredDeRegCourses(semesters.length > 0? courses.filter((course) => {
      return ((course.fk_studium === currentStudy.id || currentStudy.id ===-1) &&
          course.abmeldung === 'true');
    },
    ):[]);
  }, [semesters.length, currentStudy, props.showDeReg]);

  return (
    <>
      {semesters.length>0 && filteredRegCourses.length > 0 &&
      <div className="pt-3 mb-2 mt-2">
        {filteredRegCourses.map((course, i)=>{
          return (
            <div key={'course_reg_'+i}>
              <CourseBoxSingle course={course} />
            </div>
          );
        })}
      </div>}

      {semesters.length>0 && filteredDeRegCourses.length > 0 && props.showDeReg &&
      <>
        <div className={`past-exams ms-1 pt-3`}>
          {_('app.courses.dereg_courses')}
        </div>
        <div className="mb-2 mt-2">
          {semesters.length>0 && filteredDeRegCourses.map((course, i)=>{
            return (
              <div key={'course_dereg_'+i}>
                <CourseBoxSingle course={course} />
              </div>
            );
          })}
        </div>
      </>}

      { semesters.length>0 &&
      filteredRegCourses.length === 0 &&
      (!props.showDeReg || filteredDeRegCourses.length === 0) &&
      (<div className="p-2 mb-2">{_('schedule.no_courses')}</div>)}

    </>
  );
}
CourseBoxes.propTypes={
  showDeReg: PropTypes.bool.isRequired,
};
export default CourseBoxes;
