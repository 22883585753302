import {Row, Badge, Col, Form} from 'react-bootstrap';
import {useTranslation} from '@util/bachhelpers.js';
import {useDispatch, useSelector} from 'react-redux';
import './SemesterList.css';
import PropTypes from 'prop-types';

function SemesterList( props ) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const semesters = useSelector((state) => state.semesters);
  const currentSemester = useSelector((state) => state.currentSemester);
  const style = props.style? props.style:{};

  const changeHandler = (e)=>{
    if ( e.target.value !== '' ) {
      dispatch({
        type: 'set_current_semester',
        currentSemester: {
          id: parseInt(e.target.value),
          title: e.target.options[e.target.selectedIndex].innerHTML,
        },
      });
    } else {
      dispatch({
        type: 'set_current_semester',
        currentSemester: semesters[0],
      });
    }
  };

  const undoCurrentSemester = () => {
    dispatch({
      type: 'set_undo_current_semester',
    });
  };

  return (
    <Row className={style.row}>
      <Col className={style.col} xs={style.xs} sm={style.sm}>
        <div className="text-wrap justify-content-start">
          <span data-testid="current-semester-label"
            className="current-semester pe-2">
            {_('app.course_search.current_semester')}
          </span>
          <Badge data-testid="current-semester-badge"
            className="badge"
            bg="mainmedium">
            {semesters && semesters.length===0 &&
            _('app.exams.loading')} {currentSemester?(currentSemester.id===-1?
            _('app.exams.all'):currentSemester.title):0}
          </Badge>
        </div>
        <Form.Select
          data-testid="all-semester-selector"
          className={`mt-2 mb-2 smallselect`}
          aria-label="select semester"
          onChange={changeHandler}
          defaultValue="">
          <option data-testid="select-semester-option"
            className="option-semester"
            value="" >
            {_('app.course_search.select_semester')}
          </option>
          { !props.allSemesters &&
            currentSemester &&
            currentSemester.id ===-1 &&
            undoCurrentSemester()}
          { props.allSemesters &&
            <option data-testid="all-semester-option"
              className="option-semester"
              key={'semester-1'}
              value={-1}>
              {_('app.exams.all')}
            </option>
          }
          {semesters && semesters.map( (semester, i)=>(
            <option data-testid={'semester-option-'+semester.id}
              className="option-semester"
              key={'semester'+i}
              value={semester.id}
            >
              {semester.title}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Row>
  );
}
SemesterList.propTypes={
  allSemesters: PropTypes.bool,
  style: PropTypes.object,
};
export default SemesterList;
