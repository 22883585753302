import {Container, Button, Form, Row, Col, Spinner} from 'react-bootstrap';
import UI from '@ui/UI';
import {useFormFields} from '@hooks/form-hook.js';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {URL_TYPES} from '@util/consts';

function ForgotPassword(props) {
  const {_} = useTranslation();

  const dispatch = useDispatch();
  const [toasts, setToasts] = useState([]);
  const [fields, handleChange] = useFormFields([
    {username: '', error: false},
  ]);
  const [xhrload, setXhrload] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ( !xhrload && fields.findIndex( (el) => el.error ) === -1 ) {
      const dataToSend = BA.sendData(fields);
      setXhrload(true);

      // check if @
      const isWuniverse = fields[0].username.indexOf( '@' ) > 0 /* &&
        fields[0].username.indexOf( '@wu.' ) === -1 &&
        fields[0].username.indexOf( '@s.wu.' ) === -1*/;


      BA.ajax({
        // wenn dataToSend.username ein @ enthält andere API ?
        url: isWuniverse ? '/bachapi/pwdlost' :
          BA.buildApiUrl(URL_TYPES.RESET_PW_SEND_CODE),
        method: e.target.method,
        contentType: 'application/json',
        bpoapi: !isWuniverse,
        method: 'POST',
        data: JSON.stringify(dataToSend),
      }).then( (response) => {
        console.log(response);
        setXhrload(false);
        if ( response.error || response.status === 'error' ) {
          setToasts( [{
            variant: 'error',
            code: response.error ? response.error.code : response.code,
            message: response.error ? response.error.message : response.message,
            show: true}]);
        } else if (isWuniverse) {
          /* setToasts( [{
            variant: 'success',
            message: response.message,
            show: true}]);*/
          BA.saveTempData( 'tempmail', dataToSend.username );

          dispatch({
            type: 'set_redirect',
            to: '/',
            title: _('app.login.change_pwd'),
            info: _('app.login.pwd_reset_wuniverse',
                false,
                dataToSend.username ),
          });

          navigate('/redirect', {replace: false});
        } else {
          BA.saveTempData( 'tempmail', dataToSend.username );

          dispatch({
            type: 'set_redirect',
            to: '/reset-password',
            title: _('app.login.change_pwd'),
            info: _('app.login.pwd_reset_pin' ),
          });

          navigate('/redirect', {replace: false});
        }
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  const handleClose = (i) => {
    const updatedToasts = toasts.map( (toast, ix)=>{
      if ( i === ix ) {
        toast.show = false;
      }
      return toast;
    });
    setToasts( updatedToasts );
  };

  return (
    <div className="col-lg-5 col-xxl-3 p-0">

      <UI.InfoBox title={_('app.login.forgot_pwd_title')} >
        <UI.InfoText>{_('app.login.forgot_pwd_info')}</UI.InfoText>
      </UI.InfoBox>

      <Form method="post"
        action="/bachapi/pwdlost"
        autoComplete="off"
        onSubmit={handleSubmit}>

        <UI.ToastMessage toasts={toasts} handleClose={handleClose} />

        <Container fluid className="p-4">
          <Form.Group className="mb-4">
            <Form.Label htmlFor="username">
              <UI.Icon icon="bi-user"/>
              <span className="labeltext">{_('app.login.forgot_label')}</span>
            </Form.Label>
            <UI.FormControl
              type="text"
              placeholder={_('app.login.forgot_label')}
              name="username"
              handler={handleChange}
            />

          </Form.Group>
          <input type="hidden"
            id="pin_send_method"
            name="pin_send_method"
            value="preferred" />
          <Row className="g-2">
            <Col className="d-grid">
              <Button type="submit" id="btn_forgot" variant="primary">
                {xhrload&&<Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                />}
                {_('app.login.reset_pwd')}
              </Button>
            </Col>
            <Col xs={6} sm={5} className="d-grid">
              <UI.BachLink
                href="/"
                className="btn btn-secondary">
                {_('general.cancel')}
              </UI.BachLink>
            </Col>
          </Row>
        </Container>
      </Form>
      <Container fluid className="p-4">
        <p>
          {_('app.signup.noaccount')}<br />
          <UI.BachLink
            href="/register"
            className="text-link">
            {_('app.signup.signup_now')}
          </UI.BachLink>
        </p>
      </Container>
    </div>

  );
}

export default ForgotPassword;
