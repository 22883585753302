import PropTypes from 'prop-types';
import './ExternalPage.css';
import {useRef, useEffect} from 'react';

function ExternalPage(props) {
  const frameNode = useRef(null);

  const resetHeightiFrame = ()=>{
    frameNode.current.setAttribute( 'height', '' );
    frameNode.current.contentWindow.postMessage( 'setheight' );
  };

  const getFrameContentSize = ()=>{
    if ( frameNode.current ) {
      document.querySelector('body').style.width = '';
      resetHeightiFrame();
    }
  };

  window.addEventListener( 'resize', getFrameContentSize );

  useEffect( ()=>{
    frameNode.current.contentWindow.onload = ()=>{
      resetHeightiFrame();
      if ( props.cropped ) {
        frameNode.current.contentWindow.postMessage( 'addcropped' );
      }
    };
  }, []);

  window.onmessage = (e)=>{
    try {
      const data = JSON.parse( e.data );
      if ( data.type == 'setheight') {
        // set height of iframe to height of iframe's content
        frameNode.current.setAttribute( 'height', data.height );
        if ( !props.cropped ) {
          // if not cropped iframe, increase width
          // of body to size of iframe's content
          if (data.width > document.documentElement.scrollWidth ) {
            document.querySelector('body').style.width = (data.width+30)+'px';
            // after increase iframe we need to reset
            // height, if content of iframe changed
            resetHeightiFrame();
          }
        }
      }
    } catch (e) {}
  };
  return (
    <div className="iframe_wrapper">
      <iframe src={props.src} ref={frameNode} />
    </div>

  );
}
ExternalPage.propTypes={
  src: PropTypes.string,
  cropped: PropTypes.bool,
};
export default ExternalPage;
