/* eslint-disable camelcase */
/* eslint-disable max-len */
import {Row, Col} from 'react-bootstrap';
import UI from '@ui/UI';
import Calendar from '@components/Calendar/Calendar';
import {useSelector, useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {BA, useTranslation} from '@util/bachhelpers.js';
import MyFavorites from '@components/Partials/MyFavorites';
import MyNotifications from '@components/Partials/MyNotifications';
import PropTypes from 'prop-types';

function Home(props) {
  const {_} = useTranslation();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);
  const baseInfo = useSelector((state) => state.profileBaseInfo);

  useEffect(() => {
    // only if /member/app was called and env REACT_APP_MOBILE is set true
    // check if page is open in inappbrowser => set_app
    if (props.app && process.env.REACT_APP_MOBILEAPP) {
      console.html(navigator.userAgent);
      let mainwin = false;
      if (window.cordova_iab) mainwin = cordova_iab;
      else if (window.webkit && webkit.messageHandlers && webkit.messageHandlers.cordova_iab) mainwin = webkit.messageHandlers.cordova_iab;
      if (mainwin) {
        const stringifiedMessageObj = JSON.stringify({type: 'SET_STORAGE', name: 'mywu_app', data: '1'});
        mainwin.postMessage(stringifiedMessageObj);
        dispatch({
          type: 'set_app',
        });
      } else if ( process.env.REACT_APP_ENV == 'LOCAL' ) {
        sessionStorage.setItem( 'mywu_app', '1' );
        dispatch({
          type: 'set_app',
        });
      }
    }
  }, []);

  return (
    <Col className="p-0">
      {userInfo.student && !userInfo.wuniverseLinked && !userInfo.employee &&
        <UI.InfoBox title={(baseInfo && baseInfo.firstName && baseInfo.firstName !== '') ?
          baseInfo.firstName + _('app.member_home_title') :
          _('app.infostudent_title')}>
          <UI.InfoText>{_('app.infostudent_text')}</UI.InfoText>
        </UI.InfoBox>}
      <Row className="p-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          {(userInfo.student || userInfo.employee || userInfo.wuniverse) &&
            <>
              {BA.hasPermission('notifications') && <>
                <Row className="mt-2">
                  <Col sm={8}>
                    <h2 className="colormedium">
                      {_('app.notifications.title')}</h2>
                  </Col>
                  <Col sm={4} className="text-end">
                    <UI.BachLink className="text-link" href="/member/notifications">
                      <UI.Icon icon="bi-lightbulb2 bachicon-inline me-2" />
                      <span className="">
                        {_('app.notifications.show_all')}</span>
                    </UI.BachLink>
                  </Col>
                </Row>
                <MyNotifications />
              </>}
              <h2 className="colormedium">{_('app.memberhome.my_schedule')}</h2>
              <Calendar variant="week" />
            </>}
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 ">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}

Home.propTypes = {
  app: PropTypes.bool,
};

export default Home;
