import {useTranslation, BA} from '@util/bachhelpers.js';
import UI from '@ui/UI';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import NotificationBoxes from '../Boxes/NotificationBoxes';
import PropTypes from 'prop-types';
import {URL_TYPES} from '@util/consts.js';
// import {Row, Col} from 'react-bootstrap';

function MyNotifications( props ) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const [xhrload, setXhrload] = useState(false);
  const userNotifications = useSelector((state) => state.notifications);
  const [loadNotifications, setLoadNotifications] = useState(0);


  useEffect(()=>{
    console.log('Loading...', loadNotifications);
    setXhrload(true);
    BA.ajax({
      method: 'get',
      data: {hide_checked: props.all ? false: true},
      bpoapi: true,
      url: BA.buildApiUrl(URL_TYPES.NOTIFICATIONS),
    }).then(async (response) => {
      setXhrload(false);

      if (response.data.notifications /* &&
        response.data.notifications.length > 0*/) {
        // eslint-disable-next-line max-len
        const notifications = response.data.notifications.map((notification) => ({
          // id of user notification
          id: notification.tid,
          // id of notification
          notification_id: notification.fk_notification,
          type: notification.fk_notification_type,
          group: notification.fk_notification_group,
          titel_de: notification.titel_de,
          titel_en: notification.titel_en,
          text_de: notification.text_de,
          text_en: notification.text_en,
          valid_from: notification.valid_from,
          valid_to: notification.valid_to,
          changed_at: notification.changed_at,
          changed_by: notification.changed_by,
          is_read: notification.is_read,
          delivered_at: notification.delivered_at,
        }));
        console.log('User Notifications:', notifications);
        dispatch({
          type: 'set_notifications',
          notifications: notifications,
        });
      }
    })
        .catch((e) => {
          dispatch({
            type: 'set_errcode',
            errcode: e,
          });
          setXhrload(false);
        });
  }, [loadNotifications]);

  const setNotificationRead = (id)=>{
    setXhrload(true);
    BA.ajax({
      method: 'put',
      bpoapi: true,
      url: BA.buildApiUrl(URL_TYPES.NOTIFICATION)+'/'+id,
    }).then(async (response) => {
      // setXhrload(false);
      // load new notifications
      setLoadNotifications( loadNotifications+1 );
    }).catch((e)=>{
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  return (
    <>
      {xhrload&&<UI.AjaxLoading />}
      {!xhrload&&<>
        {props.all&&<h5 className="mt-4">{_('app.notifications.unread')}</h5>}

        {userNotifications.filter((el)=>el.is_read===0).length>0 ? <>
          <NotificationBoxes
            notifications={userNotifications.filter((el)=>el.is_read===0)}
            setNotificationRead={setNotificationRead} />
        </> : <>
          <p className="pb-4">
            <em>{_('app.notifications.no_unread')}</em></p>
        </>}


        {props.all&&<><h5 className="mt-4">{_('app.notifications.read')}</h5>

          {userNotifications.filter((el)=>el.is_read===1).length>0?<>
            <NotificationBoxes
              notifications={userNotifications.filter((el)=>el.is_read===1)}
              setNotificationRead={setNotificationRead} />
          </>:<>
            <p className="pb-4">
              <em>{_('app.notifications.no_read')}</em></p>
          </>}
        </>}
      </>}
    </>
  );
}
MyNotifications.propTypes={
  all: PropTypes.bool,
};
export default MyNotifications;
