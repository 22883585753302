import {Spinner} from 'react-bootstrap';
import {useTranslation} from '@util/bachhelpers.js';
import PropTypes from 'prop-types';

function AjaxLoading(props) {
  const {_} = useTranslation();
  return (
    <div className={!props.small?'loader text-center m-4 p-4':'loader'}>
      <Spinner animation="border"></Spinner>
      {!props.notext && !props.small &&
        <div className="mt-4">{_('app.loading')}</div>}
    </div>
  );
}
AjaxLoading.propTypes={
  notext: PropTypes.bool,
  small: PropTypes.bool,
};
export default AjaxLoading;
