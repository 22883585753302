/* eslint-disable max-len */
import UI from '@ui/UI';
import {Row, Col} from 'react-bootstrap';
import {BA, useTranslation} from '@util/bachhelpers';
import {useSelector} from 'react-redux';

import MyFavorites from '@components/Partials/MyFavorites';
import AllNotifications from '@components/Partials/AllNotifications';

function NotificationCenter(props) {
  const {_} = useTranslation();
  const userInfo = useSelector((state) => state.userInfo);
  return (
    <Col className="p-0">

      <Row className="p-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          {BA.hasPermission('notification-admin', userInfo) ? (
            <>
              <UI.PageTitle backurl="/member">
                {_('app.notificationcenter.title')}
              </UI.PageTitle>
              <AllNotifications />
            </>
          ) : (
            <>
              <UI.NoPermission />
              <div className="d-lg-none mb-4"></div>
            </>
          )}
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 ">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}
export default NotificationCenter;
