import BachLink from '@ui/BachLink';
import {BA, useTranslation} from '@util/bachhelpers.js';
import PropTypes from 'prop-types';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import {createRef} from 'react';

function MainMenuGroup(props) {
  const {_, i18n} = useTranslation();
  const {handleClose} = props;
  const ref = createRef();
  return (
    <>
      {props.title && <>
        <h2 className="">{props.title}</h2>
        <hr />
      </>}
      <nav>
        <ul className="list-unstyled">
          {props.links.map((link, i) => (
            <OverlayTrigger
              key={`overlay-${link.text}`}
              placement="top"
              overlay={
                link.tooltip?
                <Tooltip id={`tooltip-top`}>
                  {_(`navigation.${link.text}.name`)}
                </Tooltip>:<></>
              }
            >
              <li ref={ref}
                className={(link.className ? `${link.className} lnk_icon` : '')}
                key={`link${i}`}>
                <BachLink
                  href={
                    link.href.indexOf('idpapi') === -1 ?
                      (link.href.search(/%lang/) > -1 ?
                        link.href.replace(/%lang/, i18n.language) : link.href) :
                      BA.BACHURL + link.href}
                  clickHandler={handleClose}>
                  {link.tooltip?
                  _(`navigation.${link.text}.abbreviation`):
                  _(`navigation.${link.text}`)}
                </BachLink>
              </li>
            </OverlayTrigger>
          ))}
        </ul>
      </nav>
    </>
  );
}

MainMenuGroup.propTypes = {
  handleClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  links: PropTypes.array,
};
export default MainMenuGroup;
