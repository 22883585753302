/* eslint-disable max-len */
import {configureStore} from '@reduxjs/toolkit';
import {BA} from '../shared/util/bachhelpers.js';
import * as consts from '../shared/util/consts';

let bachThemeSession = sessionStorage.getItem('bach-theme-style');
bachThemeSession = consts.THEMES.find( (el)=>bachThemeSession == el.name );
bachThemeSession = bachThemeSession ? bachThemeSession.name : 'standard';
const bachSelectedDay =
  Number.isNaN(sessionStorage.getItem('bach-selectedDay')) ?
    Number(sessionStorage.getItem('bach-selectedDay')):
    Date.now();
const bachSelectedDayDate = new Date(bachSelectedDay);
const initialState = {
  redirect: {to: '/', info: '...'},
  tempmail: '',
  auth: BA.checkTokenExpired(),
  profile_colormode: bachThemeSession ? bachThemeSession : 'standard',
  userInfo: {
    student: false,
    wuniverseLinked: false,
    avatar: null,
    description: null,
    roles: {},
    tid: null,
  },
  selectedDay: Number(bachSelectedDay),
  selectedMonday: BA.getMondayOfCurrentWeek(Number(bachSelectedDay)),
  scheduleDates: [],
  calDateWeek: {
    weekdays: BA.printWeek(BA.getMondayOfCurrentWeek(Date.now()), Date.now()),
  },
  firstOfMonth: new Date(Date.UTC(bachSelectedDayDate.getFullYear(),
      bachSelectedDayDate.getMonth(),
      1)).getTime(),
  firstMondayOfMonth:
    BA.getMondayOfCurrentWeek(
        new Date(Date.UTC(bachSelectedDayDate.getFullYear(),
            bachSelectedDayDate.getMonth(), 1)).getTime()),
  calDateMonth: {
    days: BA.printMonth(BA.getMondayOfCurrentWeek(
        new Date(Date.UTC(bachSelectedDayDate.getFullYear(),
            bachSelectedDayDate.getMonth(), 1)).getTime()), Date.now()),
    month: [],
  },
  notifications: [],
  notificationTypes: [],
  notificationGroups: [],
  tiles: [/* {ranking: 1,
    title_de: 'Kurssuche',
    title_en: 'Courses Search',
    icon: 'bi-search-green',
    href: '/member/coursessearch',
    variant: 'light',
  loaded: false}*/],

  theme: consts.THEMES[0].name,
  language: consts.LANGUAGES[consts.DEFAULT_LANG],
  pastCurrentSemester: {value: 0, shortTitle: ''},
  currentSemester: {value: 0, shortTitle: ''},
  semesters: [],

  profileBaseInfo: {
    id: null,
    loginName: null,
    firstName: null,
    lastName: null,
    prefixTitle: null,
    suffixTitle: null,
    dob: null,
  },

  profileAccountInfo: {
    telNr: null,
    email: null,
    password: null,
    wlanPassword: null}, // TO UPDATE DESIGN
  profilePersonalData: {
    address: null,
    co: null,
    city: null,
    postCode: null,
    countryId: null,
    countryCode: null,
    countryNameEn: null,
    countryName: null},
  profileRecovery: {email: null, telNr: null},
  countries: [],
  currentStudy: {
    id: -1,
    studyCode: 'all',
    nameEn: '',
    nameDe: '',
    admissionCode: ''},
  studies: [],
  exams: [],
  courses: [],
  grades: [],
  filteredGrades: [],
  latestGrades: [],
  courseSearchResult: {
    searchText: '',
    semester: 0,
    courses: [],
  },
  errcode: 0,
  studyPrograms: null,
  minRequirement: [],
  currentStudyProgram: {
    code: -1,
    nameEn: '',
    nameDe: '',
  },
  mappingInfo: {
    isMappingVisible: false,
    isUserMapped: false,
  },
  app: false, // if mywu was open in app
  theses: [],
  thesis: {},
  tmgUserInfo: {},
  tmgCurrentRole: '',
  courseSearchResultLVPlaner: {
    courseInfos: {},
    courseEvents: [],
    courseTeachers: [],
  },
  calendarMonthVisibleLVPlaner: false,
};


function bachReducer(state = initialState, action) {
  switch (action.type) {
    case 'set_app':
      return {...state, app: true};
    case 'set_errcode':
      return {...state, errcode: isFinite(action.errcode) ?
        action.errcode : state.errcode};
    case 'set_tiles':
      const newTiles = (action.tiles === undefined || Object.keys(action.tiles).length === 0) ?
        initialState.tiles : action.tiles;
      return {...state, tiles: newTiles};
    case 'set_theme':
      const themeName = consts.THEMES.find((theme) =>
        (theme.id === action.profile_colormode))['name'];
      sessionStorage.setItem('bach-theme-style', themeName);
      if (state.profile_colormode !== themeName) {
        document.querySelector('#bachtheme').href =
        // eslint-disable-next-line max-len
        `${process.env.REACT_APP_BASE_URL}/css/bach-theme-${themeName}.css`;
      }
      return {...state, profile_colormode: themeName};
    case 'set_auth':
      return {...state, auth: (typeof(action.auth) === 'boolean') ?
       action.auth : BA.checkTokenExpired()};
    case 'set_tempmail':
      return {...state, tempmail: action.tempmail};
    case 'set_redirect':
      return {...state,
        redirect: {to: action.to, info: action.info, title: action.title}};
    case 'set_userinfo':
      const updatedUserInfo = {
        ...state.userInfo,
        student: action.student !== undefined ? action.student : state.userInfo.student,
        wuniverseLinked: action.wuniverseLinked !== undefined ? action.wuniverseLinked : state.userInfo.wuniverseLinked,
        avatar: action.avatar !== undefined ? action.avatar : state.userInfo.avatar,
        employee: action.employee !== undefined ? action.employee : state.userInfo.employee,
        wuniverse: action.wuniverse !== undefined ? action.wuniverse : state.userInfo.wuniverse,
        description: action.description !== undefined ? action.description : state.userInfo.description,
        roles: action.roles !== undefined ? action.roles : state.userInfo.roles,
        tid: action.tid !== undefined ? action.tid : state.userInfo.tid,
      };
      return {
        ...state,
        userInfo: updatedUserInfo,
      };
    case 'set_selected_day':
      sessionStorage.setItem('bach-selectedDay',
        Number.isNaN(action.selectedDay)?
          new Date().getTime():
          action.selectedDay);
      return {...state, selectedDay: action.selectedDay};
    case 'set_selected_monday':
      return {
        ...state,
        selectedMonday: BA.getMondayOfCurrentWeek(action.selectedMonday),
      };
    case 'set_schedule_dates':
      if (action.scheduleDates.length>0) {
        return {...state, scheduleDates: action.scheduleDates};
      } else {
        return state;
      }
    case 'set_cal_date_week':
      return {...state, calDateWeek: action.calDateWeek};
    case 'set_first_of_month':
      return {...state, firstOfMonth: action.firstOfMonth};
    case 'set_first_monday_of_month':
      const tempDate = new Date( action.firstMondayOfMonth );
      return {...state, firstMondayOfMonth: BA.getMondayOfCurrentWeek(
          new Date(Date.UTC(tempDate.getFullYear(),
              tempDate.getMonth(), 1)).getTime())};
      // return {...state, firstMondayOfMonth: action.firstMondayOfMonth};
    case 'set_cal_date_month':
      return {...state, calDateMonth: action.calDateMonth};
    case 'set_language':
      /* https://github.com/vincenzal/bach-makeover/issues/75
      if (state.countries.length > 0) {
        state.countries = (action.language === consts.LANGUAGES.DE ?
          state.countries.sort((a, b) =>
            a.countryName.localeCompare(b.countryName)) :
          state.countries.sort((a, b) =>
            a.countryNameEn.localeCompare(b.countryNameEn)));
      }*/

      sessionStorage.setItem('bach-language', action.language );

      let countriesTemp = [...state.countries];
      if (countriesTemp.length > 0) {
        countriesTemp = (action.language === consts.LANGUAGES.DE ?
          countriesTemp.sort((a, b) =>
            a.countryName.localeCompare(b.countryName)) :
          countriesTemp.sort((a, b) =>
            a.countryNameEn.localeCompare(b.countryNameEn)));
      }

      return {...state, language: action.language, countries: countriesTemp};
    case 'set_current_semester':
      return {...state,
        pastCurrentSemester: state.currentSemester,
        currentSemester: action.currentSemester,
      };
    case 'set_undo_current_semester':
      return {...state,
        currentSemester: state.pastCurrentSemester,
      };
    case 'set_semesters':
      return {...state, semesters: action.semesters};
    case 'set_current_study':
      return {...state,
        currentStudy: action.currentStudy,
      };
    case 'set_studies':
      return {...state, studies: action.studies};
    case 'set_exams':
      return {...state, exams: action.exams};
    case 'set_courses':
      return {...state, courses: action.courses};
    case 'set_grades':
      return {...state, grades: action.grades};
    case 'set_filtered_grades':
      return {...state, filteredGrades: action.filteredGrades};
    case 'set_latest_grades':
      return {...state, latestGrades: action.latestGrades};
    case 'set_course_search_result':
      return {...state, courseSearchResult: action.courseSearchResult};
    case 'set_profile_base_info':
      return {...state,
        profileBaseInfo: action.profileBaseInfo,
      };
    case 'set_profile_account_info':
      return {...state,
        profileAccountInfo: action.profileAccountInfo,
      };
    case 'set_profile_personal_data':
      return {...state,
        profilePersonalData: action.profilePersonalData,
      };
    case 'set_profile_recovery':
      return {};
    case 'set_countries':
      if (action.countries.length < 1) {
        return;
      }
      const countries = (state.language === 'de' ?
        action.countries.sort((a, b) =>
          a.countryName.localeCompare(b.countryName)) :
        action.countries.sort((a, b) =>
          a.countryNameEn.localeCompare(b.countryNameEn)));
      return {...state,
        countries: countries,
      };
    case 'set_notifications':
      console.log( action.notifications );
      action.notifications.sort(
          (a, b)=>a.date<b.date ? 1 : -1,
      );
      return {...state,
        notifications: action.notifications};

    case 'set_notification_types':
      return {...state,
        notificationTypes: action.notificationTypes};
    case 'set_notification_groups':
      return {...state,
        notificationGroups: action.notificationGroups};

    case 'set_study_programs':
      return {...state, studyPrograms: action.studyPrograms};
    case 'set_min_requirement':
      return {...state, minRequirement: action.minRequirement};
    case 'set_current_study_program':
      return {...state,
        currentStudyProgram: action.currentStudyProgram,
      };
    case 'set_mapping_info':
      return {...state,
        mappingInfo: action.mappingInfo,
      };
    case 'set_theses':
      return {...state, theses: action.theses};
    case 'set_thesis':
      return {...state, thesis: action.thesis};
    case 'set_tmg_user_info':
      return {...state, tmgUserInfo: action.tmgUserInfo};
    case 'set_tmg_current_role':
      return {...state, tmgCurrentRole: action.tmgCurrentRole};
    case 'set_course_search_result_lv_planer':
      return {...state, courseSearchResultLVPlaner: action.courseSearchResultLVPlaner};
    case 'set_calendar_month_visible_lv_planer':
      return {...state, calendarMonthVisibleLVPlaner: action.calendarMonthVisibleLVPlaner};
    default:
      return state;
  }
}

export default configureStore({
  reducer: bachReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: {warnAfter: 128},
    serializableCheck: {warnAfter: 128},
  }),
});
