import {Modal, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {useTranslation} from '@util/bachhelpers.js';

function ConfirmModal(props) {
  const {_} = useTranslation();

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>{_('general.confirmmodal')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          {_('general.cancel')}
        </Button>
        <Button variant="primary" onClick={props.handler}>
          {props.buttontext}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
ConfirmModal.propTypes ={
  show: PropTypes.bool,
  handler: PropTypes.func,
  handleClose: PropTypes.func,
  message: PropTypes.object,
  buttontext: PropTypes.string,
};
export default ConfirmModal;
