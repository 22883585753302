import UI from '@ui/UI';
import {Badge} from 'react-bootstrap';
import './Box.css';
import PropTypes from 'prop-types';
import AjaxLoading from '../UIElements/AjaxLoading';

function Box( props ) {
  const classNames = ['row m-0 datebox mb-3', props.type];
  const classNamesBoxContent = [!props.noContentPadding?'p-2':'p-0'];
  if ( props.footer ) {
    classNamesBoxContent.push( 'boxseperator' );
  }
  if ( props.href ) {
    return (
      <UI.BachLink className={classNames.join(' ')} href={props.href}>
        {props.children}
        <UI.Icon icon="bi-chevron-right-light" variant="rightcenter" />
      </UI.BachLink>
    );
  } else {
    return (
      <div className={classNames.join(' ')}>
        {props.closeHandler&&<button
          className="btn btn-close btn_close_bach"
          onClick={props.closeHandler}></button>}
        <div className="box-functions">
          {props.deleteItem&&<AjaxLoading small />}
          {props.editHandler&&!props.deleteItem&&<button
            className="btn bi-edit2"
            onClick={props.editHandler}></button>}
          {props.deleteHandler&&!props.deleteItem&&<button
            className="btn btn_close_bach"
            onClick={props.deleteHandler}></button>}
        </div>
        {props.title && props.badge && (
          <>
            <div className="col-9 pt-2 boxseperator">
              <h5>
                {props.title}
                {props.didaktik.map((item, index) =>
                  <UI.Icon key={index} icon={item.icon} didaktik></UI.Icon>,
                )}
              </h5>
            </div>
            <div className="col-3 pt-2 text-end boxseperator">
              <Badge bg="mainmedium">{props.badge}</Badge>
            </div>
          </>)}
        {props.title && !props.badge && props.variant!=='databox' &&
        (<div className="col-12 pt-2 boxseperator">
          <h5>{props.title}</h5>
        </div>)}
        {props.title && !props.badge && props.variant==='databox' &&
        (<div className="col-12 pt-2">
          <h2 className="mainmedium">
            {props.title}
            {props.clickHandler&&
            <span onClick={props.clickHandler}
              className="bachicon m-2 bi-chevron-right-green"/>}
          </h2>

        </div>)}
        <div className={classNamesBoxContent.join(' ')}>
          {props.children}
        </div>
        {props.footer&&(<div className="pt-2 pb-2">{props.footer}</div>)}
      </div>
    );
  }
}
Box.propTypes={
  title: PropTypes.string,
  didaktik: PropTypes.arrayOf(PropTypes.object),
  footer: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  clickHandler: PropTypes.func,
  type: PropTypes.string,
  noContentPadding: PropTypes.bool,
  badge: PropTypes.string,
  variant: PropTypes.string,
  href: PropTypes.string,
  closeHandler: PropTypes.func,
  editHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  deleteItem: PropTypes.bool,
};
export default Box;
