import {useTranslation} from '@util/bachhelpers.js';

function AboutSampleCode() {
  const {_} = useTranslation();
  return <>
    <h5 className="mt-4">{_('app.about.sample_code_title')}</h5>
    <pre className="code p-2">{`
#!/usr/bin/python

try:
    # python2
    from urllib2 import Request, urlopen
except ImportError:
    # python3
    from urllib.request import Request, urlopen
import json
import pprint

url = 'https://bach.wu.ac.at/z/BachAPI/courses/'

data = {
    'id': '0815',
    'method': 'get_events',
    'params': (1234, '10S'),
}

data = json.dumps(data)
data = data.encode('utf-8')
headers = {
    'Content-Type': 'application/json-rpc',
}

req = Request(url, data, headers)

resp = urlopen(req)
data = resp.read()
data = data.decode('utf-8')

data = json.loads(data)

pprint.pprint(data)
`}
    </pre>
  </>;
}

export default AboutSampleCode;
