import {LANGUAGES} from '@util/consts';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

const Grades = (props) => {
  const language = useSelector((state) => state.language);
  return (
    <div data-testid="latest-grade" className="d-block text-center">
      <div className="note">
        {props.grade.note}
      </div>
      <div className="note-title">
        {language === LANGUAGES.DE ? props.grade.nameDe : props.grade.nameEn}
      </div>
    </div>
  );
};
Grades.propTypes = {
  grade: PropTypes.object,
};
export default Grades;
