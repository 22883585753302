import PropTypes from 'prop-types';
import React from 'react';
import ErrorModal from './ErrorModal';
import {BA} from '@util/bachhelpers.js';
// import {URL_TYPES} from '@util/consts.js';
/*
const generateErrorCode = () => {
  return (Math.floor( Math.random()*90000 ) + 10000).toString();
};
*/

const logMyErrors = (code, error, errorInfo) => {
  /* const datetime = new Date();
  console.log( `ERR#${code}`, errorInfo, error);
  console.log(datetime);
  BA.ajax({
    url: BA.buildApiUrl(URL_TYPES.LOG_ERROR),
    method: 'post',
    data: {
      code,
      timestamp: datetime.getTime(),
      datetime: datetime.toISOString(),
      ...errorInfo},
  });*/
  BA.ajaxError(code, error, errorInfo);
};

/**
 * https://reactjs.org/docs/error-boundaries.html
 * Only class components can be error boundaries.
 * In practice, most of the time you’ll want to
 * declare an error boundary component once and use
 * it throughout your application.
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, errorCode: ''};
    window.onerror = (ev, source, lineno, colno, error ) => {
      const errorCode = BA.generateErrorCode();
      this.setState({hasError: true, errorCode} );
      logMyErrors(errorCode, error, {error, source, lineno, colno});
      return true;
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logMyErrors(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <main id="main">
        <div>
          <ErrorModal errorCode={this.state.errorCode} goback={true} />
        </div>
      </main>;
    }
    return this.props.children;
  }
};

ErrorBoundary.propTypes ={
  children: PropTypes.object,
};

export default ErrorBoundary;
