
import UI from '@ui/UI';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

function PageTitle( props ) {
  const navigate = useNavigate();

  if ( props.backurl === 'goback' ) {
    return (
      <>
        <h2 data-testid='page-title'
          className="pagetitle">
          {props.backurl&&
            <UI.BackButton href="/" clickHandler={(e)=>{
              e.preventDefault(); navigate(-1);
            }} />}{props.children}
        </h2>

      </>
    );
  } else {
    return (
      <>
        <h2 data-testid='page-title'
          className="pagetitle">
          {props.backurl&&
          <UI.BackButton href={props.backurl}
            clickHandler={props.clickHandler} />}{props.children}
        </h2>

      </>
    );
  }
}
PageTitle.propTypes={
  pagetitle: PropTypes.string,
  backurl: PropTypes.string,
  clickHandler: PropTypes.func,
  children: PropTypes.string,
};
export default PageTitle;
