/* eslint-disable max-len */
import UI from '@ui/UI';
import {Row, Col} from 'react-bootstrap';
import {BA, useTranslation} from '@util/bachhelpers';

import MyFavorites from '@components/Partials/MyFavorites';
import MyNotifications from '@components/Partials/MyNotifications';

function Notifications(props) {
  const {_} = useTranslation();

  return (
    <Col className="p-0">

      <Row className="p-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          {BA.hasPermission('notifications')?<>
            <UI.PageTitle backurl="/member">
              {_('app.notifications.title')}
            </UI.PageTitle>
            <MyNotifications all />
          </>:
            <UI.NoPermission />}
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 ">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}
export default Notifications;
