/* eslint-disable max-len */
import UI from '@ui/UI';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {URL_TYPES} from '@util/consts.js';
import Box from './Box';
import Instructor from '../Partials/Instructor';
import {Badge} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

function CourseBoxSingle( props ) {
  const {i18n} = useTranslation();
  const currentSemester = useSelector((state) => state.currentSemester);
  const course = props.course;

  return (<>
    <Box
      type='course'
      href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS,
          {id: course.verid, semester: currentSemester.id})}
    >
      <div className="col text-start">
        <strong>{course['titel_'+i18n.language]}</strong>
        {course['course_didaktik'].map((item, index) =>
          <UI.Icon key={index} icon={item.icon} didaktik></UI.Icon>,
        )}
        <br />
        <Instructor semester={currentSemester.id}
          teachers={course.teachers}
          nolink />
        <h5 className="mt-4">
          <Badge bg="highlight"
            className="me-2">
            {course.verid.toString().padStart(4, '0')}
          </Badge>
          <Badge bg="mainmedium"
            className="me-2">
            {course.lvtyp_1}
          </Badge>
          <Badge bg="maindark"
            className="me-2">
            {course.wochenstunden_neu} SST
          </Badge>
        </h5>
      </div>
    </Box>
  </>
  );
}

CourseBoxSingle.propTypes={
  course: PropTypes.object,
};

export default CourseBoxSingle;
