import {Row, Col} from 'react-bootstrap';
import UI from '@ui/UI';
import {useTranslation} from '@util/bachhelpers';
import Calendar from '@components/Calendar/Calendar';
import MyFavorites from '@components/Partials/MyFavorites';


function Schedule(props) {
  const {_} = useTranslation();
  return (
    <Col className="p-0">

      <Row className="p-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <UI.PageTitle backurl="/member">
            {_('app.memberhome.my_schedule')}
          </UI.PageTitle>
          <Calendar variant="month" />
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3
        d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}
export default Schedule;
