import UI from '@ui/UI';
import {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {BA, useTranslation} from '@util/bachhelpers.js';

function Confirm(props) {
  const {_} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const fkUser = queryParams.get('fk_user');
  const nonce = queryParams.get('nonce');
  const action = queryParams.get('action');

  const callApiOnce = useRef(false);

  useEffect( () => {
    if ( !callApiOnce.current ) {
      callApiOnce.current = true;
      let api;
      switch ( action ) {
        case 'REGISTER':
          api = 'activate';
          break;
        case 'RESET':
          api = '';
          // BA.getTempData( 'tempmail' ) &&
          // BA.getTempData( 'tempmail' ).length>0?
          navigate(`/reset-password-wuniverse?fk_user=${fkUser}&nonce=${nonce}`,
              {replace: false});
          /* :
          navigate('/');*/
          break;
        default:
          api = '';
      }
      if ( api !== '' ) {
        BA.ajax({
          url: `/bachapi/${api}?fk_user=${fkUser}&nonce=${nonce}`,
          bpoapi: false,
        }).then( (response) => {
          switch ( api ) {
            case 'activate':
              if ( response.status === 'error' ) {
                dispatch({
                  type: 'set_redirect',
                  to: '/',
                  title: _('app.signup.activation_error'),
                  info: _('app.signup.activation_error_text'),

                });
              } else {
                dispatch({
                  type: 'set_redirect',
                  to: BA.BACHURL+'/idpapi/login',
                  title: _('app.signup.activated_title'),
                  info: _('app.signup.activated_text', false, response.email),
                });
              }
              navigate('/redirect', {replace: false});
              break;
            default:
          }
        }).catch((e) => {
          dispatch({
            type: 'set_errcode',
            errcode: e,
          });
          setXhrload(false);
        });
      }
      return () => {};
    }
  }, []);


  return (
    <div className="col-lg-6 col-xxl-4 ">
      <UI.AjaxLoading />
    </div>
  );
}

export default Confirm;
