/* eslint-disable camelcase */
import {Form, Button, Spinner} from 'react-bootstrap';
import {useFormFields} from '../../hooks/form-hook';
import {useState} from 'react';
import {BA, useTranslation} from '@util/bachhelpers';
import {URL_TYPES} from '@util/consts.js';
import UI from '@ui/UI';
import PropTypes from 'prop-types';

function FormWLANPassword(props) {
  const {_} = useTranslation();
  const [fields, handleChange] = useFormFields([
    {new_wlan_pw: '', error: true},
    {new_wlan_pw2: '', error: true},
  ]);
  const [toasts, setToasts] = useState([]);
  const [xhrload, setXhrload] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log( 'handleSubmit', xhrload, fields.findIndex((el) => el.error) );
    if (!xhrload && fields.findIndex((el) => el.error) === -1) {
      const dataToSend = BA.sendData(fields);
      setXhrload(true);
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.WLAN_PASSWORD),
        method: e.target.method,
        contentType: 'application/json',
        bpoapi: true,
        method: 'POST',
        data: JSON.stringify(dataToSend),

      }).then((response) => {
        // console.log(response);
        setXhrload(false);
        if (response.error) {
          setToasts([{variant: 'error mb-4',
            message: response.error.message, // .status_detail
            show: true}]);
        } else {
          setToasts([{variant: 'success mb-4',
            message: _('app.profile.wlan_password_changed'),
            show: true}]);
          setTimeout(()=>{
            // console.log( dataToSend );
            props.setUser({...props.user, ...dataToSend});
            props.setInPageForm('');
          }, 1000);
        }
      });
    }
  };

  const handleClose = (i) => {
    const updatedToasts = toasts.map( (toast, ix)=>{
      if ( i === ix ) {
        toast.show = false;
      }
      return toast;
    });
    setToasts( updatedToasts );
  };

  return (
    <>

      <Form method="post"
        action=""
        autoComplete="off"
        onSubmit={handleSubmit}
        className="dataform">

        <UI.ToastMessage toasts={toasts} handleClose={handleClose} />
        {/* prevent firefox from writing password in first password field */}
        <input type="password" className="d-none" />

        <Form.Group className="mb-2">
          <Form.Label htmlFor="new_wlan_pw" className="mb-0">
            <span className="labeltext">{_('general.new_pwd')}</span>
          </Form.Label>
          <UI.FormControl
            type="password"
            validation
            name="new_wlan_pw"
            handler={handleChange}
            className="ps-2"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label htmlFor="new_wlan_pw2" className="mb-0">
            <span className="labeltext">{_('general.repeat_pwd')}</span>
          </Form.Label>
          <UI.FormControl
            type="password"
            validation
            repeat="new_wlan_pw"
            name="new_wlan_pw2"
            handler={handleChange}
            className="ps-2"
          />
        </Form.Group>

        <div className="text-end mt-4">
          <Button type="submit" className="px-4" variant="primary">
            {xhrload&&<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />}
            {_('app.login.change_pwd')}
          </Button>

        </div>
      </Form></>);
}
FormWLANPassword.propTypes={
  setUser: PropTypes.func,
  user: PropTypes.object,
  setInPageForm: PropTypes.oneOfType(
      [
        PropTypes.object,
        PropTypes.func,
      ]),
};
export default FormWLANPassword;
