import UI from '@ui/UI';
import {useTranslation} from '@util/bachhelpers.js';
import parse from 'html-react-parser';

const PrivacyPolicy = () => {
  const {_} = useTranslation();
  const serviceTexts=_('app.privacy_policy', {returnObjects: true});

  return <>
    <div className="p-4 pb-0 col-lg-6 col-xxl-4 ">
      {
        Object.keys(serviceTexts).map((key)=>{
          if (key==='title') {
            return <UI.PageTitle backurl="/" key={'page-'+key}>
              {serviceTexts[key]}
            </UI.PageTitle>;
          } else {
            return (
              <div key={key}>
                <h5 key={key+'-title'} className="pt-3">
                  {serviceTexts[key]['title']}
                </h5>
                {serviceTexts[key]['narritive'] &&
                  <p key={key+'-narritive'}>
                    {parse(serviceTexts[key]['narritive'])}
                  </p>
                }
                {serviceTexts[key]['narritives'] &&
                  <ul key={key+'-narritives-ul'}>
                    {serviceTexts[key]['narritives'].map((text, i)=>(
                      <li key={key+'-narritives-li-'+i}>
                        {text}
                      </li>
                    ))}
                  </ul>
                }

              </div>
            );
          }
        })
      }
    </div>
  </>;
};
export default PrivacyPolicy;
