import {useTranslation} from '@util/bachhelpers.js';
import UI from '@ui/UI';
import {useSelector} from 'react-redux';
import {Button} from 'react-bootstrap';

function NoPermission() {
  const {_} = useTranslation();
  const userInfo = useSelector((state) => state.userInfo);

  return (
    userInfo.tid > 0 ?
    <div className="text-center mt-5">
      <UI.Icon icon="bi-lock" inline></UI.Icon>
      <h3>{_('general.nopermission')}</h3>
      <p>{_('general.nopermission_text')}</p>
      <div className="d-flex justify-content-center">
        <Button
          type="submit"
          id="id_np_goback"
          className="btn btn-secondary"
          variant="primary"
          onClick={() => window.history.back()}
        >
          {_('general.nopermission_goback')}
        </Button>
      </div>
    </div> :
    <></>
  );
}

export default NoPermission;
