/* eslint-disable max-len */
/* eslint-disable camelcase */
import UI from '@ui/UI';
import {
  Button,
  Card,
  Col,
  Row,
} from 'react-bootstrap';

import MyFavorites from '@components/Partials/MyFavorites';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {LANGUAGES, URL_TYPES} from '@util/consts.js';
import {useEffect, useState} from 'react';
import {Search} from 'react-bootstrap-icons';
import {useDispatch, useSelector} from 'react-redux';


function CoursesSearch(props) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const currentSemester = useSelector((state) => state.currentSemester);
  const language = useSelector((state) => state.language);
  const courseSearchResult = useSelector(((state) => state.courseSearchResult));

  const [xhrload, setXhrload] = useState(false);
  const [searchText, setSearchText] = useState(sessionStorage.getItem('courseSearchText') || '');
  const [searchCourse, setSearchCourse] = useState(null);

  const styleSemesters = {
    col: 'text-end',
    xs: {span: 9, offset: 3},
    sm: {span: 5, offset: 7},
  };

  const columns = [
    {
      id: 'verdid',
      selector: (row) => row.verid,
      name: _('app.course_search_dt.class_nr'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '9.5rem',
    },
    {
      id: 'lvtyp_1',
      selector: (row) => row.lvtyp_1,
      name: _('app.course_search_dt.class_type'),
      wrap: true,
      minWidth: '60px',
      maxWidth: '11rem',
    },
    {
      id: 'title',
      selector: (row) => language === LANGUAGES.EN ? row.titel_en : row.titel_de,
      cell: (row) => (
        <UI.BachLink
          href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS,
              {id: row.verid, semester: currentSemester.id})}
          clickHandler={storeCourseSearchText}>
          {language === LANGUAGES.EN ? row.titel_en : row.titel_de}
        </UI.BachLink>
      ),
      name: _('app.course_search_dt.title'),
      sortable: true,
      wrap: true,
    },
    {
      id: 'instructor',
      // sort instructor by the last name of the first instructor
      selector: (row) => row.teachers[0].zuname,
      cell: (row) => (
        <div>
          {row.teachers.map(({tid, vorname, zuname}, i) => (
            <UI.BachLink key={i}
              href={BA.buildUIRouteUrl(URL_TYPES.COURSE_INSTRUCTORS,
                  {id: tid, semester: currentSemester.id})}
              clickHandler={storeCourseSearchText}>
              <div >{vorname} {zuname}</div>
            </UI.BachLink>
          ))}
        </div>
      ),
      name: _('app.course_search_dt.instructor'),
      sortable: true,
      wrap: true,
    },
  ];

  const storeCourseSearchText = () => {
    sessionStorage.setItem('courseSearchText', searchText);
  };

  const loadCourses = (param) => {
    // console.log('loadCourses');
    setXhrload(true);
    BA.ajax({
      url: BA.buildApiUrl(URL_TYPES.COURSE_SEARCH,
          {text: param, semester: currentSemester.id}),
      bpoapi: true,
    }).then(async (response) => {
      if (response.data && response.data.courses) {
        let courses = [];
        courses = response.data.courses.map(
            ({verid,
              lvtyp_1,
              titel_en,
              titel_de,
              teachers,
              wochenstunden_neu}) =>
              (verid = verid.toString().padStart(4, '0'),
              {
                verid,
                lvtyp_1,
                titel_en,
                titel_de,
                teachers,
                wochenstunden_neu,
              }));
        dispatch({
          type: 'set_course_search_result',
          courseSearchResult: {
            searchText: searchText,
            semester: currentSemester.id,
            courses: courses,
          },
        });
      } else {
        dispatch({
          type: 'set_course_search_result',
          courseSearchResult: {
            searchText: '',
            semester: 0,
            courses: [],
          },
        });
      }
      setSearchCourse(false);
      setXhrload(false);
    }).catch((e) => {
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  useEffect(() => {
    if (searchText && searchText.trim() !== '' && !xhrload &&
      // change semester will VOID the cached result
      courseSearchResult.semester === currentSemester.id) {
      sessionStorage.removeItem('courseSearchText');
    } else {
      dispatch({
        type: 'set_course_search_result',
        courseSearchResult: {
          searchText: '',
          semester: 0,
          courses: [],
        },
      });
    }
  }, []);

  useEffect(() => {
    if (searchCourse && searchText.trim() !== '' && !xhrload) {
      loadCourses(searchText);
    }
  }, [searchCourse, currentSemester.id, searchText, xhrload]);

  useEffect(() => {
    if (courseSearchResult.semester &&
      courseSearchResult.semester !== currentSemester.id) {
      setSearchText('');
      dispatch({
        type: 'set_course_search_result',
        courseSearchResult: {
          searchText: '',
          semester: 0,
          courses: [],
        },
      });
    }
  }, [currentSemester.id]);

  const handleKeyDown = (event) => {
    if (searchText.length > 2 &&
      searchText.trim() !== '' &&
      !searchCourse &&
      event.key === 'Enter') {
      if (!isNaN(searchText) && searchText.length === 3) {
        setSearchText('0' + searchText);
      }
      setSearchCourse(true);
      event.target.value = '';
    }
  };

  const handleSubmitClick = (event) => {
    event.preventDefault();
    switch (event.target.id) {
      case 'submit-input':
      case 'search-course-magnifier':
        if (searchText.length > 2 &&
          searchText.trim() !== '' &&
          !searchCourse) {
          if (!isNaN(searchText) && searchText.length === 3) {
            setSearchText('0' + searchText);
          }
          setSearchCourse(true);
        }
        break;
      case 'reset-input':
        setSearchCourse(null);
        setSearchText('');
        dispatch({
          type: 'set_course_search_result',
          courseSearchResult: {
            searchText: '',
            semester: 0,
            courses: [],
          },
        });
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <Col className="p-0">
      <Row className="px-4 pt-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <UI.PageTitle backurl="goback">{_('app.course_search.title')}</UI.PageTitle>
          <div className="d-lg-none"><UI.SemesterList style={styleSemesters} /></div>
          <div className='pt-4 pb-4'>
            <UI.InfoBox>
              <UI.InfoText>
                {_('app.course_search.search_tips')} {_('app.course_search.search_tips_description')}
              </UI.InfoText>
            </UI.InfoBox>
          </div>

          <Card className="card">
            <Card.Header className="card_header p-2">
              {_('app.course_search.search_for_courses')}
            </Card.Header>
            <Card.Body className="card_body py-3">
              <p className="m-0 mb-1">{_('app.course_search.course_information')}</p>
              <input
                type="text"
                name="search"
                placeholder={_('general.search')}
                id="search_course"
                className="form-control shadow-sm"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={searchText}
                disabled={xhrload || currentSemester.title === ''}
              />
              <Search
                id="search-course-magnifier"
                className="magnifier"
                onClick={handleSubmitClick}
                style={{cursor: 'pointer'}}
              />
            </Card.Body>
          </Card>
          <Row className="g-2 my-2 d-flex justify-content-center">
            <Col className="d-grid">
              <Button
                type="submit"
                id="submit-input"
                variant="primary"
                onClick={handleSubmitClick}
              >
                {_('app.course_search.search_button')}
              </Button>
            </Col>
            <Col xs={5} sm={4} className="d-grid">
              <Button
                type="submit"
                id="reset-input"
                variant="secondary"
                onClick={handleSubmitClick}
              >
                {_('app.course_search.reset_button')}
              </Button>
            </Col>
          </Row>

          {xhrload && <UI.AjaxLoading />}
          {((!xhrload && searchCourse !== null) || courseSearchResult.courses.length > 0) &&
            <UI.BachDataTable
              noDataComponent={_('app.course_search.no_course_found')}
              hideCount={false}
              hideFilter={false}
              columns={columns}
              data={courseSearchResult.courses}
              filterCols={[(language === LANGUAGES.EN ? 'titel_en' : 'titel_de'), 'teachers']}
            />
          }
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}
export default CoursesSearch;
