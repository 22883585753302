import {Offcanvas, CloseButton} from 'react-bootstrap';
import './MainMenu.css';
import MainMenuGroup from './MainMenuGroup';
/* import MemberMenuGroup from './MemberMenuGroup'; */
import {useEffect, useState} from 'react';
import {useTranslation} from '@util/bachhelpers';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import linkDataPublic from '@model/mainnav_links_public.json';
import linkDataMember from '@model/mainnav_links_member_cat.json';

function MainMenu(props) {
  const {_} = useTranslation();
  const {showMenu, handleClose} = props;
  const [linkData, setLinkData] = useState( linkDataPublic );

  const userInfo = useSelector((state) => state.userInfo);

  useEffect( ()=> {
    if ( props.auth ) {
      if ( userInfo.employee === true ) { // employee
        setLinkData(linkDataMember.employee);
      } else if ( userInfo.wuniverse === true ) { // wuniverse
        setLinkData(linkDataMember.wuniverse);
      } else if (userInfo.student === true) { // student
        setLinkData(linkDataMember.student);
      }
    } else {
      setLinkData(linkDataPublic);
    }
  }, [props.auth, userInfo]);

  return (
    <>
      <Offcanvas
        show={showMenu}
        onHide={handleClose}
        id="mainmenu"
        className="offcanvas-end"
        placement="end"
      >
        <Offcanvas.Header>
          <h5>{/* BACH Logo */}</h5>
          <CloseButton
            className="btn-close-white"
            onClick={handleClose}>
          </CloseButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* props.auth&&<MemberMenuGroup handleClose={handleClose}/> */}
          {linkData.map( (linkGroup, i) => (
            <MainMenuGroup
              handleClose={handleClose}
              title={_(linkGroup.title)}
              links={linkGroup.links}
              key={`group${i}`}
            />
          ))}

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

MainMenu.propTypes = {
  showMenu: PropTypes.bool,
  handleClose: PropTypes.func,
  auth: PropTypes.bool,
};

export default MainMenu;
