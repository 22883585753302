import UI from '@ui/UI';

function WULogo( props ) {
  return (
    <UI.BachLink href="https://www.wu.ac.at/" className="logo_wu">
      <UI.ImageElement src="/icons/wu_logovienna.svg" alt="WU Vienna" />
    </UI.BachLink>
  );
}

export default WULogo;
