import {useTranslation, BA} from '@util/bachhelpers.js';
import UI from '@ui/UI';
import {useEffect, useState} from 'react';
import NotificationBoxes from '../Boxes/NotificationBoxes';
import FormNotification from '../Forms/FormNotification';
import {useDispatch} from 'react-redux';
// import PropTypes from 'prop-types';
import {URL_TYPES} from '@util/consts.js';
// import {Row, Col} from 'react-bootstrap';

function AllNotifications( props ) {
  const {i18n, _} = useTranslation();
  const dispatch = useDispatch();
  const [xhrload, setXhrload] = useState(false);
  const [show, setShow] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [toDelete, setToDelete] = useState({}); // before confirm

  const [deleteId, setDeleteId] = useState(false); // after server response
  const [editId, setEditId] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [loaddata, setLoaddata] = useState(0);

  /**
   * use deleteId to make sure, element will be removed from state
   */
  useEffect(()=>{
    if ( deleteId ) {
      let n = [...allNotifications];
      n = n.filter( (el)=>{
        return el.id !== deleteId;
      });
      setAllNotifications(n);
    }
  }, [deleteId]);

  useEffect(() => {
    // Fetch notifications
    setXhrload(true);
    BA.ajax({
      method: 'get',
      data: {},
      bpoapi: true,
      url: BA.buildApiUrl(URL_TYPES.ADMIN_NOTIFICATIONS),
    })
        .then(async (response) => {
          setXhrload(false);
          // eslint-disable-next-line max-len
          const notifications = response.data.notification_list.map((notification) => ({
            id: notification.tid,
            type: notification.fk_notification_type,
            group: notification.fk_notification_group,
            titel_de: notification.titel_de,
            titel_en: notification.titel_en,
            text_de: notification.text_de,
            text_en: notification.text_en,
            valid_from: notification.valid_from,
            valid_to: notification.valid_to,
            created_at: notification.created_at,
            created_by: notification.created_by,
            changed_at: notification.changed_at,
            changed_by: notification.changed_by,
          }));
          setAllNotifications(notifications);
        })
        .catch((e) => {
          dispatch({
            type: 'set_errcode',
            errcode: e,
          });
          setXhrload(false);
        });
  }, [loaddata]);

  const confirmDeleteNotification = (id) => {
    setShow( true );
    setToDelete( allNotifications.find( (el)=>el.id === id ));
  };

  const deleteNotification = () => {
    if (!toDelete.id) return;

    const id = toDelete.id;

    // Mark the notification as deleted in UI before API call
    const updatedNotifications = allNotifications.map((el) => {
      if (el.id === id) {
        return {...el, delete: true};
      }
      return el;
    });
    setAllNotifications(updatedNotifications);

    setShow(false);

    BA.ajax({
      method: 'delete',
      data: {},
      bpoapi: true,
      url: BA.buildApiUrl(URL_TYPES.ADMIN_NOTIFICATIONS)+'/'+id,
    })
        .then(async (response) => {
          if (response) {
            setDeleteId(id);
          }
        })
        .catch((e) => {
        // Revert changes in case of API failure
          const revertedNotifications = updatedNotifications.map((el) => {
            if (el.id === id) {
              return {...el, delete: false};
            }
            return el;
          });
          setAllNotifications(revertedNotifications);

          dispatch({
            type: 'set_errcode',
            errcode: e,
          });
        });
  };


  const editNotification = (id)=>{
    setTimeout( ()=>{
      document.querySelector('#backtotop').click();
    }, 50 );
    setEditId( id );
    setShowForm( true );
  };

  const filterNotification = (el, filter)=>{
    const d = new Date();
    const today = new Date( d.getFullYear(), d.getMonth(), d.getDate() );
    const v = el.valid_from.split('-');
    const validFrom = new Date( v[0]*1, v[1]*1-1, v[2]*1 );
    const v2 = el.valid_to.split('-');
    const validTo = new Date( v2[0]*1, v2[1]*1-1, v2[2]*1 );
    switch (filter) {
      case 'future': return validFrom>today;
      case 'current': return validTo>=today && validFrom<=today;
      case 'past': return validTo<today;
    }
  };

  const filterNotificationCurrent = (el)=>{
    return filterNotification(el, 'current');
  };

  const filterNotificationFuture = (el)=>{
    return filterNotification(el, 'future');
  };

  const filterNotificationPast = (el)=>{
    return filterNotification(el, 'past');
  };

  const showAddForm = (b=true)=>{
    setEditId( false );
    setShowForm( b );
  };

  return (
    <>
      <UI.ConfirmModal
        show={show}
        handler={deleteNotification}
        handleClose={()=>{
          setShow(false);
          setToDelete({});
        }}
        message={<p>{_('app.notificationcenter.confirm_delete')}<br/>
          <b>{toDelete['titel_'+i18n.language]}</b></p>}
        buttontext={_('app.notificationcenter.button_delete')}
      />
      {xhrload&&<UI.AjaxLoading />}
      {!xhrload&&<>
        {showForm&&<>
          <div className="d-flex">
            <a className="sub-back-btn"
              onClick={() => showAddForm(false)}></a>
            <h4 className="mt-4">{editId?_('app.notificationcenter.edit'):
          _('app.notificationcenter.addnew')}</h4>
          </div>
          <FormNotification
            edit={allNotifications.filter((el)=>el.id===editId)}
            cancel={()=>{
              showAddForm(false);
            }}
            refresh={()=>{
              setLoaddata( loaddata+1 );
            }}
          />
        </>}
        {!showForm&&<>
          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-primary"
              onClick={showAddForm}>
              {_('app.notificationcenter.addnew')}
            </button>
          </div>
        </>}
        {!editId&&!showForm&&<>
          <h5 className="mt-4">{
            _('app.notificationcenter.future')}</h5>

          {allNotifications.filter(filterNotificationFuture).length>0 ? <>
            <NotificationBoxes
              notifications={allNotifications.filter(filterNotificationFuture)}
              deleteNotification={confirmDeleteNotification}
              editNotification={editNotification}
              admin
            />
          </> : <>
            <p className="pb-4">
              <em>{_('app.notificationcenter.no_future')}</em></p>
          </>}

          <h5 className="mt-4">{
            _('app.notificationcenter.current')}</h5>

          {allNotifications.filter(filterNotificationCurrent).length>0 ? <>
            <NotificationBoxes
              notifications={allNotifications.filter(filterNotificationCurrent)}
              deleteNotification={confirmDeleteNotification}
              editNotification={editNotification}
              admin

            />
          </> : <>
            <p className="pb-4">
              <em>{_('app.notificationcenter.no_current')}</em></p>
          </>}


          <h5 className="mt-4">{
            _('app.notificationcenter.old')}</h5>

          {allNotifications.filter(filterNotificationPast).length>0?<>
            <NotificationBoxes
              notifications={allNotifications.filter(filterNotificationPast)}
              deleteNotification={confirmDeleteNotification}
              editNotification={editNotification}
              admin
            />
          </>:<>
            <p className="pb-4">
              <em>{_('app.notificationcenter.no_old')}</em></p>
          </>}
        </>}
      </>}
    </>
  );
}
AllNotifications.propTypes={
};
export default AllNotifications;
