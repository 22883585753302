/* eslint-disable max-len */
import {Row, Col} from 'react-bootstrap';
import MyFavorites from '@components/Partials/MyFavorites';
import ExternalPage from './ExternalPage';

function ExternDemo(props) {
  return (
    <Col className="p-0">
      <Row className="p-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <ExternalPage src="https://bach.wu.ac.at/mywu-dev/extern-demo/vortragendenportal.submenu.LOCAL.html" cropped />
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 ">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}
export default ExternDemo;
