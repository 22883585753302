import image500 from '../../assets/500.png';
import image404 from '../../assets/404.png';
import './Error.css';
import {useParams} from 'react-router-dom';

function Error(props) {
  const imgs = {
    404: image404,
    500: image500,
  };
  const redirectParams = useParams();
  let status = redirectParams.status;

  if ( !imgs[status] ) {
    status = 500;
  }

  return (
    <div className="container text-center errorpage">
      <img src={process.env.REACT_APP_BASE_URL+imgs[status]} />
    </div>
  );
}

export default Error;
