import './WUniverseInfo.css';
import {useTranslation} from '@util/bachhelpers.js';

function WUniverseInfo( props ) {
  const {_} = useTranslation();
  return (
    <>
      <p className="py-2 px-4 text-center wuniverse_info_box">
        {_('general.wuniverse_info')}</p>
    </>
  );
}
export default WUniverseInfo;
