import ErrorModal from '@components/Error/ErrorModal';
import BachLink from '@ui/BachLink';
import {useTranslation} from '@util/bachhelpers.js';
import {useState} from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import SupportModal from './SupportModal';
import ThemeModal from './ThemeModal';


function Footer(props) {
  const {_} = useTranslation();
  const auth = useSelector((state) => state.auth);
  const errcode = useSelector((state) => state.errcode);
  const [showSupport, setShowSupport] = useState(false);
  const [showTheme, setShowTheme] = useState(false);

  const handleClose = () => setShowSupport(false);
  const handleCloseTheme = () => setShowTheme(false);

  const handleShow = (e) => {
    e.preventDefault();
    setShowSupport(true);
  };

  const handleShowTheme = (e) => {
    e.preventDefault();
    setShowTheme(true);
  };


  return (
    <>
      <SupportModal showSupport={showSupport} handleClose={handleClose} />
      <ThemeModal
        public={!auth}
        showSupport={showTheme}
        handleClose={handleCloseTheme} />
      {errcode!=0&&<ErrorModal errorCode={errcode} goback={false} />}
      <footer id="mainfoot">
        <Container fluid>
          <Navbar className="justify-content-between" variant="">
            <div id="navbarText">
              <Nav className=" mr-auto">
                <Nav.Item className="">
                  <BachLink className="nav-link" href="/about">
                    {_('app.nav.about')}
                  </BachLink>
                </Nav.Item>
                <Nav.Item>
                  <BachLink className="nav-link" href="https://www.wu.ac.at/en/the-university/about-wu/impressum">{_('app.nav.imprint')}</BachLink>
                </Nav.Item>
                <Nav.Item >
                  <BachLink className="nav-link" href="/privacy-policy">
                    {_('app.nav.privacy_policy')}
                  </BachLink>
                </Nav.Item>
              </Nav>
            </div>
            <div>
              <a href="?"
                className="nav-link choosetheme"
                onClick={handleShowTheme}>
                <b className="lnk_bulblight">&nbsp;</b>
                <span>{_('app.nav.theme')}</span>
              </a>
              <a href="?"
                className="nav-link support"
                onClick={handleShow}>
                <b>?</b>
                <span>{_('app.nav.support')}</span>
              </a>
            </div>
          </Navbar>
        </Container>
      </footer>
    </>
  );
}
export default Footer;
