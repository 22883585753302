/* eslint-disable max-len */
/* eslint-disable new-cap */
import {Table, Col, Row} from 'react-bootstrap';
import UI from '@ui/UI';
import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {BA, useTranslation} from '@util/bachhelpers.js';
import Box from '@components/Boxes/Box';
import Instructor from '@components/Partials/Instructor';
import Subject from '@components/Partials/Subject';
import {URL_TYPES} from '@util/consts';
import MyFavorites from '@components/Partials/MyFavorites';

function CourseDetail(props) {
  const {_, i18n} = useTranslation();

  let weekdays = [];
  weekdays = [
    _('schedule.mon'),
    _('schedule.tue'),
    _('schedule.wed'),
    _('schedule.thu'),
    _('schedule.fri'),
    _('schedule.sat'),
    _('schedule.sun'),
    _('schedule.today'),
  ];

  const redirectParams = useParams();
  const verid = redirectParams.verid;
  const semester = redirectParams.semester;
  const semesterBadge = (semester * 1 % 2 === 1 ? 'SoSe' : 'WiSe') + ' ' + semester.substring(0, 4);
  const semesterShort = semester.substring(2, 4) + (semester * 1 % 2 === 1 ? 'S' : 'W');
  const [courseDetail, setCourseDetail] = useState({});

  const [xhrload, setXhrload] = useState(false);
  const loadCourseDetail = () => {
    if (!xhrload) {
      setXhrload(true);
      BA.ajax({
        bpoapi: true,
        url: BA.buildApiUrl(URL_TYPES.COURSE_DETAILS,
            {id: verid, semester: semester}),
      }).then(async (response) => {
        console.log(response);
        setXhrload(false);
        setCourseDetail(response.data.courses);
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  useEffect(() => {
    loadCourseDetail();
  }, []);


  return (
    <Col className="p-0">

      <Row className="px-4 pt-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <UI.PageTitle backurl="goback">
            {_('app.course_detail.title')}
          </UI.PageTitle>
          {xhrload && <UI.AjaxLoading />}
          {!xhrload && courseDetail.course_info && <>
            <Box
              type="course my-4"
              title={courseDetail.course_info['titel_' + i18n.language]}
              didaktik={courseDetail.course_didaktik}
              badge={semesterBadge}
              footer={<>
                <a href={'https://bach.wu.ac.at/d/mvvz/' + courseDetail.course_info.tid + '/' + BA.randint(800000000, 899999999) + '/ics/'}>{_('app.course_detail.schedule_download')}</a> | <a href={'webcal://bach.wu.ac.at/d/mvvz/' + courseDetail.course_info.tid + '/' + BA.randint(800000000, 899999999) + '/ics/#'}>{_('app.course_detail.subscribe')}</a>
              </>
              }
            >
              <Table borderless className="mb-0">
                <tbody>
                  <tr>
                    <th>{_('app.course_detail.class_nr')}:</th>
                    <td>{courseDetail.course_info.verid}</td>
                  </tr>
                  <tr>
                    <th>{_('app.course_detail.instructors')}:</th>
                    <td>
                      <Instructor semester={semester} teachers={courseDetail.teachers} />
                    </td>
                  </tr>
                  <tr>
                    <th>{_('app.course_detail.class_type')}:</th>
                    <td>{courseDetail.course_info.lvtyp_1}</td>
                  </tr>
                  <tr>
                    <th>{_('app.course_detail.subjects')}:</th>
                    <td>
                      <Subject semester={semester} subjects={courseDetail.course_pp} />
                    </td>
                  </tr>
                  <tr>
                    <th>{_('app.course_detail.credit_hours')}:</th>
                    <td>{courseDetail.course_info.wochenstunden_neu}</td>
                  </tr>
                  <tr>
                    <th>{_('app.course_detail.language')}:</th>
                    <td>{courseDetail.course_info['sprache_' + i18n.language]}</td>
                  </tr>
                </tbody>
              </Table>
            </Box>
            <Box
              type="course mb-4"
              title={_('app.course_detail.further_info')}
            >
              <UI.BachLink className='text-link' href={`https://learn.wu.ac.at/vvz/${semesterShort.toLowerCase()}/${verid.toString().padStart(4, '0')}`}>
                {`https://learn.wu.ac.at/vvz/${semesterShort.toLowerCase()}/${verid.toString().padStart(4, '0')}`}
              </UI.BachLink>
            </Box>
            <Box
              type="course mb-4"
              title={_('app.course_detail.event_info')}
              noContentPadding
            >
              <Table bordered className="noborder mb-0">
                <tbody>
                  {courseDetail.events.map((el, i) => (
                    <tr key={i}>
                      <td className="p-1">{BA.printCurrentDayString(el.start_time, weekdays)}</td>
                      <td className="p-1">
                        {BA.getTimeString(el.start_time)}-{BA.getTimeString(el.end_time)}
                        &nbsp;{el.short_descr}
                      </td>
                      <td className="p-1">
                        {el.room_de.includes('Online') ? el['room_' + i18n.language] :
                          <UI.BachLink href={'http://campus.wu.ac.at/?q='+el['room_' + i18n.language]}>
                            {el['room_' + i18n.language]}
                          </UI.BachLink>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
            {courseDetail.detail_info.length > 0 &&
              <Box
                type="course mb-4"
                title={_('app.course_detail.detail_info')} >
                <h5>{_('app.course_detail.contact_details')}:</h5>
                <p>
                  {courseDetail.detail_info[0].kontakt}
                </p>
                <h5 className="pt-4">{_('app.course_detail.content')}:</h5>
                <div>
                  {BA.htmlSanitized(courseDetail.detail_info[0]['inhalte_gesamt'] || '-')}
                </div>
                <h5 className="pt-4">{_('app.course_detail.learning_outcomes')}:</h5>
                <div>
                  {BA.htmlSanitized(courseDetail.detail_info[0]['ziele_' + i18n.language] || '-')}
                </div>
                <h5 className="pt-4">{_('app.course_detail.teaching_methods')}:</h5>
                <div>
                  {BA.htmlSanitized(courseDetail.detail_info[0]['methoden'] || '-')}
                </div>
                <h5 className="pt-4">{_('app.course_detail.assessement')}:</h5>
                <div>
                  {BA.htmlSanitized(courseDetail.detail_info[0]['bewertung'] || '-')}
                </div>
                <h5 className="pt-4">{_('app.course_detail.prerequisites')}:</h5>
                <div>
                  {BA.htmlSanitized(courseDetail.detail_info[0]['aufnahmekriterien'] ||
                    '-')}
                </div>
              </Box>
            }
          </>}
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}
export default CourseDetail;
