import i18next from 'i18next';
import {LANGUAGES, DEFAULT_LANG} from '@util/consts';

/**
 * load translation json files for init
 */
import commonDe from '../../lang/de/common.json';
import commonEn from '../../lang/en/common.json';

i18next.init({
  interpolation: {escapeValue: false}, // React already does escaping
  lng: LANGUAGES[DEFAULT_LANG],
  resources: {
    en: {
      common: commonEn,
    },
    de: {
      common: commonDe,
    },
  },
  parseMissingKeyHandler: (key) => {
    console.warn('missing translation', i18next.language, key);
    return `${key}`;
  },
});

export default i18next;
