/* eslint-disable max-len */
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {useTranslation} from '@util/bachhelpers.js';
import PropTypes from 'prop-types';

import Home from '@public/pages/Home';

import Login from '@public/pages/Login';
import Register from '@public/pages/Register';
import ForgotPassword from '@public/pages/ForgotPassword';
import ResendActivation from '@public/pages/ResendActivation';
import Confirm from '@public/pages/Confirm';
import RedirectPage from '@public/pages/RedirectPage';
import RedirectExtern from '@public/pages/RedirectExtern';
import PrivacyPolicy from './public/pages/PrivacyPolicy';

import ResetPassword from '@public/pages/ResetPassword';
import ResetPasswordWUniverse from '@public/pages/ResetPasswordWUniverse';
import About from '@public/pages/About';
import AboutTeaching from '@public/pages/AboutTeaching';
import AboutPersonal from '@public/pages/AboutPersonal';
import LogoutApp from '@public/pages/LogoutApp';
import LogoutInactive from '@public/pages/LogoutInactive';
import LogoutIDP from '@public/pages/LogoutIDP';
import LogoutExpire from '@public/pages/LogoutExpire';

import CourseSearch from '@member/pages/CoursesSearch';
import CourseDetail from '@member/pages/CourseDetail';
import InstructorCourses from '@member/pages/InstructorCourses';
import SubjectCourses from '@member/pages/SubjectCourses';

import Error from '@public/pages/Error';

// import DemoIconPage from '@public/demopages/DemoIconPage';

function AppPublic(props) {
  const {i18n} = useTranslation();
  const {auth} = props;
  let routes = [];
  if (auth) {
    /* routes access only with auth */
    routes = [
      {path: '/', element: <Navigate to="/member" />},
      {path: '/login', element: <Login />},
    ];
  } else {
    /* routes access only without auth */
    routes = [
      {path: '/', element: <Home />},
      {path: '/login', element: <Login />},
      {path: '/register', element: <Register />},
      {path: '/forgot-password', element: <ForgotPassword />},
      {path: '/reset-password', element: <ResetPassword />},
      {path: '/reset-password-wuniverse', element: <ResetPasswordWUniverse />},
      {path: '/resend-activation', element: <ResendActivation />},
      {path: '/lang/:lang', element: <Home />},
    ];
  }

  const location = useLocation();
  useEffect(() => {
    // setTimout 1ms needed, in some cases page didnot scroll up after change
    setTimeout( ()=>{
      window.scrollTo({top: 0, behavior: 'auto'});
    }, 1 );
  }, [location]);


  return (

    <Routes>
      { routes.map( (route, ix) => <Route key={ix} path={route.path} element={route.element} /> )}
      <Route path="/logoutidp" element={<LogoutIDP />} />
      <Route path="/logoutapp" element={<LogoutApp />} />
      <Route path="/logoutexpire" element={<LogoutExpire />} />
      <Route path="/logoutinactive" element={<LogoutInactive />} />
      <Route path="/about" element={<About />} />
      <Route path="/about/teaching" element={<AboutTeaching />} />
      <Route path="/about/personnel" element={<AboutPersonal />} />
      <Route path="/about/research" element={<About />} />
      <Route path="/course/:verid/semester/:semester" element={<CourseDetail />} />
      <Route path="/instructor/:persid/semester/:semester" element={<InstructorCourses />} />
      <Route path="/planpunkt/:tid/semester/:semester" element={<SubjectCourses />} />
      <Route path="/redirect" element={<RedirectPage to="/login" />} />
      <Route path="/redirecterror/:errorid" element={<RedirectPage to="/" />} />
      <Route path="/courses" element={( <CourseSearch />)} />
      <Route path="/rooms" element={(<RedirectExtern href={`https://bach.wu.ac.at/d/i18n/set/${i18n.language}/?next=/d/rr/`} />)} />
      {/* <Route path="/learn" element={<Navigate to="/" />} />
            <Route path="/office365" element={<Navigate to="/" />} />*/}
      { /* <Route path="/icondemo" element={(<DemoIconPage />)} />*/ }
      {/* Exceptional breaking of naming convention, delivered as the fixed path of an external API*/}
      <Route path="/public/register_html" element={<Confirm />} />
      <Route path="/confirm/:type/:id" element={<Confirm />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/Error/:status" element={<Error />} />
      <Route path="*" element={<Navigate to="/Error/404" />} />
    </Routes>
  );
}

AppPublic.propTypes={
  auth: PropTypes.bool,
};

export default AppPublic;
