import {Container, Button, Form, Row, Col, Spinner} from 'react-bootstrap';
import UI from '@ui/UI';
import {useFormFields} from '@hooks/form-hook.js';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {URL_TYPES} from '@util/consts';

function ResetPassword(props) {
  const {_} = useTranslation();
  // const queryParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  // const fkUser = queryParams.get('fk_user');
  // const nonce = queryParams.get('nonce');
  const tempmail = BA.getTempData( 'tempmail' );
  const [toasts, setToasts] = useState([]);
  const [fields, handleChange] = useFormFields([
    {username: tempmail, error: false},
    {pin: '', error: true},
    {new_pw: '', error: true},
    {new_pw_2: '', error: true},
    //  {fk_user: fkUser, error: false},
    //  {nonce: nonce, error: false},

  ]);

  const [xhrload, setXhrload] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ( !xhrload && fields.findIndex( (el) => el.error ) === -1 ) {
      const dataToSend = BA.sendData(fields);
      setXhrload(true);
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.VERIFY_PW_RESET),
        method: e.target.method,
        contentType: 'application/json',
        data: JSON.stringify(dataToSend),
        bpoapi: true,
      }).then( (response) => {
        console.log( response );
        setXhrload(false);
        if ( response.error ) {
          setToasts( [{
            variant: 'error',
            code: response.error.code,
            message: response.error.message, // ??
            show: true}]);
        } else {
          dispatch({
            type: 'set_redirect',
            to: BA.BACHURL+'/idpapi/login',
            title: _('general.thankyou'),
            info: _('app.login.pwd_reset_success',
                false,
                dataToSend.username),
          });
          BA.clearTempData( 'tempmail' );

          navigate('/redirect', {replace: false});
        }
      });
    }
  };

  const handleClose = (i) => {
    const updatedToasts = toasts.map( (toast, ix)=>{
      if ( i === ix ) {
        toast.show = false;
      }
      return toast;
    });
    setToasts( updatedToasts );
  };

  const handlerClick = (e) => {
    e.preventDefault();
    if ( !xhrload ) {
      setXhrload(true);
      const dataToSend = BA.sendData(fields);
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.RESET_PW_SEND_CODE),
        contentType: 'application/json',
        bpoapi: true,
        method: 'POST',
        data: JSON.stringify(dataToSend),
      }).then( (response) => {
        // console.log( response );
        setXhrload(false);
        if ( response.error ) {
          setToasts( [{
            variant: 'error',
            code: response.error.code,
            message: response.error.message,
            show: true}]);
        } else if (response.sentinfo_status === 'error' ) {
          setToasts( [{
            variant: 'error',
            code: response.error.code,
            message: response.sentinfo_status_detail,
            show: true}]);
        } else {
          setToasts( [{variant: 'success',
            message: _('app.login.new_reset_pin'),
            show: true}]);
        }
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  return (
    <div className="col-lg-5 col-xxl-3 p-0">
      <UI.InfoBox
        title={_('app.login.reset_title')}
      >
        <UI.InfoText>{_('app.login.reset_info')}</UI.InfoText>
      </UI.InfoBox>

      <Form method="post"
        action="/bachapi/pwdreset"
        autoComplete="off"
        onSubmit={handleSubmit}>

        <UI.ToastMessage toasts={toasts} handleClose={handleClose} />

        <Container fluid className="p-4">
          <Form.Group className="mb-4">
            <Form.Label htmlFor="username">
              <UI.Icon icon="bi-user"/>
              <span className="labeltext">
                {_('app.login.prefilled_username')}
              </span>
            </Form.Label>
            <UI.FormControl
              type="text"
              validation
              placeholder={_('app.login.prefilled_username')}
              name="username"
              value={tempmail}
              handler={handleChange}
            />

          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label htmlFor="pin">
              <UI.Icon icon="bi-password"/>
              <span className="labeltext">
                {_('general.pin')}
              </span>
            </Form.Label>
            <UI.FormControl
              type="text"
              validation
              placeholder={_('general.pin')}
              name="pin"
              handler={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label htmlFor="new_pw">
              <UI.Icon icon="bi-password"/>
              <span className="labeltext">
                {_('general.new_pwd')}
              </span>
            </Form.Label>
            <UI.FormControl
              type="password"
              validation
              placeholder={_('general.new_pwd')}
              name="new_pw"
              handler={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label htmlFor="new_pw_2">
              <UI.Icon icon="bi-password"/>
              <span className="labeltext">
                {_('general.repeat_pwd')}
              </span>
            </Form.Label>
            <UI.FormControl
              type="password"
              placeholder={_('general.repeat_pwd')}
              validation
              name="new_pw_2"
              repeat="new_pw"
              handler={handleChange}
            />
          </Form.Group>
          <Row className="g-2">

            <Col className="d-grid">
              <Button type="submit" id="btn_reset_pwd" variant="primary">
                {xhrload&&<Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                />}
                {_('app.login.save_new_pwd')}
              </Button>
            </Col>
            <Col xs={6} sm={5} className="d-grid">
              <UI.BachLink href="/forgot-password"
                className="btn btn-secondary">
                {_('general.cancel')}
              </UI.BachLink>
            </Col>
          </Row>
        </Container>
      </Form>
      <Container fluid className="p-4">
        <p>
          {_('app.login.not_received_pw_reset')}<br />
          <a href="/"
            id="btn_resend_resetpin"
            className="text-link"
            onClick={handlerClick}>
            {_('app.login.resend_pw')}
          </a>
        </p>
      </Container>
    </div>

  );
}

export default ResetPassword;
