
import UI from '@ui/UI';
import {BA} from '@util/bachhelpers.js';
import {URL_TYPES} from '@util/consts.js';
import PropTypes from 'prop-types';

function Instructor( props ) {
  return (
    <>
      {props.teachers.map( (teacher, i) => {
        // console.log( teacher );
        const nameParts = [
          teacher.kurztitel,
          teacher.vorname,
          teacher.zuname,
          teacher.akad_grad_post,
        ];
        if ( props.nolink ) {
          return (
            <span className='text-link'
              href={BA.buildUIRouteUrl(URL_TYPES.COURSE_INSTRUCTORS,
                  {id: teacher.tid, semester: props.semester})}
              key={i}>
              {nameParts.join(' ')}<br />
            </span>);
        } else {
          return (
            <UI.BachLink className='text-link'
              href={BA.buildUIRouteUrl(URL_TYPES.COURSE_INSTRUCTORS,
                  {id: teacher.tid, semester: props.semester})}
              key={i}>
              {nameParts.join(' ')}<br />
            </UI.BachLink>);
        }
      })}
    </>
  );
}
Instructor.propTypes={
  teachers: PropTypes.array,
  nolink: PropTypes.bool,
  semester: PropTypes.oneOfType(
      [
        PropTypes.number,
        PropTypes.string,
      ],
  ),
};
export default Instructor;
