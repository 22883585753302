/* eslint-disable max-len */
import {BA, useTranslation} from '@util/bachhelpers.js';
import './ScheduleBoxes.css';
import Box from './Box';
import {LANGUAGES, URL_TYPES} from '@util/consts.js';
import PropTypes from 'prop-types';
import UI from '@ui/UI';
import {useSelector} from 'react-redux';

function ScheduleBoxes( props ) {
  const {_, i18n} = useTranslation();
  const language = useSelector((state) => state.language);

  const currentDay = (new Date(props.currentDay)).toDateString();
  const coursesDB = props.coursesDB || [];

  const {eventsOnThisDay, hasCollision} = BA.getLVPlanerCoursesForToday(currentDay, coursesDB);

  // remove hidden dates
  const filteredDates = props.dates.filter( (el)=>{
    // const currentDay = (new Date(props.currentDay)).toDateString();

    // fix for iOS, not working with format 'YYYY-MM-DD HH:ii'
    // need 'YYYY-MM-DDTHH:ii'
    const startdate = el.start.replace(' ', 'T');
    const enddate = el.end.replace(' ', 'T');

    const eventStartDay = new Date(startdate).toDateString();
    const eventEndDay = new Date(enddate).toDateString();

    return (new Date(eventStartDay)).getTime()<=
     (new Date(currentDay)).getTime() &&
     (new Date(eventEndDay)).getTime()>=(new Date(currentDay)).getTime();
  });


  return (
    <div className="schedule-boxes">
      {coursesDB.length === 0 && props.dates && filteredDates.map( (el, i)=>{
        return (<Box
          key={i}
          type={ el.event_type}
          href={
            el.event_type === 'course' ?
              BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS,
                  {id: el.verid, semester: el.semester}) :
              el.event_type === 'exam' ?
              BA.buildApiUrl(URL_TYPES.EXAMS, {semester: el.semester}) :
              el.event_type === 'internal_booking' ?
              el.event_link :
              ''
          }>
          <div className="col-12">
            <strong>{
              el['titel_'+i18n.language] !== null ?
              el['titel_'+i18n.language] : el['titel_de']
            }</strong>
            <div>
              {_(`schedule.${el.event_type}`)}
            </div>
          </div>
          {el.event_type!='public_holiday'&&el.event_type!='recess'&&<>
            <div className="col-5">
              <UI.Icon icon="bi-clock"
                inline></UI.Icon> {BA.getTimeString( el.start)} - {
                BA.getTimeString( el.end)}
            </div>
            <div className="col-7">
              <UI.Icon icon="bi-location" inline></UI.Icon>
              {el['room_'+i18n.language]}
            </div></>}
        </Box>);
      })}

      {coursesDB.length === 0 && filteredDates.length === 0 &&
      (<div className="p-2 mb-2">{_('schedule.no_courses_today')}</div>)}

      {/* Show public_holiday and recess for the LV-Planer */}
      {coursesDB.length !== 0 && filteredDates
          .filter((el) => el.event_type === 'public_holiday' || el.event_type === 'recess')
          .map((el, i) => (
            <Box
              key={i}
              type={el.event_type}
              href={''}>
              <div className="col-12">
                <strong>
                  {el['titel_' + i18n.language] !== null ? el['titel_' + i18n.language] : el['titel_de']}
                </strong>
                <div>{_(`schedule.${el.event_type}`)}</div>
              </div>
            </Box>
          ))}

      {/* Show noted LVs for the LV-Planer */}
      {coursesDB.length !== 0 && eventsOnThisDay.length > 0 && eventsOnThisDay.map((event, i) => {
        return (
          <Box
            key={i}
            type={'course'}
            href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS, {id: event.lv_nr, semester: event.semester})}>
            <div className="col-12">
              <strong>
                {language === LANGUAGES.EN ? event.titel_en : event.titel}
              </strong>
              <div>{_('app.lvplaner.calendar_schedule_boxes_noted_lv')}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className={`col-5 ${hasCollision ? 'text-danger' : ''}`}>
                <UI.Icon icon="bi-clock" inline></UI.Icon> {event.startTime} - {event.endTime}
              </div>
              <div className="col-7">
                <UI.Icon icon="bi-location" inline></UI.Icon> {event.raum}
              </div>
            </div>
          </Box>
        );
      })}

      {/* Show info when no LVs have been noted for the LV-Planer */}
      {coursesDB.length !== 0 && (
        <>
          {eventsOnThisDay.length === 0 && (
            <>
              {(filteredDates.length === 0 || filteredDates.some((el) =>
                (el.event_type === 'course' || el.event_type === 'exam' || el.event_type === 'internal_booking'))) &&
                !filteredDates.some((el) =>
                  el.event_type === 'public_holiday' || el.event_type === 'recess') && (
                <div className="p-2 mb-2">{_('app.lvplaner.calender_schedule_boxes_no_noted_lv')}</div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
ScheduleBoxes.propTypes={
  dates: PropTypes.array,
  currentDay: PropTypes.number,
  coursesDB: PropTypes.array,
};
export default ScheduleBoxes;
