import BachLink from './BachLink';
import './BackButton.css';
import PropTypes from 'prop-types';

function BackButton(props) {
  return (
    <div className="btn-back">
      <BachLink href={props.href}
        clickHandler={props.clickHandler}
        className="btn bachicon bi-chevron-left-green m-2" />
    </div>
  );
}
BackButton.propTypes={
  href: PropTypes.string,
  clickHandler: PropTypes.func,
};
export default BackButton;
