import './NotificationBoxes.css';
import {BA, useTranslation} from '@util/bachhelpers.js';
import Box from './Box';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';


function NotificationBoxes( props ) {
  const {i18n, _} = useTranslation();

  const notificationTypes = useSelector((state) => state.notificationTypes);
  const notificationGroups = useSelector((state) => state.notificationGroups);

  const findType = (id, t) => {
    let n = {};
    n = notificationTypes.find( (el)=>el.id == id);
    // get notification class
    // console.log( n.icon );
    if ( n && n.icon ) {
      switch ( n.icon ) {
        case 'exclamation-triangle-fill': return 'recess'; break;
        case 'exclamation-circle-fill': return 'exam'; break;
        case 'info-circle': return 'course'; break;
        default: return 'course';
      }
    } else {
      return n && n.mapping ? n.mapping : 'course';
    }
  };

  const getTypeTitel = ( id, lang ) => {
    let n = {};
    n = notificationTypes.find( (el)=>el.id == id);
    return n ? n['titel_'+lang] : '';
  };

  const findGroup = (g, lang)=>{
    const n = notificationGroups.find( (el) => el.id == g);
    return n && n.titel_en ? (lang === 'en' ? n.titel_en:n.titel_de):'';
  };

  const openBox = (e) => {
    e.preventDefault();
    const notifyNode = e.target.parentNode.parentNode;
    notifyNode.classList.remove( 'notify_closed' );
  };
  const closeBox = (e) => {
    e.preventDefault();
    const notifyNode = e.target.parentNode.parentNode;
    notifyNode.classList.add( 'notify_closed' );
  };

  const boxResizer = ()=>{
    const notifyBoxes = document.querySelectorAll('.notification_text');
    notifyBoxes.forEach( (box) => {
      const has = box.parentNode.classList.contains( 'notify_closed' );

      if ( !has ) {
        box.parentNode.classList.add( 'notify_closed' );
      }

      const wrapperHeight = box.clientHeight;
      const contentHeight = box.childNodes[0].clientHeight;
      console.log( wrapperHeight, contentHeight);
      if ( contentHeight > wrapperHeight ) {
        box.nextSibling.style.display = '';
      } else {
        box.nextSibling.style.display = 'none';
      }
      if ( !has ) {
        box.parentNode.classList.remove( 'notify_closed' );
      }
    });
  };

  useEffect(() => {
    boxResizer();
    window.onresize = boxResizer;
  }, [props.notifications]);


  return (
    <>
      <div className="pt-3 pb-2 mb-4 mt-2">
        {props.notifications.map( (el, i)=>{
          return (
            <div key={'notification_'+i}>
              <Box
                type={findType(el.type, el.titel_de)+' notification_box'+
                (el.checked?' read':'')}
                closeHandler={el.is_read === 0 &&
                  props.setNotificationRead ? (e)=>{
                    props.setNotificationRead( el.id );
                  }: undefined }
                deleteHandler={ props.deleteNotification ? (e)=>{
                  props.deleteNotification( el.id );
                }: undefined }
                editHandler={ props.editNotification ? (e)=>{
                  props.editNotification( el.id );
                }: undefined }
                deleteItem={el.delete}
              >
                <div className="col text-start notify_closed">
                  <strong>{el['titel_' + i18n.language]}</strong><br />
                  <div className="notification_text">
                    <div>
                      {BA.htmlSanitized(el['text_' + i18n.language])}
                    </div>
                  </div>
                  <div className="notification_opener">
                    <a href="#" className="notification_more" onClick={openBox}>
                      {_('app.notifications.readmore')}
                    </a>
                    <a href="#" className="notification_less"
                      onClick={closeBox}>
                      {_('app.notifications.readless')}
                    </a>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {props.admin&&<>
                        <br/><span>{
                          BA.printCurrentDayString( el.valid_from)}  {
                          _('app.notificationcenter.bis')} {
                          BA.printCurrentDayString( el.valid_to)}</span><br />
                        { el.group!=0&&<>{_('app.notificationcenter.group')}: {
                          findGroup(el.group, 'de')
                        }<br/></>}
                      </>}
                      <em>
                        {_('app.notifications.created_at')+' '}
                        {BA.printCurrentDayString( el.changed_at)} |
                        &nbsp;{getTypeTitel(el.type, i18n.language)}
                      </em>


                    </div>
                  </div>
                </div>
              </Box>
            </div>

          );
        })}
      </div>

    </>
  );
}
NotificationBoxes.propTypes={
  notifications: PropTypes.array,
  setNotificationRead: PropTypes.func,
  deleteNotification: PropTypes.func,
  editNotification: PropTypes.func,
  admin: PropTypes.bool,
};
export default NotificationBoxes;
