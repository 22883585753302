import UI from '@ui/UI';

function BACHLogo( props ) {
  return (
    <UI.BachLink href="/" className="logo_bach" id='logo_bach'>
      <span className="theme_bach_logo"></span>
    </UI.BachLink>
  );
}

export default BACHLogo;
