/* eslint-disable max-len */

import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {LANGUAGES, URL_TYPES} from '@util/consts.js';
import MyFavorites from '@components/Partials/MyFavorites';
import {Row, Col} from 'react-bootstrap';
import UI from '@ui/UI';

function SubjectCourses(props) {
  const {_} = useTranslation();
  const {tid, semester} = useParams(); // ppid = tid
  const [subjectCourses, setSubjectCourses] = useState([]);
  const [planpunktDetails, setPlanpunktDetails] = useState(null);
  const [xhrLoad, setXhrLoad] = useState(false);
  const language = useSelector((state) => state.language);

  const columns = [
    {
      id: 'verdid',
      selector: (row) => row.verid,
      name: _('app.course_search_dt.class_nr'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '9.5rem',
    },
    {
      id: 'lvtyp_1',
      selector: (row) => row.lvtyp_1,
      name: _('app.course_search_dt.class_type'),
      wrap: true,
      minWidth: '95px',
      maxWidth: '11rem',
    },
    {
      id: 'title',
      selector: (row) => language === LANGUAGES.EN ? row.titel_en : row.titel_de,
      cell: (row) => (
        <UI.BachLink className='text-link' href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS, {id: row.verid, semester: semester})}>
          {language === LANGUAGES.EN ? row.titel_en : row.titel_de}
        </UI.BachLink>
      ),
      name: _('app.course_search_dt.title'),
      sortable: true,
      wrap: true,
    },
    {
      id: 'instructor',
      // sort instructor by the last name of the first instructor
      selector: (row) => row.teachers[0].zuname,
      cell: (row) => (
        <div>
          {row.teachers.map(({tid, vorname, zuname}, i) => (
            <UI.BachLink className='text-link' key={i} href={BA.buildUIRouteUrl(URL_TYPES.COURSE_INSTRUCTORS, {id: tid, semester: semester})}>
              <div>{vorname} {zuname}</div>
            </UI.BachLink>
          ))}
        </div>
      ),
      name: _('app.course_search_dt.instructor'),
      sortable: true,
      wrap: true,
    },
  ];

  const loadSubjectCourses = async () => {
    if (!xhrLoad) {
      setXhrLoad(true);
      try {
        const response = await BA.ajax({
          bpoapi: true,
          url: BA.buildApiUrl(URL_TYPES.COURSE_SUBJECTS, {id: tid, semester: semester}),
        });
        setXhrLoad(false);
        if (response.data.courses && response.data.courses.length > 0) {
          setSubjectCourses(response.data.courses);
          // also get planpunkt info
          setPlanpunktDetails(response.data.planpunkt);
        } else {
          setSubjectCourses([]);
        }
      } catch (e) {
        setXhrLoad(false);
        setSubjectCourses([]);
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
      }
    }
  };

  useEffect(() => {
    loadSubjectCourses();
  }, []);

  return (
    <Col className="p-0">
      <Row className="pt-4 px-4 instructor-course g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6">
          <UI.PageTitle backurl="goback">{_('app.planpunkt_course.title')}</UI.PageTitle>
          {planpunktDetails && (
            <div className="featurebox px-4">
              <div className="p-0">
                <h2 className="pt-4 mb-4">
                  {language === LANGUAGES.EN ? planpunktDetails.planpunkt_en : planpunktDetails.planpunkt_de}
                </h2>
              </div>
            </div>
          )}
          {xhrLoad && <UI.AjaxLoading />}
          {!xhrLoad && subjectCourses.length === 0 && <p>{_('app.course_detail.no_data')}</p>}
          {!xhrLoad && subjectCourses.length > 0 && (
            <>
              <Row className="justify-content-end d-lg-none">
                <Col className="text-end col-8 col-xs-8 col-md-6 col-lg-5">
                  <UI.SemesterList />
                </Col>
              </Row>
              <UI.BachDataTable hideCount={false} hideFilter={true} columns={columns} data={subjectCourses} />
            </>
          )}
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}

export default SubjectCourses;
