import {Col, Form, Button, Spinner} from 'react-bootstrap';
import {useFormFields} from '@hooks/form-hook';
import {useState, useEffect} from 'react';
import {BA, useTranslation} from '@util/bachhelpers';
import UI from '@ui/UI';
import {LANGUAGES} from '@util/consts.js';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {URL_TYPES} from '@util/consts.js';

function FormNotification(props) {
  const {_} = useTranslation();
  // const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const notificationTypes = useSelector((state) => state.notificationTypes);
  const notificationGroups = useSelector((state) => state.notificationGroups);


  const [fields, handleChange] = useFormFields([
    {titel_de: '', error: true},
    {titel_en: '', error: true},
    {text_de: '', error: false},
    {text_en: '', error: false},
    {valid_from: '', error: true},
    {valid_to: '', error: true},
    {type: notificationTypes&&notificationTypes[0]?notificationTypes[0].id:'',
      error: false},
    {group: 0, error: false},

  ]);

  // set values if edit was called
  useEffect(()=>{
    if ( props.edit && props.edit[0] ) {
      handleChange([
        {titel_de: props.edit[0].titel_de, error: false},
        {titel_en: props.edit[0].titel_en, error: false},
        {text_de: props.edit[0].text_de, error: false},
        {text_en: props.edit[0].text_en, error: false},
        {valid_from: props.edit[0].valid_from, error: false},
        {valid_to: props.edit[0].valid_to, error: false},
        {type: props.edit[0].type.toString(), error: false},
        {group: props.edit[0].group.toString(), error: false},
        {resend: false, error: false},
      ]);
      setEditId( props.edit[0].id );
    }
  }, [props]);

  const [toasts, setToasts] = useState([]);
  const [xhrload, setXhrload] = useState(false);
  const [formVisible, setFormVisible] = useState( true );

  const [editId, setEditId] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log( 'handleSubmit', xhrload, fields.findIndex((el) => el.error) );

    if (!xhrload && fields.findIndex((el) => el.error) === -1) {
      const dataToSend = BA.sendData(fields);
      console.log( dataToSend );

      setXhrload(true);
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.ADMIN_NOTIFICATIONS)+
        (editId?'/'+editId:''),
        contentType: 'application/json',
        bpoapi: true,
        method: editId ? 'PUT' : 'POST',
        data: JSON.stringify(dataToSend),
      }).then((response) => {
        console.log(response);
        setXhrload(false);
        if (response.error) {
          setToasts([{variant: 'error mb-4',
            message: response.error.message,
            show: true}]);
        } else {
          setToasts([{variant: 'success mb-4',
            message: 'data saved',
            show: true}]);
          // reset fields ?
          props.refresh();
          setFormVisible( false );
          setTimeout( ()=>{
            props.cancel();
          }, 2000 );
        }
      });
    } else {
      BA.validateFields({
        titel_de: '',
        titel_en: '',
        text_de: '',
        text_en: '',
        valid_from: '',
        valid_to: '',
      });
    }
  };

  const handleClose = (i) => {
    const updatedToasts = toasts.map( (toast, ix)=>{
      if ( i === ix ) {
        toast.show = false;
      }
      return toast;
    });
    setToasts( updatedToasts );
  };

  return (
    <>

      <Form method="post"
        action=""
        autoComplete="off"
        onSubmit={handleSubmit}
        className="dataform dataform-margin-top">

        <UI.ToastMessage toasts={toasts} handleClose={handleClose} />
        {formVisible&&<>
          { editId&&
        <Form.Group className="mb-4 row align-items-center">
          <Form.Label htmlFor="id" className="col-md-3 mb-0">
            <span className="labeltext">
              {_('app.notificationcenter.id')}
            </span>
          </Form.Label>
          <Col sm={9}>
            <UI.FormControl
              className="ps-3"
              type="text"
              value={editId.toString()}
              name="id"
              readonly
            />
          </Col>
        </Form.Group>
          }
          <Form.Group className="mb-4 row align-items-center">
            <Form.Label htmlFor="titel_de" className="col-md-3 mb-0">
              <span className="labeltext">
                {_('app.notificationcenter.titel_de')}
              </span>
            </Form.Label>
            <Col sm={9}>
              <UI.FormControl
                className="ps-3"
                type="text"
                value={fields[0].titel_de}
                name="titel_de"
                handler={handleChange}
                validation={true}
                placeholder={_('app.notificationcenter.required')}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-4 row align-items-center">
            <Form.Label htmlFor="titel_en" className="col-md-3 mb-0">
              <span className="labeltext">
                {_('app.notificationcenter.titel_en')}
              </span>
            </Form.Label>
            <Col sm={9}>
              <UI.FormControl
                className="ps-3"
                type="text"
                value={fields[1].titel_en}
                name="titel_en"
                handler={handleChange}
                validation={true}
                placeholder={_('app.notificationcenter.required')}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-4 row align-items-center">
            <Form.Label htmlFor="text_de" className="col-md-3 mb-0">
              <span className="labeltext">
                {_('app.notificationcenter.text_de')}
              </span>
            </Form.Label>
            <Col sm={9}>
              <UI.FormControl
                className="ps-3"
                type="textarea"
                value={fields[2].text_de}
                name="text_de"
                handler={handleChange}
                validation={true}
                placeholder={_('app.notificationcenter.max_length')}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-4 row align-items-center">
            <Form.Label htmlFor="text_en" className="col-md-3 mb-0">
              <span className="labeltext">
                {_('app.notificationcenter.text_en')}
              </span>
            </Form.Label>
            <Col sm={9}>
              <UI.FormControl
                className="ps-3"
                type="textarea"
                value={fields[3].text_en}
                name="text_en"
                handler={handleChange}
                validation={true}
                placeholder={_('app.notificationcenter.max_length')}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-4 row align-items-center">
            <Form.Label htmlFor="valid_from" className="col-md-3 mb-0">
              <span className="labeltext">
                {_('app.notificationcenter.valid_from')}
              </span>
            </Form.Label>
            <Col sm={9}>
              <UI.FormControl
                className="ps-3"
                type="date"
                value={fields[4].valid_from}
                name="valid_from"
                handler={handleChange}
                validation={true}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-4 row align-items-center">
            <Form.Label htmlFor="valid_to" className="col-md-3 mb-0">
              <span className="labeltext">
                {_('app.notificationcenter.valid_to')}
              </span>
            </Form.Label>
            <Col sm={9}>
              <UI.FormControl
                className="ps-3"
                type="date"
                value={fields[5].valid_to}
                name="valid_to"
                dateAfter="valid_from"
                handler={handleChange}
                validation={true}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-4 row align-items-center">
            <Form.Label htmlFor="type" className="col-md-3 mb-0">
              <span className="labeltext">
                {_('app.notificationcenter.type')}
              </span>
            </Form.Label>
            <Col sm={9}>
              <Form.Select
                className={`dataform form-control`}
                name="type"
                value={fields[6].type}
                onChange={handleChange}
              >
                {notificationTypes.map((el) => (
                  <option key={el.id} value={el.id}>
                    {language === LANGUAGES.DE ? el.titel_de : el.titel_en}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group className="mb-4 row align-items-center">
            <Form.Label htmlFor="group" className="col-md-3 mb-0">
              <span className="labeltext">
                {_('app.notificationcenter.group')}
              </span>
            </Form.Label>
            <Col sm={9}>
              <Form.Select
                className={`dataform form-control`}
                name="group"
                value={fields[7].group}
                onChange={handleChange}
                disabled={props.edit && props.edit[0] &&
                  props.edit[0].group !== undefined}
              >
                {notificationGroups.map((el) => (
                  <option key={el.id} value={el.id}>
                    {language === LANGUAGES.DE ? el.titel_de : el.titel_en}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>

          {editId && (
            <Form.Group className="mb-4 row align-items-center">
              <span className="col-md-3 mb-0">
                {_('app.notificationcenter.resend')}
              </span>
              <Col sm={9}>
                <Form.Check
                  type="switch"
                  id="resend"
                  name="resend"
                  onChange={handleChange}
                  label={_('app.notificationcenter.resend_yes')}
                />
              </Col>
            </Form.Group>
          )}


          <div className="text-end mt-4">
            <Button type="submit" className="px-4 btn-inline" variant="primary">
              {xhrload&&<Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />}
              {_('app.forms.save')}
            </Button>
            <Button type="button"
              className="px-4 btn-inline ms-4"
              variant="secondary"
              onClick={props.cancel}
            >
              {_('general.cancel')}
            </Button>
          </div></>}
      </Form></>);
}
FormNotification.propTypes={
  edit: PropTypes.array,
  refresh: PropTypes.func,
  cancel: PropTypes.func,
};
export default FormNotification;
