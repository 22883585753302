import './ImageElement.css';
import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';


function ImageElement(props) {
  const [coverSrc, setCoverSrc] = useState(process.env.REACT_APP_BASE_URL +
    '/images/user_nopic.svg');
  useEffect( ()=>{
    if ( props.cover ) {
      if ( props.src!==null && typeof props.src!=='undefined' ) {
        const testLoadImage = new Image();
        testLoadImage.src = props.src;
        testLoadImage.onload = ()=>{
          setCoverSrc(props.src);
        };
      } else {
        setCoverSrc(process.env.REACT_APP_BASE_URL +
          '/images/user_nopic.svg');
      }
    }
  }, [props.src]);

  if (props.cover) {
    return (
      <div
        className="cover-image"
        style={
          {backgroundImage: `url("${coverSrc}")`}
        }
      />
    );
    /* return (
      <img className="cover-image"
        style={{height: '100%', width: '100%'}}
        src={
          // eslint-disable-next-line max-len
          `${((props.src!==null && typeof props.src!=='undefined')?
          // eslint-disable-next-line max-len
          /* process.env.REACT_APP_BASE_URL +* / props.src:
          process.env.REACT_APP_BASE_URL + '/images/user_nopic.svg')}`}
        onError={(e)=>{
          // eslint-disable-next-line max-len
          e.target.src = process.env.REACT_APP_BASE_URL+
          '/images/user_nopic.svg';
        }}
        alt='profile'
      />
    );*/
  } else {
    return (
      <img src={
        ((props.src.indexOf('data:image/') === -1 &&
        props.src.indexOf('http') !== 0) ?
        process.env.REACT_APP_BASE_URL : '') +
        props.src}
      alt={props.alt}
      />
    );
  }
}
ImageElement.propTypes={
  cover: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
};
export default ImageElement;
