import BachLink from './BachLink';
import BachTile from './BachTile';
import BachTileGroup from './BachTileGroup';
import Icon from './Icon';
import FormControl from './FormControl';
import InfoBox from './InfoBox';
import InfoText from './InfoText';
import ImageElement from './ImageElement';
import ToastMessage from './ToastMessage';
import BackButton from './BackButton';
import AjaxLoading from './AjaxLoading';
import SemesterList from './SemesterList';
import PageTitle from './PageTitle';
import BachDataTable from './BachDataTable';
import StudyList from './StudyList';
import Grades from './Grades';
import TableWithHeader from './TableWithHeader';
import ConfirmModal from './ConfirmModal';
import NoPermission from './NoPermission';


const UI = {
  AjaxLoading,
  BachLink,
  BachTile,
  BachTileGroup,
  BackButton,
  BachDataTable,
  ConfirmModal,
  FormControl,
  Grades,
  Icon,
  InfoBox,
  InfoText,
  ImageElement,
  NoPermission,
  PageTitle,
  SemesterList,
  StudyList,
  TableWithHeader,
  ToastMessage,
};

export default UI;
