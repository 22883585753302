import {Accordion} from 'react-bootstrap';
import {useTranslation} from '@util/bachhelpers.js';

function AboutGeneralInfo() {
  const {_} = useTranslation();
  return <>
    <h5>{_('app.about.general_info')}</h5>
    <Accordion alwaysOpen>
      <Accordion.Item eventKey="0" className="mb-2">
        <Accordion.Header>{_('app.about.acc_auth_title')}</Accordion.Header>
        <Accordion.Body>{_('app.about.acc_auth_body')}</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className="mb-2">
        <Accordion.Header>
          {_('app.about.acc_statuscode_title')}
        </Accordion.Header>
        <Accordion.Body>{_('app.about.acc_statuscode_body')}</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className="mb-2">
        <Accordion.Header>{_('app.about.acc_semester_title')}</Accordion.Header>
        <Accordion.Body>{_('app.about.acc_semester_body')}</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className="mb-2">
        <Accordion.Header>
          {_('app.about.acc_protocols_title')}
        </Accordion.Header>
        <Accordion.Body>{_('app.about.acc_protocols_body')}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </>;
}

export default AboutGeneralInfo;
