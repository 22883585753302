import {Container} from 'react-bootstrap';
import UI from '@ui/UI';
import {useTranslation} from '@util/bachhelpers.js';
import AboutGeneralInfo from '@components/Partials/AboutGeneralInfo';
import AboutSampleCode from '@components/Partials/AboutSampleCode';

function AboutPersonal(props) {
  const {_} = useTranslation();
  return (
    <div className="col-lg-6 col-xxl-4 ">
      <div className="p-4 pb-0">
        <UI.PageTitle backurl="/about">{_('app.about.api_title')}</UI.PageTitle>
      </div>
      <div className="featurebox p-4 pt-0">
        <h2 className="pt-4 mb-4">{_('app.tile.personnel')}</h2>
      </div>
      <Container fluid className="px-4">
        <AboutGeneralInfo />
        <h5 className="mt-4">{_('app.about.available_data_title')}</h5>
        <div className="p-4">

          <h5>get_organization_details</h5>
          <dl className="mb-4">
            <dt>{_('app.about.api_url')}</dt>
            <dd><code>https://bach.wu.ac.at/z/BachAPI/personnel/get_organization_details</code></dd>
            <dt>{_('app.about.api_params')}</dt>
            <dd>[orgid]</dd>
            <dt>{_('app.about.api_example')}</dt>
            <dd>
              <UI.BachLink
                className="text-link"
                href="https://bach.wu.ac.at/z/BachAPI/personnel/get_organization_details?orgid=3944">
                orgid=3944
              </UI.BachLink>
            </dd>
          </dl>

          <h5>get_employee_details (not available to the public)</h5>
          <dl className="mb-4">

            <dt>{_('app.about.api_url')}</dt>
            <dd><code>https://bach.wu.ac.at/z/BachAPI/personnel/get_employee_details</code></dd>
            <dt>{_('app.about.api_params')}</dt>
            <dd>[tid] or [username]</dd>
            <dt>{_('app.about.api_example')}</dt>
            <dd>
              <UI.BachLink
                className="text-link"
                href="https://bach.wu.ac.at/z/BachAPI/personnel/get_employee_details?tid=6396">
                  tid=6396
              </UI.BachLink>,
              <UI.BachLink
                className="text-link"
                href="https://bach.wu.ac.at/z/BachAPI/personnel/get_employee_details?username=frey">
                username=frey
              </UI.BachLink>
            </dd>
          </dl>

          <h5>search_directory (not available to the public)</h5>
          <dl className="mb-4">

            <dt>{_('app.about.api_url')}</dt>
            <dd><code>https://bach.wu.ac.at/z/BachAPI/directory/search_directory</code></dd>
            <dt>{_('app.about.api_params')}</dt>
            <dd>[query]</dd>
            <dt>{_('app.about.api_example')}</dt>
            <dd>
              <UI.BachLink href="https://bach.wu.ac.at/z/BachAPI/directory/search_directory?query=frey">query=frey</UI.BachLink>
            </dd>
          </dl>
        </div>
        <AboutSampleCode />
      </Container>

    </div>

  );
}

export default AboutPersonal;
