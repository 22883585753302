import './Icon.css';
import PropTypes from 'prop-types';

function Icon(props) {
  const classnamesSpan = ['bachicon',
    props.icon,
    props.variant,
    props.inline ? 'bachicon-inline':'',
    props.didaktik ? 'bachicon-didaktik':'',
    props.nopointer ? 'bachicon-nopointer': '',
  ].join(' ');
  return (
    <span className={classnamesSpan}></span>
  );
}
Icon.propTypes={
  icon: PropTypes.string,
  variant: PropTypes.string,
  inline: PropTypes.bool,
  nopointer: PropTypes.bool,
  didaktik: PropTypes.bool,
};
export default Icon;
