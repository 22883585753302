import UI from '@ui/UI';
import {useTranslation} from '@util/bachhelpers.js';
import packageInfo from '@packageInfo';

function About(props) {
  const {_} = useTranslation();
  return (
    <div className="col-lg-6 col-xxl-4 ">
      <div className="p-4 pb-0">
        <UI.PageTitle backurl="/">{_('app.nav.about')}</UI.PageTitle>
        {_('app.about.main_text')}
        <img className="bpowelcome"
          // eslint-disable-next-line max-len
          src={process.env.REACT_APP_BASE_URL + '/images/bpo_welcome_800x533.png'} alt="BPO"></img>
      </div>
      <div className="featurebox p-4 pt-0">
        <div className="p-0 pb-0">
          <h2 className="pt-4 mb-4">{_('app.about.api_title')}</h2>
          {_('app.about.api_text')}
        </div>
        <UI.BachTileGroup
          title=""
          className="mt-4 mb-4"
          tiles={[
            {title: _('app.tile.teaching'),
              icon: 'bi-flipchart',
              active: 1,
              href: '/about/teaching'},
            {title: _('app.tile.personnel'),
              icon: 'bi-building',
              active: 1,
              href: '/about/personnel'},
          ]}
        />
      </div>
      <div className="px-4 text-end versiontext">
        Version: {packageInfo.version}</div>

    </div>

  );
}

export default About;
