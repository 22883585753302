import packageInfo from '@packageInfo';
import BachLink from '@ui/BachLink';
import {useTranslation} from '@util/bachhelpers.js';
import PropTypes from 'prop-types';
import {CloseButton, Col, Modal, Row} from 'react-bootstrap';


function SupportModal(props) {
  const {_} = useTranslation();
  const {showSupport, handleClose} = props;

  return (
    <Modal show={showSupport} onHide={handleClose}>
      <Modal.Header className="pt-2 pb-2">
        <Modal.Title>{_('app.support.title')}</Modal.Title>
        <CloseButton onClick={handleClose} className="btn-close-white" />
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <strong>{_('app.support.online_info')}</strong><br />
            <BachLink href="https://short.wu.ac.at/accountverwaltung">{_('app.support.account')}</BachLink><br />
            <BachLink href="https://short.wu.ac.at/its-abisz">{_('app.support.it_service')}</BachLink>
          </Col>
          <Col>
            <strong>{_('app.support.personnel_info')}</strong><br />
            {_('app.support.tel')}: +43 1 31336 3000<br />
            {_('app.support.mail')}:
            <BachLink href="mailto:hotline@wu.ac.at">hotline@wu.ac.at</BachLink>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <strong>{_('app.support.need_help')}</strong><br />
            <BachLink href="https://www.wu.ac.at/it/support">{_('app.support.itcenter')}</BachLink>
          </Col>
          <Col>
            <strong>{_('app.support.helpdesk')}</strong><br />
            <BachLink href="https://short.wu.ac.at/helpdesk-anfrage">{_('app.support.servicedesk')}</BachLink>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>{_('app.nav.feedback')}</strong><br />
            <BachLink href="mailto:mywu-feedback@wu.ac.at">
              mywu-feedback@wu.ac.at</BachLink>
          </Col>
          <Col>
            <strong>Version</strong><br />
            {packageInfo.version}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
SupportModal.propTypes ={
  showSupport: PropTypes.bool,
  handleClose: PropTypes.func,
};
export default SupportModal;
