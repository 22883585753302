import {useTranslation} from 'react-i18next';
import parse from 'html-react-parser';

const format = (...args) => {
  let a = args[0];
  const b = [];
  let c;
  for (c = 1; c < args.length; c++) {
    b.push(args[c]);
  }
  for (c in b) {
    if (a.search(/%[a-z]/)>-1) {
      a = a.replace(/%[a-z]/, b[c]);
    }
  }
  return a;
};

const useBATranslation = (initialState, ...args) => {
  initialState = initialState || 'common';
  const {t, i18n} = useTranslation(initialState);
  return {
    /**
     * translate text from key
     * @param {*} key // keys from translation JSON
     * @param  {...any} args // if args[0] Boolean AND false, do not parseHTML
     * @returns
     */
    _: (key, ...args)=>{
      if ( key==='') {
        return '';
      }
      if ( key === ' ' ) {
        return ' ';
      }
      // if objects are requested
      if (typeof args[0] === 'object' &&
        args[0].returnObjects &&
        args[0].returnObjects === true) {
        return t(key, {returnObjects: true});
      }

      if ( typeof args[0] === 'boolean' && args[0] === false ) {
        args.shift();
        return format(
            t(key),
            args,
        );
      } else {
        // eslint-disable-next-line new-cap
        return parse(
            format(
                t(key),
                args,
            ),
        );
      }
    },
    i18n,
  };
};

export {useBATranslation as useTranslation};
