import {BA} from '@util/bachhelpers.js';
import editTile from '@model/tile_edit.json';
import publicTiles from '@model/tiles_public.json';
import adminTiles from '@model/tile_admin.json';


const tileMapping = (obj) => {
  obj.hreftrans = obj.href.indexOf( '/idpapi' ) === 0 ?
      BA.BACHURL+obj.href : obj.href;
  obj.titlekey = obj.title;
  obj.infokey = obj.info;
};

const tilesMapping = (tiles) => {
  tiles.map&&tiles.map( (tile, i) => {
    tileMapping( tile );
  });
};

for ( const i in publicTiles ) {
  if ( typeof publicTiles[i] === 'object' ) {
    tilesMapping( publicTiles[i] );
  }
}

tilesMapping( editTile );
tilesMapping( adminTiles );


export {editTile, publicTiles, adminTiles};
